import './Policy1.css';

const Policy1 = () => {

    return(
        <div className='PolicyWrap'>
            <div className='PolicyBox'>
                <div className='TermsOfUseWrap'>
                    <div className='TermsOfUseContentWrap'>
                        <div className='TermsOfUseTitleBox'>
                            제 1 장 총칙
                        </div>
                        <div className='TermsOfUseContentBox'>
                            <div className='TermsOfUseContentTitle'>
                                제 1 조 (목적)
                            </div>
                            <div className='TermsOfUseContent'>
                                이 약관(이하 ‘본 약관’ 또는 ‘이 약관’)은 통신판매중개업자인 ㈜공유자재(이하 ‘회사’)가 운영하는 자재 중개 쇼핑몰(사이트 도메인 표시, 이하 ‘몰’)에서 제공하는 모든 서비스(이하 ‘서비스’)를 이용함에 있어 이용자의 권리 및 의무, 기타 부수 사항에 관하여 규정함을 목적으로 합니다. 
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 2 조 (정의)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. '몰': 회사가 컴퓨터, 태블릿, 스마트폰 등 정보통신기기를 이용하여 서비스를 제공할 수 있도록 운영중인 자재 판매자 및 구매자 용도의 웹 및 앱을 의미하며, 이를 운영하는 사업자의 의미를 포함합니다.<br/>
                                2. '회원': 구매자와 판매자를 총칭하여 회사에 개인정보를 제공하여 회원등록을 한 자로서, 몰에서 제공하는 정보를 지속적으로 제공받으며, 이용할 수 있는 자를 말합니다. 회사는 서비스의 원활한 제공을 위해 회원의 등급을 회사 내부의 규정에 따라 나눌 수 있습니다.<br/> 
                                3. '구매자': 자재를 구매하고자 몰을 통해 자신의 자재 구매 정보를 몰에 등록하고, 자재 정보를 탐색하는 자를 의미합니다.<br/>
                                4. '판매자': 자재를 생산, 수입, 유통하는 사업자로 몰을 통해 자신의 자재 판매 정보를 몰에 등록하고, 자재 정보를 탐색하는 자를 의미합니다.<br/>
                                5. '몰 서비스': 몰을 통하여 구매자가 등록한 자재 구매 정보와 판매자가 등록한 자재 판매 정보를 비교하여 최적의 조건으로 구매자와 판매자가 자재 매매계약을 체결할 수 있도록 구매자와 판매자를 연결해 주는 서비스를 의미합니다.<br/>
                                6. '아이디(ID)': 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인한 회원 본인의 ‘식별 정보’로 서비스에서는 닉네임 및 이메일 주소를 식별정보로 사용합니다.<br/>
                                7. '비밀번호': 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정하고 회사가 승인한 문자나 숫자의 조합을 말합니다.<br/>
                                8. '서비스': 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 관계없이 회원이 이용할 수 있는 일체의 서비스를 의미합니다.<br/>
                                9. '개인정보': 개인정보보호법 제2조의 ‘개인정보’를 의미합니다.<br/>
                                10. 위 항에서 정의된 용어 이외의 용어에 대해서는 관계 법령 및 서비스별 이용기준에서 정의하는 일반적인 거래관행에 따릅니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 3 조 (약관의 효력 및 변경)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 본 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 서비스에 가입을 하여 이용하고자 하는 자(이하 “가입신청자”라 한다)에게 공지하고, 이에 동의한 가입신청자가 서비스에 가입함으로써 효력이 발생합니다.<br/>
                                2. 회사는 필요하다고 인정되는 경우 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 본 조 제1항과 같은 방법으로 그 적용일자 7일 전부터 공지합니다. 다만, 회원에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 전부터 공지하며, SMS, APP Push 등으로 회원에게 개별 통지합니다. 단, 회원의 연락처 미기재, 변경 후 미수정 등으로 인하여 개별 통지가 어려운 경우에 한하여 본 항의 공지를 함으로써 개별 통지한 것으로 간주합니다.<br/>
                                3. 회사가 제2항에 따라 변경 약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회원이 변경 약관에 동의한 것으로 간주합니다. 회원은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 4 조 (약관 외 준칙 및 관련법령과의 관계)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 본 약관에 또는 개별약관에서 정하지 않은 사항에 대해서는 개인정보보호법, 전자상거래 등에서의 소비자보호에 관한 법률 (“전자상거래법”) 등 관계법령 및 개인정보처리방침, 회사가 정한 서비스의 세부이용지침 등의 규정에 따릅니다.<br/>
                                2. 본 약관에서 정한 회사의 책임 제한 규정은 관련 법령이 허용하는 최대한도의 범위 내에서 적용됩니다.<br/>
                                3. 회사는 필요한 경우 서비스 내의 개별항목에 대하여 개별약관을 정할 수 있으며, 본 약관과 개별약관의 내용이 상충되는 경우에는 개별약관의 내용이 우선 적용됩니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 5 조 (회원에 대한 통지)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 이동전화 단문메시지서비스(SMS), 푸시알림(App push)등으로 회원에게 통지할 수 있습니다.<br/>
                                2. 회사는 불특정다수 회원에 대한 통지의 경우 공지사항으로 게시함으로써 개별 통지에 갈음할 수 있습니다. 
                            </div>
                        </div>
                    </div>
                    <div className='TermsOfUseContentWrap'>
                        <div className='TermsOfUseTitleBox'>
                            제 2 장 서비스 이용계약
                        </div>
                        <div className='TermsOfUseContentBox'>
                            <div className='TermsOfUseContentTitle'>
                                제 6 조 (이용계약의 신청)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 서비스의 이용계약은 가입신청자가 본 약관에 동의하고 기타 필요한 가입절차를 완료함으로써 체결됩니다.<br/>
                                2. 회사는 본인확인을 위하여 가입신청자의 휴대 단말 식별 정보 및 본인확인 인증절차(이하 “본인인증절차”)를 요구할 수 있습니다. 이 경우 가입신청자는 해당 인증을 수행하는 등 가입절차를 완료하여야 합니다.<br/>
                                3. 가입신청자의 관리 소홀로 타인이 가입신청자 본인 명의의 인증절차를 수행하거나, 가입신청자가 타인의 명의를 도용하여 인증을 수행하는 경우, 그로 인해 발생하는 불이익 기타 손해에 대하여 회사는 책임지지 않습니다.<br/>
                                4. 가입신청자는 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보(이하 “등록정보”)를 제공하여야 합니다.<br/>
                                5. 가입신청자 또는 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 가입신청자 또는 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우 회사는 서비스 이용을 제한 또는 중단시킬 수 있습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 7 조 (이용계약 신청에 대한 승낙 유보 등)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 제6조에 의한 가입신청자의 신청에 대하여, 회사는 원칙적으로 서비스의 이용을 승낙합니다. 단, 회사는 다음 각 호에 해당하는 신청에 대하여는 그 사유가 해소될 때까지 승낙을 유보하거나, 승낙을 거부할 수 있습니다.
                                    <div className='TermsOfUseSubContent'>
                                        - 다른 사람의 명의를 사용하거나 이동전화 명의자의 허락 없이 SMS 인증, 범용공인인증 또는 신용카드인증을 수행하는 등 회원 등록 시 허위로 신청하는 경우<br/>
                                        - 회사에 의하여 이용계약이 해지된 날로부터 2개월 이내에 재이용신청을 하는 경우<br/>
                                        - 기술적 문제로 서비스 제공이 불가능한 경우<br/>
                                        - 회원이 등록사항을 누락하거나 오기하여 신청하는 경우<br/>
                                        - SMS 인증, 휴대 단말 식별정보 인증 등 회사가 정한 인증절차를 완료하지 않은 경우<br/>
                                        - 만 14세 미만 아동이 서비스 이용 신청을 한 경우<br/>
                                        - 가입신청자가 회원 자격을 상실한 사실이 있는 경우 (단, 회원 자격을 상실한 날로부터 1년이 경과한 자로서 회사의 재가입 승낙을 얻은 경우에는 그러하지 아니합니다.)<br/>
                                        - 회사로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우<br/>
                                        - 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우<br/>
                                        - 회사가 정한 이용신청 요건이 만족되지 않았거나 위법 또는 부당한 이용신청임이 확인된 경우 또는 회원의 책임 있는 사유로 인하여 회사가 승낙할 수 없는 경우
                                    </div>
                                2. 본 조 제1항에 따라 회사가 회원가입 신청의 승낙을 유보하거나 거절한 경우, 회사는 가입신청자에게 승낙 유보의 사유, 승낙 가능시기 또는 승낙에 필요한 추가요청정보 내지 자료 등 기타 승낙 유보, 거절과 관련된 사항을 해당 서비스화면에 게시하거나 SMS, APP Push 등을 통하여 통지합니다.<br/>
                                3. 회사는 서비스의 품질 향상이나 서비스 제공과 관련하여 필요한 경우, 회원을 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 기타 필요한 사항을 세분화하여 그 이용에 차등을 둘 수 있습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 8 조 (이용계약의 종료)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회원의 해지
                                <div className='TermsOfUseSubContent'>
                                    1) 회원은 언제든지 회사에게 해지 의사를 통지할 수 있고 회사는 특별한 사유가 없는 한 이를 즉시 수락하여야 합니다. 다만, 회원은 (제11조에 정의된) 매칭 대기 중인 자재 구매/판매 등록/ 신청이 있는 경우, 해지의사를 통지하기 전에 대기 중인 모든 자재 구매/판매 등록/신청을 철회 또는 취소해야만 합니다. 즉, 거래중인 상품 또는 구매중인 상품이 있거나 미정산된 대금이 있는 경우, 이용계약의 해지가 불가능합니다. 회원이 탈퇴하는 경우, 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 이용계약의 해지는 이미 매칭된 매매계약에 대해서는 영향을 미치지 않습니다.<br/>
                                    2) 회원이 발한 의사표시로 인해 발생한 불이익에 대한 책임은 회원 본인이 부담하여야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다. <br/>
                                    3) 회원의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는 재이용 의사가 회사에 통지되고, 이에 대한 회사의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.<br/>
                                    4) 본 항에 따라 해지를 한 회원은 이 약관이 정하는 회원가입절차와 관련조항에 따라 신규 회원으로 다시 가입할 수 있습니다. 다만 회원이 중복참여가 제한된 판촉이벤트 중복 참여 등 부정한 목적으로 회원탈퇴 후 재이용을 신청하는 경우 회사는 가입을 일정기간 동안 제한할 수 있습니다.<br/>
                                    5) 본 항에 따라 해지한 이후에는 재가입이 불가능하며, 모든 가입은 신규가입으로 처리됩니다.
                                </div>
                            </div>
                            <div className='TermsOfUseContent'>
                                2. 회사의 해지
                                <div className='TermsOfUseSubContent'>
                                    1) 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다.
                                    <div className='TermsOfUseSubContent2'>
                                        - 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 공서양속에 위배되는 행위를 한 경우<br/>
                                        - 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br/>
                                        - 제7조 1항의 승낙거부 사유가 추후 발견된 경우<br/>
                                        - 기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우
                                    </div>
                                    2) 회사가 해지를 하는 경우 회사는 회원에게 e-mail, 전화, 기타의 방법을 통하여 해지 사유를 밝혀 해지 의사를 통지합니다. 이용계약은 회사의 해지의사를 회원에게 통지한 시점에 종료됩니다.<br/>
                                    3) 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 회원에게 부가적으로 제공한 각종혜택을 회수할 수 있습니다. 이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 책임을 부담하여야 하고, 회사는 일체의 책임을 지지 않습니다.<br/>
                                    4) 본 항에서 정한 바에 따라 이용계약이 종료된 경우, 회원의 재이용 신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='TermsOfUseContentWrap'>
                        <div className='TermsOfUseTitleBox'>
                            제 3 장 몰 서비스
                        </div>
                        <div className='TermsOfUseContentBox'>
                            <div className='TermsOfUseContentTitle'>
                                제 9 조 (회사의 서비스 제공 항목)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사가 제공하는 서비스는 다음과 같습니다.
                                <div className='TermsOfUseSubContent'>
                                    1) 자재 구매자와 판매자를 연결해 주는 몰 서비스<br/>
                                    2) 회원간 커뮤니케이션 제공, 기타 부가 서비스
                                </div>
                                2. 회사가 제공하는 서비스의 내용을 기술적 사양의 변경 등의 이유로 변경할 경우에는 그 사유를 회원에게 통지하거나, 회원이 알아볼 수 있도록 공지사항으로 게시합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 10 조 (서비스의 이용시간)
                            </div>
                            <div className='TermsOfUseContent'>
                                회사는 서비스를 연중무휴, 1일 24시간을 제공함을 원칙으로 합니다. 다만, 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 11 조 (서비스의 상세 및 이용조건)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 판매자의 판매 정보 등록: 판매자는 몰 서비스를 이용하기 위해 몰에 자재 판매 정보를 등록합니다. 등록해야 하는 정보의 항목은 다음을 포함하며, 상세 항목은 몰 판매자용 웹 및 앱에 표시됩니다. 판매 정보의 항목은 회사가 서비스 향상을 위해 수시로 변경될 수 있습니다.
                                    <div className='TermsOfUseSubContent'>
                                        - 품명, 원산지, 품질기준, 인도장소, 인도일자, 운송조건, 수량, 단가, 대금/계약금, 지급조건
                                        - [기타 항목 추가]
                                    </div>
                                2. 구매자의 구매 신청: 회사는 구매자에게 적합한 판매자의 판매 등록 정보를 몰 웹 및 앱의 화면을 통해 개별 구매자에게 제공합니다. 구매자가 판매 등록 정보를 확인하여 구매 신청을 하면, 회사는 다음 사항을 포함한 매매 거래 조건(“이하 “판매조건’)을 해당 구매자에게 알림을 보냅니다. 
                                    <div className='TermsOfUseSubContent'>
                                        - 제품의 판매자의 상호, 대표자의 성명, 주소, 전화번호, 사업자등록번호<br/>
                                        - 제품의 명칭과 종류, 원산지 기타 정보<br/>
                                        - 가격, 지급방법, 지급시기<br/>
                                        - 공급방법, 공급시기<br/>
                                        - 청약의 철회 및 계약의 해제(이하 “청약철회등”)의 기한, 행사방법 및 효과에 관한 사항<br/>
                                        - 제품의 품질보증에 대한 사항<br/>
                                        - 분쟁 처리에 관한 사항<br/>
                                        - [있는 경우] 매매약관<br/>
                                        - [기타 항목 추가]
                                    </div>
                                3. 해당 구매자가 판매자의 판매조건으로 거래하고자 청약하면, 몰은 해당 구매자에게 즉시 청약 의사표시의 수신 확인 및 판매 가능 여부에 관한 정보를 해당 구매자에게 알립니다. 몰이 해당 구매자에게 청약내용을 확인한 후 해당 구매자가 대금/계약금을 결제함으로써 구매자와 판매자 사이에 자재 매매계약이 체결됩니다. 이렇게 체결된 계약을 ‘매매계약’이라 하며, 매매계약이 체결된 것을 ‘매칭’이라 합니다. 몰은 매매계약이 체결되는 즉시, 판매자가 판매조건을 포함한 계약서를 작성하여 구매자에게 교부하도록 합니다.<br/>
                                4. 판매자가 매매계약에 따라 구매자에게 자재를 인도하고 구매자가 이를 확인(이를 “구매확정”이라 함)하면 몰은 판매자에게 대금/계약금을 지급, 정산합니다. 구매자가 구매확정을 하지 않으면 자재 결제일 기준 7일 후 자동으로 구매확정이 됩니다. 구매자는 자재의 인도가 매매계약에 따르지 않은 경우 불편접수를 할 수 있으며, 이 경우 구매확정은 중단됩니다. 구매자는 불편접수 후에도 언제든지 구매확정을 할 수 있습니다. 구매자가 불편접수를 한 경우 몰은 구매자의 불편 사항을 신속하게 처리할 수 있도록 판매자와 협의, 조정합니다.<br/>
                                5. 몰은 구매자가 매매계약을 체결함에 있어 조작 실수 등을 하지 않도록 필요한 절차를 마련하며, 몰이 이러한 절차를 마련하지 않아 구매자가 중요부분에 대한 착오로 매매계약을 체결한 경우에 구매자는 그 매매계약을 취소할 수 있습니다. 몰은 전자적 수단에 의한 거래대금의 지급(이하 "전자적 대금지급") 방법을 이용하는 경우 관련 정보의 보안 유지에 필요한 조치를 하며, 전자적 대금지급이 이루어진 경우에 전자문서의 송신 등의 방법에 따라 구매자에게 그 사실을 알려고, 언제든지 구매자가 전자적 대금지급과 관련한 자료를 열람할 수 있게 하는 조치합니다.<br/>
                                6. 회사가 제공하는 몰 서비스는 구매자와 판매자가 매매계약을 체결할 수 있도록 몰을 통해 정보를 제공하는 서비스로 제한되고, 제공된 정보를 바탕으로 매매계약을 체결할지 여부는 회원의 판단과 책임으로 결정합니다. 실질적인 자재 판매는 판매자의 의무이며 구체적인 조건은 구매자와 판매자가 체결하는 매매계약에 따릅니다. 회사는 구매자와 판매자가 매매계약을 체결하는 것을 지원하기 위해 회원들이 사용할 수 있는 표준 자재매매약관을 작성하여 몰에 게시할 수 있습니다.<br/>
                                7. 자재의 품질, 수량, 인도일자 준수 등과 관련된 구매자와 판매자의 권리와 책임 등은 매매계약에 따르므로, 구매자는 판매자가 등록한 자재 판매 정보와 판매자가 정하는 매매계약의 조건을 반드시 확인해야 합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 12 조 (대금의 결제 및 몰 서비스의 이용요금)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 구매자는 매매계약에 따른 대금/계약금을 회사가 제공하는 PG사 결제시스템을 통하여 결제합니다. 회사는 구매확정된 자재에 대해 대금/계약금에서 제2항의 몰 서비스 이용요금을 공제한 후 매월 25일 정산, 판매자에게 지급합니다.<br/>
                                2. 몰 서비스의 이용요금은 다음과 같습니다.
                                <div className='TermsOfUseSubContent'>
                                    - 대금/계약금의 10%(이하 “요율”이라 함)에 해당하는 금액. 요율은 자재 원산지, 운송조건 등에 따라 달리 정할 수 있으며, 회사의 정책에 따라 수시로 변경될 수 있습니다. 요율은 몰에 게시하며, 요율을 변경할 경우 변경예정일 15일 전에 공지합니다.<br/>
                                    - 서비스 이용요금은 PG사 수수료 등 각종 수수료를 포함한 것입니다.
                                </div>
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 13 조 (서비스의 변경)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 서비스의 전부 또는 일부를 변경할 수 있습니다. 다만, 변경된 내용이 중대하거나 회원에게 불리한 경우에는 회사가 서비스를 이용하는 회원에게 제5조(회원에 대한 통지)에 정한 방법으로 통지합니다.<br/>
                                2. 회사는 전항에 따른 서비스 변경에 대한 동의를 거절한 회원에 대하여는 변경 전 서비스를 제공합니다. 다만, 그러한 제공이 불가능할 경우 서비스의 제공을 중지하거나 이용계약을 해지할 수 있습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 14 조 (서비스의 중단)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
                                <div className='TermsOfUseSubContent'>
                                    1) 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br/>
                                    2) 회원이 본 약관의 규정을 위반하는 경우, 회원이 정당한 사유 없이 매칭 후 매매계약을 취소하는 경우<br/>
                                    3) 정전, 제반 설비의 장애 또는 이용양의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br/>
                                    4) 서비스 제공업자와의 계약 종료, 경영상의 판단 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우<br/>
                                    5) 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                                </div>
                                2. 제1항에 의한 서비스 중단의 경우에는 서비스 중단 7일 전 회사가 제5조에서 정한 방법으로 회원에게 통지합니다. 다만, 제1항 제4호에 의한 서비스 중단의 경우에는 서비스 중단 30일 전 회사가 제5조 제1항에서 정한 방법으로 회원에게 통지하며, 회사가 통제할 수 없는 사유로 인한 서비스의 중단 (회사의 고의, 과실이 없는 디스크 장애, 시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 사후에 통지할 수 있습니다.<br/>
                                3. 회사는 본 조에 따른 서비스의 변경, 중단으로 발생하는 문제에 대해서 회사에 고의, 과실이 없는 한 책임을 지지 않습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 15 조 (정보의 제공 및 광고의 게재)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 서비스 내 공지사항, 서비스 화면, 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보제공에 대해서 수신 거절을 할 수 있습니다.<br/>
                                2. 회사는 서비스의 운영과 관련하여 회사가 제공하는 서비스의 화면 및 홈페이지 등에 광고를 게재할 수 있습니다.
                            </div>
                        </div>
                    </div>  
                    <div className='TermsOfUseContentWrap'>
                        <div className='TermsOfUseTitleBox'>
                            제 4 장 책임 및 의무
                        </div>
                        <div className='TermsOfUseContentBox'>
                            <div className='TermsOfUseContentTitle'>
                                제 16 조 (회사의 의무) 
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 관련법령과 본 약관이 금지하거나 미풍약속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.<br/>
                                2. 회사는 서비스의 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 17 조 (회원의 의무)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회원은 다음 행위를 하여서는 안 됩니다.
                                <div className='TermsOfUseSubContent'>
                                    - 이용 신청 또는 변경 시 허위내용 등록<br/>
                                    - 타인의 정보도용<br/>
                                    - 회원 ID를 본인 외에 제3자로 하여금 이용하게 하는 행위<br/>
                                    - 개인정보 무단취득 및 도용<br/>
                                    - 기타 불법적이거나 부당한 행위<br/>
                                    - 허가되지 않은 정보의 취득
                                </div>
                                2. 회원이 몰 서비스를 통하여 구매/판매 등록/ 신청하는 경우 반드시 회사가 제공하는 결제시스템을 통하여 거래를 완료하여야 합니다. 회사는 회원 간의 직거래로 인하여 발행하는 문제에 대하여 책임지지 않으며, 직거래 신고가 접수될 경우 해당 회원은 몰 서비스 이용에 제한을 받을 수 있습니다. 또한 직거래가 회원이 구매/판매 등록/ 신청 정보를 확인한 후 서비스 이용요금 지급을 회피하기 위한 목적인 경우, 회사는 해당 구매/판매 신청에 대한 서비스 이용요금 상당액을 청구할 수 있습니다.<br/>
                                3. 아이디 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디 또는 비밀번호를 타인에게 양도, 대여, 공유, 제공해서는 안 됩니다.<br/>
                                4. 회사의 귀책사유 없이 아이디 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대해서는 회원 본인이 그에 대한 모든 책임을 부담합니다.<br/>
                                5. 회원은 아이디 또는 비밀번호를 도난당하거나 제3자가 무단으로 이를 사용하고 있음을 인지한 경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를 위하여 최선의 노력을 다합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 18 조 (개인정보 보호의무)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회원은 서비스를 이용하면서 얻은 타인(판매자의 경우, 구매자의 정보를 포함하되 이에 한정하지 아니하며, 이하 같음)의 개인정보를 본 약관에서 정한 목적 이외의 용도로 사용할 수 없으며, 이를 위반할 시 관련 법령에 따라 모든 민/형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책 시켜야 하고, 회사는 해당 회원의 서비스 이용을 제한할 수 있습니다.<br/>
                                2. 회사는 서비스의 제공 목적으로 회원에게 제공된 타인의 개인정보에 대하여 일정 기간이 지난 후 비공개 조치할 수 있습니다.<br/>
                                3. 회사가 개인정보를 보호하는데 주의를 하였음에도 불구하고 특정 회원이 제1항을 위반하여 타인의 개인정보를 유출하거나 유용한 경우 회사는 그에 대하여 아무런 책임을 지지 않습니다.<br/>
                                4. 회사는 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등으로부터 회원에 관한 정보 제공 요청을 받았을 경우 회사는 해당 자료를 제출할 수 있습니다.<br/>
                                5. 회사는 회원이 불법행위를 하였다고 인정될 경우 이에 대한 수사 등을 의뢰하기 위하여 관련 자료를 수사관서 등에 제출할 수 있고, 회원은 이에 동의합니다.<br/>
                                6. 회원은 회사로부터 제공받은 정보를 제공받은 목적(상품 서비스 제공 등)의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한 제공받은 개인정보의 주체로부터 직접 파기 요청을 받은 경우 이에 응해야 합니다. 회원은 관련 법령 및 개인정보보호위원회 고시에 따라 회사로부터 제공받은 개인정보를 보호하기 위하여 기술적∙관리적 보호조치를 취해야 합니다.<br/>
                                7. 회사는 회원의 개인정보를 보호하기 위해 개인정보취급방침을 수립하고 개인정보보호책임자를 지정하여 이를 게시하고 운영합니다.<br/>
                                8. 회사가 회원의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 회원의 동의를 받습니다. 
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 19 조 (대리행위의 부인)
                            </div>
                            <div className='TermsOfUseContent'>
                                회사는 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 매매계약을 포함하여 회원 사이에 성립된 거래 및 회원이 제공하고 등록한 정보에 대해서는 해당 회원이 그에 대한 책임을 부담하여야 합니다. 
                            </div>
                        </div>
                    </div>
                    <div className='TermsOfUseContentWrap'>
                        <div className='TermsOfUseTitleBox'>
                            제 5 장 면책 및 기타사항
                        </div>
                        <div className='TermsOfUseContentBox'>
                            <div className='TermsOfUseContentTitle'>
                                제 20 조 (면책사항)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br/>
                                2. 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.<br/>
                                3. 회사는 고의 또는 과실이 없는 한 회원이 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.<br/>
                                4. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 고의 또는 과실이 없는 한 이로 인한 손해를 배상할 책임도 없습니다.<br/>
                                5. 회원이 자신의 개인정보를 타인에게 유출 또는 제공함으로써 발생하는 피해에 대해서 회사의 고의 또는 과실이 없는 한 책임을 지지 않습니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 21 조 (손해배상)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 회원이 서비스를 이용함에 있어 회사의 고의 또는 과실로 인해 손해가 발생한 경우에는 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.<br/>
                                2. 회원이 이 약관을 위반하거나 관계 법령을 위반하여 회사에 손해가 발생한 경우에는 회사에 그 손해를 배상하여야 합니다.<br/>
                                3. 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 회원이 직접 책임 부담함을 원칙으로 합니다. 다만, 회사가 당해 문제를 제기한 제3자에게 우선 대응할 수 있으며, 이 과정에서 회사에게 비용 및 손해가 발생한 경우에는 그에 대하여 당해 회원에게 구상권을 행사합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 22 조 (서비스 종료)
                            </div>
                            <div className='TermsOfUseContent'>
                                회사가 서비스를 종료하고자 할 경우에는 서비스 종료 3개월전 제5조에 따른 통지로서 서비스를 종료할 수 있습니다. 이 경우 회사가 회원으로부터 지급받은 유료요금 등이 있는 경우에는 잔여 기간에 해당하는 것만큼 일할 계산하여 환불합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                제 23 조 (분쟁의 해결 및 관할법원)
                            </div>
                            <div className='TermsOfUseContent'>
                                1. 회사는 매매계약에 따른 분쟁 등 회원 상호간 분쟁에서 발생하는 문제에 대해서 일체의 책임을 지지 않습니다. 회원 상호간 분쟁은 당사자간에 직접 해결해야 합니다.<br/>
                                2. 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.<br/>
                                3. 본 조 제2항에 의하여서도 분쟁이 해결되지 않을 경우 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.<br/>
                                4. 본 약관과 관련된 사항에 대하여는 대한민국법을 준거법으로 합니다.
                            </div>
                            <div className='TermsOfUseContentTitle'>
                                ■ 부칙
                            </div>
                            <div className='TermsOfUseContent'>
                                이 약관은 2023년 08월 01일부터 적용됩니다.
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default Policy1;