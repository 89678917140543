import { useEffect, useState } from 'react';
import './AddressEdit.css';
import axios from 'axios';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';

const AddressEdit = () => {

    const location = useLocation();
    let address = location.state.address;
    const [name, setName] = useState(address.receiveName);
    const [tel, setTel] = useState(address.tel);
    const [zoneCode, setZoneCode] = useState(address.postNo);
    const [addr, setAddr] = useState(address.address);
    const [detailAddr, setDetailAddr] = useState(address.addressDetail);
    const [area, setArea] = useState(address.region);   // 지역 데이터 받아옴
    const [defaultAddr, setDefaultAddr] = useState(address.defaultYN);
    const navigate = useNavigate();

    // 받는 사람 수정 (숫자,영문,한글, () . - , 가능)
    const nameChange = (e) => {
        const nameChange = e.target.value.replace(/[^0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힝|(|)|.|/\s|,|-]/g, ''); // 숫자,영문,한글, () . - , 가능
        setName(nameChange);
    }

    // 휴대폰 번호 수정 (숫자만 입력 가능하게)
    const telChange = (e) => {
        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');  // 숫자만 입력 가능한 정규식
        setTel(onlyNumber);
    }

    // 상세주소 (숫자,영문,한글, () . - , 가능)
    const addressDetailChange = (e) => {
        const addressDetailChange = e.target.value.replace(/[^0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힝|(|)|.|/\s|,|-]/g, ''); // 숫자,영문,한글, () . - , 가능
        setDetailAddr(addressDetailChange);
    }

    // 주소 API 호출
    const addressPopup = async() => {      
        fnPopup();       
        window.name ="주소찾기";                                
        function fnPopup(){
            window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/address', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
        }        
    }

    // 주소 받아오기
    const getAddress = async(e) => {
        if(e.data.message==="OK"){          
            setZoneCode(e.data.zonecode);
            setAddr(e.data.addr);
            setArea(e.data.area);
            setDetailAddr('');
        }
    }

    // 기본 배송지 상태 (렌더링 시)
    const defaultAddrStatus = () => {
        if(defaultAddr === 'Y') {
            $('#AddressDefaulCheck').prop('checked', true);
        }else{
            $('#AddressDefaulCheck').prop('checked', false);
        }
    }

    // 기본 배송지 설정
    const defaultAddrCheck = () => {
        if(defaultAddr === 'Y') {
            $('#AddressDefaulCheck').prop('checked', false);
            setDefaultAddr('N');
        }else{
            $('#AddressDefaulCheck').prop('checked', true);
            setDefaultAddr('Y');
        }
    }

    // 배송지 수정
    const addressEdit = async () => {
        if(name.replace(/(^\s*)|(\s*$)/gi, "").length === 0){
            alert('받는 사람을 입력해 주세요.');
            return;
        }
        if(tel.replace(/(^\s*)|(\s*$)/gi, "").length === 0){
            alert('휴대폰 번호를 입력해 주세요.');
            return;
        }
        var params = new URLSearchParams();
        params.append('seq', address.seq);
        params.append('name', address.name);
        params.append('address', addr);
        params.append('addressdetail', detailAddr);
        params.append('postNo', zoneCode);
        params.append('region', area);
        params.append('tel', tel);
        params.append('receiveName', name);
        params.append('defaultYN', defaultAddr);
        const addressEdit = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/address/modify', params);
        if(addressEdit.data.success){
            alert('저장되었습니다.');
            navigate('/addresslist');
        }
    }

    useEffect(() => {
        defaultAddrStatus();
        window.addEventListener("message", getAddress, false);
        return () => {
            window.removeEventListener("message", getAddress, false);
        }
    }, []);

    return(
        <div className='AddressEditWrap'>
            <div className='AddressEditBox'>
                <div className='AddressEditSection'>
                    <div className="AddressEditTitle1">
                        받는 사람
                    </div>
                    <div className="AddressEditContentWrap1">
                        <div className="AddressEditInfoBox">
                            <input className="AddressEditInfoInput" value={name} onChange={nameChange} placeholder="받는 사람을 입력해 주세요." /> 
                        </div>
                    </div>
                </div>
                <div className='AddressEditSection'>
                    <div className="AddressEditTitle1">
                        휴대폰 번호
                    </div>
                    <div className="AddressEditContentWrap1">
                        <div className="AddressEditInfoBox">
                            <input className="AddressEditInfoInput" value={tel} onChange={telChange} placeholder="휴대폰 번호를 입력해 주세요." /> 
                        </div>
                    </div>
                </div>
                <div className='AddressEditSection'>
                    <div className="AddressEditTitle2">
                        주소
                    </div>
                    <div className="AddressEditContentWrap2">
                        <div className="AddressEditBox1">
                            <input className="AddressEditInput1" value={zoneCode} readOnly={true} placeholder="우편번호" />
                        </div>
                        <div className="AddressSearch" onClick={()=>addressPopup()}>
                            주소찾기
                        </div>
                        <div className="AddressEditBox2">
                            <input className="AddressEditInput2" value={addr} readOnly={true} placeholder="주소" />
                        </div>
                        <div className="AddressEditBox3">
                            <input className="AddressEditInput3" value={detailAddr} onChange={addressDetailChange} placeholder="상세주소를 입력해 주세요." /> 
                        </div>
                    </div>
                </div>
                <div className='AddressDefaulCheckBox' onClick={()=>defaultAddrCheck()}>
                    <input className='AddressDefaulCheck' type='checkbox' id="AddressDefaulCheck" />
                    <div className='AddressDefaulText'>
                        기본 배송지로 선택
                    </div>
                </div>
                <div className='AddressEditBtnBox'>
                    <div className='AddressEditBtn' onClick={()=>addressEdit()}>
                        저장
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressEdit;