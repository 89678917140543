import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import $ from 'jquery';
import ScrollTop from './Scroll/ScrollTop';
import Header from './Header/Header';
import Floating from './Floating/Floating';
import Main from './Main/Main';
import Login from './Login/Login';
import SignUp from './SignUp/SignUp';
import Idsearch from './IdSearch/IdSearch';
import PwReset from './PwReset/PwReset';
import Search from './Search/Search';
import ProductInfo from './ProductInfo/ProductInfo';
import MyInfo from './MyPage/MyInfo';
import AddressList from './MyPage/AddressList';
import AddressEdit from './MyPage/AddressEdit';
import AddressNew from './MyPage/AddressNew';
import OrderList from './MyPage/OrderList';
import Pay from './MyPage/Pay';
import PaySuccess from './MyPage/PaySuccess';
import PayInfo from './MyPage/PayInfo';
import Cart from './MyPage/Cart';
import AskList from './CScenter/AskList';
import AskView from './CScenter/AskView';
import AskWrite from './CScenter/AskWrite';
import Policy1 from './Policy/Policy1';
import Policy2 from './Policy/Policy2';
import Withdraw from './Withdraw/Withdraw';
import Footer from './Footer/Footer';
import Policy3 from './Policy/Policy3';

function App() {

    // 스크롤값이 0이 아닐때만 floating icon 노출
    const scrollRes = () => {
        let scrollTop = window.pageYOffset;
        if(scrollTop === 0){
            $('.FloatingWrap').css('display', 'none');
        }else{
            $('.FloatingWrap').css('display', 'block');
        }
    }
    
    useEffect(() => {
        scrollRes();    // 초기값을 위해 선언
        window.addEventListener('scroll', scrollRes);
        return () => {
            window.removeEventListener('scroll', scrollRes);
        };
    }, []);

    // 페이지 첫 진입 시 로그인 정보 지우기
    const loginInit = () => {
        if(sessionStorage.getItem('pageIn') === null){
            localStorage.clear();
            sessionStorage.setItem('pageIn', "Y");
        }
    }

    useEffect(() => {
        loginInit();
    }, [])
    
    return (
        <div className="App">
            <ScrollTop/>    {/* 페이지 이동 시 스크롤 Top으로 이동시키기 위한 component */}
            <Header/>
            <Floating/>
            <Routes>
                <Route path='/' element={<Main/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/signup' element={<SignUp/>} />
                <Route path='/idsearch' element={<Idsearch/>} />
                <Route path='/pwreset' element={<PwReset/>} />
                <Route path='/search' element={<Search/>} />
                <Route path='/productinfo' element={<ProductInfo/>} />
                <Route path='/myinfo' element={<MyInfo/>} />
                <Route path='/addresslist' element={<AddressList/>} />
                <Route path='/addressedit' element={<AddressEdit/>} />
                <Route path='/addressnew' element={<AddressNew/>} />
                <Route path='/orderlist' element={<OrderList/>} />
                <Route path='/pay' element={<Pay/>} />
                <Route path='/paysuccess' element={<PaySuccess/>} />
                <Route path='/payinfo' element={<PayInfo/>} />
                <Route path='/cart' element={<Cart/>} />
                <Route path='/asklist' element={<AskList/>} />
                <Route path='/askview' element={<AskView/>} />
                <Route path='/askwrite' element={<AskWrite/>} />
                <Route path='/policy1' element={<Policy1/>} />
                <Route path='/policy2' element={<Policy2/>} />
                <Route path='/policy3' element={<Policy3/>} />
                <Route path='/withdraw' element={<Withdraw/>} />
            </Routes>
            <Footer/>
        </div> 
    );
}

export default App;
