import React, { useEffect, useState } from 'react';
import './Main.css';
import axios from 'axios';
import $ from 'jquery';
import { NavLink, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Grid } from '@mui/material';
import backImg from '../assets/images/back_img.jpg';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PopUpModal from '../Modal/PopUpModal';
import ClipLoader from "react-spinners/ClipLoader";

const Main = () => {

    const [banner, setBanner] = useState([]);
    const [marbleCate, setMarbleCate] = useState([]);
    const [furnitureCate, setFurnitureCate] = useState([]);
    const [marble, setMarble] = useState([]);
    const [furniture, setFurniture] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [marbleStatus, setMarbleStatus] = useState(0);
    const [furnitureStatus, setFurnitureStatus] = useState(0);
    const [popUpNotice, setPopUpNotice] = useState("");
    const [noticeModal, setNoticeModal] = useState(false);
    const nowDate = new Date().getDate();   /* 오늘 일자 */
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // 최신 인조대리석 리스트
    const [isLoading2, setIsLoading2] = useState(false); // 최신 가구 리스트
    const [isLoading3, setIsLoading3] = useState(false); // 배너 리스트

    // 배너 swiper option
    const swiperOption = {
        modules: [Navigation, Pagination ],
        navigation: true,
        pagination: { clickable: true },
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true
    };

    // 방문 기록
    const setVisit = async () => {
        const temp = await axios.post(process.env.REACT_APP_AXIOS_URL+"v1/main/visit");
    }

    // 팝업 이미지 가져오기
    const getPopUpNotice = async () => {
        const temp = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getPopUp");
        if(temp.data.success){   // success 가 참일때만
            if(temp.data.data !== null) {
                setPopUpNotice(temp.data.data);
            }
        }
        // 팝업 오늘 하루 보지 않기 기능을 위해 예외처리
        if(+localStorage.getItem('closeDate') === nowDate || temp.data.data === null){
            setNoticeModal(false);
        }else{
            setNoticeModal(true);
        }
    }

    // 팝업 modal 닫기
    const noticeModalClose = () => {
        setNoticeModal(false);
        localStorage.removeItem('closeDate');
    }

    // 공지 팝업 오늘 하루 보지 않기
    const expiryNotice = () => {
        const expiry = new Date();
        const expiryDate = expiry.getDate();
        localStorage.setItem('closeDate', expiryDate);
        setNoticeModal(false);
    }

    // 텍스트 길이 자르기
    const limTxt = (txt) => {
        let text = String(txt);
        if(text.length>=55){
            text = text.substring(0, 55)+ "...";
        }
        return text;
    }

    //천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 배너 이미지 URL
    const getBannerUrl = fileName => {
        let bannerImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_BANNER; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            bannerImg = bannerImg + fileName;
        }
      
        return bannerImg;
    } 

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 배너 이미지 불러오기
    const getBanner = async () => {
        const getBanner = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getBannerList");
        if(getBanner.data.success){
            setBanner(getBanner.data.list);
            setIsLoading3(true);
        }
    }

    // 소카테고리 불러오기
    const getCategory = async () => {
        // 인조대리석
        const getMarbleCate = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getCategoryList",
        {
            params:{
                type: 1
            }
        })
        if(getMarbleCate.data.success){
            setMarbleCate(getMarbleCate.data.list);
        }
        // 가구
        const getFurnitureCate = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getCategoryList",
        {
            params:{
                type: 2
            }
        })
        if(getFurnitureCate.data.success){
            setFurnitureCate(getFurnitureCate.data.list);
        }
    }

    // 더보기 클릭
    const moreList = (num) => {
        let target = "#BigCate"+num;
        $('.BigCate, .SmallCate, .Sort').css('color', 'var(--baseFontColor)');
        $('.BigCate, .SmallCate, .Sort').css('fontWeight', 'normal');
        $(target).css('color', 'var(--mainColor)');
        $(target).css('fontWeight', 'bold');
        $('#Sort0').css('color', 'var(--mainColor)');
        $('#Sort0').css('fontWeight', 'bold');
        if(num === 1){
            $('#MarbleSmall0').css('color', 'var(--mainColor)');
            $('#MarbleSmall0').css('fontWeight', 'bold');
        }else{
            $('#FurnitureSmall0').css('color', 'var(--mainColor)');
            $('#FurnitureSmall0').css('fontWeight', 'bold');
        }
        sessionStorage.setItem('cateNum', num);
        navigate('/search', {
            state: {
                bigCate: num,
                smallCate: 0,
                keyword: '',
                orderBy: 0
            }
        });
        window.scrollTo(0, 0);
    }

    // 대리석 소카테고리 클릭
    const marbleCateClick = (num) => {
        let target = "#Marble"+num;
        $('.MarbleCate').css('backgroundColor','var(--whiteColor)');
        $('.MarbleCate').css('color','var(--baseFontColor)');
        $('.MarbleCate').css('border','1px solid var(--borderColor)');
        $(target).css('backgroundColor','var(--mainColor)');
        $(target).css('color','var(--whiteColor)');
        $(target).css('border','1px solid var(--mainColor)');
    }

    // 인조대리석 상품 불러오기 (최신순 최대 8개)
    const getMarble = async () => {
        const getMarble = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getProductList",
        {
            params:{
                lCategoryNo: 1,
                sCategoryNo: marbleStatus
            }
        })
        if(getMarble.data.success){
            setMarble(getMarble.data.list);
            marbleCateClick(marbleStatus);
            setIsLoading(true);
        }
    }

    // 가구 소카테고리 클릭
    const furnitureCateClick = (num) => {
        let target = "#Furniture"+num;
        $('.FurnitureCate').css('backgroundColor','var(--whiteColor)');
        $('.FurnitureCate').css('color','var(--baseFontColor)');
        $('.FurnitureCate').css('border','1px solid var(--borderColor)');
        $(target).css('backgroundColor','var(--mainColor)');
        $(target).css('color','var(--whiteColor)');
        $(target).css('border','1px solid var(--mainColor)');
    }

    // 가구 상품 불러오기 (최신순 최대 8개)
    const getFurniture = async () => {
        const getFurniture = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getProductList",
        {
            params:{
                lCategoryNo: 2,
                sCategoryNo: furnitureStatus
            }
        })
        if(getFurniture.data.success){
            setFurniture(getFurniture.data.list);
            furnitureCateClick(furnitureStatus);
            setIsLoading2(true);
        }
    }   

    // 인조 대리석 select 메뉴
    const marbleSelect = (e) => {
        setMarbleStatus(e.target.value);
    }

    // 인조 대리석 Mobile의 메뉴 변경에 따른 PC tab 색상 변화를 위해 (창크기 900 이상일때만 동작)
    const marbleMenuColor = () => {
        $('.MarbleCate').css('backgroundColor','var(--whiteColor)');
        $('.MarbleCate').css('color','var(--baseFontColor)');
        $('.MarbleCate').css('border','1px solid var(--borderColor)');
        if(marbleStatus === 0){
            $('#Marble0').css('backgroundColor','var(--mainColor)');
            $('#Marble0').css('color','var(--whiteColor)');
            $('#Marble0').css('border','1px solid var(--mainColor)');
        }else if(marbleStatus === 3){
            $('#Marble3').css('backgroundColor','var(--mainColor)');
            $('#Marble3').css('color','var(--whiteColor)');
            $('#Marble3').css('border','1px solid var(--mainColor)');
        }else if(marbleStatus === 4){
            $('#Marble4').css('backgroundColor','var(--mainColor)');
            $('#Marble4').css('color','var(--whiteColor)');
            $('#Marble4').css('border','1px solid var(--mainColor)');
        }else if(marbleStatus === 5){
            $('#Marble5').css('backgroundColor','var(--mainColor)');
            $('#Marble5').css('color','var(--whiteColor)');
            $('#Marble5').css('border','1px solid var(--mainColor)');
        }else if(marbleStatus === 6){
            $('#Marble6').css('backgroundColor','var(--mainColor)');
            $('#Marble6').css('color','var(--whiteColor)');
            $('#Marble6').css('border','1px solid var(--mainColor)');
        }else if(marbleStatus === 7){
            $('#Marble7').css('backgroundColor','var(--mainColor)');
            $('#Marble7').css('color','var(--whiteColor)');
            $('#Marble7').css('border','1px solid var(--mainColor)');
        }
    }

    // 가구 select 메뉴
    const furnitureSelect = (e) => {
        setFurnitureStatus(e.target.value);
    }

    // 가구 Mobile의 메뉴 변경에 따른 PC tab 색상 변화를 위해 (창크기 900 이상일때만 동작)
    const furnitureMenuColor = () => {
        $('.FurnitureCate').css('backgroundColor','var(--whiteColor)');
        $('.FurnitureCate').css('color','var(--baseFontColor)');
        $('.FurnitureCate').css('border','1px solid var(--borderColor)');
        if(furnitureStatus === 0){
            $('#Furniture0').css('backgroundColor','var(--mainColor)');
            $('#Furniture0').css('color','var(--whiteColor)');
            $('#Furniture0').css('border','1px solid var(--mainColor)');
        }else if(furnitureStatus === 8){
            $('#Furniture8').css('backgroundColor','var(--mainColor)');
            $('#Furniture8').css('color','var(--whiteColor)');
            $('#Furniture8').css('border','1px solid var(--mainColor)');
        }else if(furnitureStatus === 9){
            $('#Furniture9').css('backgroundColor','var(--mainColor)');
            $('#Furniture9').css('color','var(--whiteColor)');
            $('#Furniture9').css('border','1px solid var(--mainColor)');
        }else if(furnitureStatus === 10){
            $('#Furniture10').css('backgroundColor','var(--mainColor)');
            $('#Furniture10').css('color','var(--whiteColor)');
            $('#Furniture10').css('border','1px solid var(--mainColor)');
        }
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
    }

    useEffect(() => {
        setVisit();
        getBanner();
        getCategory();
    }, [])

    useEffect(() => {
        getMarble(0);
    }, [marbleStatus])

    useEffect(() => {
        getFurniture(0);
    }, [furnitureStatus])
    
    useEffect(() => {
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    useEffect(() => {
        if(window.innerWidth > 900){
            marbleMenuColor();
            furnitureMenuColor();
        }
    })

    useEffect(() => {
        getPopUpNotice();
    }, [])

    return(
        <>        
            <PopUpModal open={noticeModal}>
                <div className='NoticeModalWrap'>
                    <div className='NoticeModalImgBox'>
                        <img src={getBannerUrl(popUpNotice.filename)} alt="img" />
                    </div>
                    <div className='NoticeModalBtnWrap'>
                        <div className='NoticeModalBtnBox'>
                            <div className='NoticeModalBtnBoxText' onClick={()=>expiryNotice()}>
                                오늘 하루 보지 않기
                            </div>
                        </div>
                        <div className='NoticeModalBtnBox'>
                            <div className='NoticeModalBtnBoxText' onClick={()=>noticeModalClose()}>
                                닫기
                            </div>
                        </div>
                    </div>
                </div>
            </PopUpModal>
            <div className='BannerWrap'>
                <div className='BannerBox'>
                    {isLoading3 ? (
                        <Swiper {...swiperOption}>
                            {banner.map(
                                (banner) => (
                                    <SwiperSlide key={banner.seq}>
                                        <img src={getBannerUrl(banner.filename)} alt="img" />
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                    ):(
                        <div className='NonProductWrap'>
                            <ClipLoader
                                color="var(--mainColor)"
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='MainWrap'>
                <div className='MainBox'>
                    <div className='ProductWrap'>
                        <div className='ProductTitleBox'>                            
                            <div className='ProductTitle'>
                                최신 인조대리석
                            </div>
                            <div className='ProductMore' onClick={()=>moreList(1)}>
                                더보기 &gt;
                            </div>
                        </div>
                        {width > 900 ? (
                            <div className='ManufacturerBox'>
                                <div className='MarbleCate' id="Marble0" onClick={()=>setMarbleStatus(0)}>
                                    제조사 전체
                                </div>
                                {marbleCate.map(
                                    (cate) => (
                                        <div className='MarbleCate' id={"Marble"+cate.seq} key={cate.seq} onClick={()=>setMarbleStatus(cate.seq)}>
                                            {cate.name}
                                        </div>
                                    )
                                )}
                            </div>
                        ):(
                            <div className='ManufacturerBoxM'>
                                <FormControl sx={{ minWidth: 100 }} size="small">
                                    <InputLabel id="demo-select-small-label">제조사</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={marbleStatus}
                                        label="제조사"
                                        onChange={marbleSelect}
                                    >
                                        <MenuItem className='MenuItem' value={0}>전체</MenuItem>
                                        {marbleCate.map(
                                            (cate) => (
                                                <MenuItem className='MenuItem' value={cate.seq} key={cate.seq}>{cate.name}</MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </div>

                        )}
                        {isLoading ? (
                            <div className='ProductInfoBox'>
                                {marble.length !== 0 ? (
                                    <Grid container rowSpacing={{xs:2, md:3}} columnSpacing={2}>
                                        {marble.map(
                                            (product) => (
                                                <Grid item xs={6} md={3} key={product.seq}>
                                                    <NavLink className="NavLink" to='/productinfo' 
                                                        state={{
                                                            productInfo: product
                                                        }}
                                                    >
                                                        <div className='ProductInfo'>
                                                            <div className='ProductImgBox'>
                                                                <img className='BackImg' src={backImg} alt='img' />
                                                                <img className='ProductImg' src={getProductUrl(product.productImg)} alt='img' />
                                                            </div>
                                                            <div className='ProductName'>
                                                                {limTxt(product.name)}
                                                            </div>
                                                            <div className='ProductPrice'>
                                                                {addComma(product.price)}원
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                ):( 
                                    <div className='NonProductWrap'>
                                        등록된 상품이 없습니다.
                                    </div>
                                )}
                            </div>
                        ):(
                            <div className='NonProductWrap'>
                                <ClipLoader
                                    color="var(--mainColor)"
                                    size={50}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        )}
                    </div>
                    <div className='ProductWrap'>
                        <div className='ProductTitleBox'>
                            <div className='ProductTitle'>
                                최신 가구
                            </div>
                            <div className='ProductMore' onClick={()=>moreList(2)}>
                                더보기 &gt;
                            </div>
                        </div>
                        {width > 900 ? (
                            <div className='ManufacturerBox'>
                                <div className='FurnitureCate' id="Furniture0" onClick={()=>setFurnitureStatus(0)}>
                                    제조사 전체
                                </div>
                                {furnitureCate.map(
                                    (cate) => (
                                        <div className='FurnitureCate' id={"Furniture"+cate.seq} key={cate.seq} onClick={()=>setFurnitureStatus(cate.seq)}>
                                            {cate.name}
                                        </div>
                                    )
                                )}
                            </div>
                        ):(
                            <div className='ManufacturerBoxM'>
                                <FormControl sx={{ minWidth: 100 }} size="small">
                                    <InputLabel id="demo-select-small-label">제조사</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={furnitureStatus}
                                        label="제조사"
                                        onChange={furnitureSelect}
                                    >
                                        <MenuItem className='MenuItem' value={0}>전체</MenuItem>
                                        {furnitureCate.map(
                                            (cate) => (
                                                <MenuItem className='MenuItem' value={cate.seq} key={cate.seq}>{cate.name}</MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {isLoading2 ? (
                            <div className='ProductInfoBox'>
                                {furniture.length !== 0 ? (
                                    <Grid container rowSpacing={{xs:2, md:3}} columnSpacing={2}>
                                        {furniture.map(
                                            (product) => (
                                                <Grid item xs={6} md={3} key={product.seq}>
                                                    <NavLink className="NavLink" to='/productinfo' 
                                                        state={{
                                                            productInfo: product
                                                        }}
                                                    >
                                                        <div className='ProductInfo'>
                                                            <div className='ProductImgBox'>
                                                                <img className='BackImg' src={backImg} alt='img' />
                                                                <img className='ProductImg' src={getProductUrl(product.productImg)} alt='img' />
                                                            </div>
                                                            <div className='ProductName'>
                                                                {limTxt(product.name)}
                                                            </div>
                                                            <div className='ProductPrice'>
                                                                {addComma(product.price)}원
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                ):(
                                    <div className='NonProductWrap'>
                                        등록된 상품이 없습니다.
                                    </div>
                                )}
                            </div>
                        ):(
                            <div className='NonProductWrap'>
                                <ClipLoader
                                    color="var(--mainColor)"
                                    size={50}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Main;