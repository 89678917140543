import './IdSearch.css';
import { NavLink, useLocation } from 'react-router-dom';

const IdSearch = () => {

    const location = useLocation();
    let searchId = location.state.searchId;

    // 마지막 2글자 마스킹 처리
    const idMasking = (id) => {
        return id.substring(0, id.length - 2) + "**";
    }

    return(
        <div className='IdSearchWrap'>
            <div className='IdSearchBox'>
                <div className='IdSearchTitle'>
                    아이디 찾기
                </div>
                <div className='IdSearchText'>
                    <p>인증된 번호로 가입한 아이디는</p>
                    <b>{idMasking(searchId)}</b>
                    <p>입니다.</p>
                </div>
                <NavLink to='/login'>
                    <div className='LoginLinkBtn'>
                        로그인 하러가기
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default IdSearch;