import React, { useEffect, useState } from 'react';
import './AskList.css';
import axios from 'axios';
import $ from 'jquery';
import { Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

var cuPageArray = [];   // 현재 보여지는 페이지 리스트 담겨 있는 배열
var cuPage = 1;        // 현재 페이지 리스트의 첫 페이지
var cuEndPage = 10;    // 현재 페이지 리스트의 마지막 페이지
var moreNum = 1;       // 무한스크롤을 위한 변수

// 1:1문의 리스트 페이지네이션
const setPageList = (totalPage) => {
    $('.PagiNationArrow').css('display', 'flex');
    cuPageArray = [];
    cuEndPage = cuPage + 9;
    if(totalPage<=cuEndPage) {
        cuEndPage = totalPage;
        displayArrow(2);        
    }
    if(cuPage===1){
        displayArrow(1);   
    }
    for(let i=cuPage; i<=cuEndPage; i++){
        cuPageArray[i-1] = i;
    }
}

// 페이지네이션 arrow 예외처리
const displayArrow = (cbc) => {
    switch (cbc) {
        case 1:
            $('#PagiNationFirst').css('display', 'none');
            $('#PagiNationPrev').css('display', 'none');
            break;
        case 2:
            $('#PagiNationEnd').css('display', 'none');
            $('#PagiNationNext').css('display', 'none');
            break;         
        default:
    }
}

const AskList = () => {

    const [askList, setAskList] = useState([]);
    const [askListCount, setAskListCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentIndexStart, setCurrentIndexStart] = useState(1);
    const [DBpage, setDBpage] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // 1:1 문의 리스트

    // 텍스트 길이 자르기
    const limTxt = (txt) => {
        let text = String(txt);
        if(text.length>=35){
            text = text.substring(0, 35)+ "...";
        }
        return text;
    }

    // 1:1 문의 리스트 불러오기
    const getAskList = async (pageNo, size) => {
        if(pageNo===undefined){
            pageNo = DBpage;
        }else{
            setDBpage(pageNo);
        }
        setCurrentIndexStart(pageNo*10);
        const getAskList = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/customer/getList",
        {
            params:{
                pageNo: pageNo,
                size: size
            }
        })
        if(getAskList.data.success){
            setAskList(getAskList.data.list);
            setIsLoading(true);
        }
    }

    // 1:1문의 리스트 Count
    const getAskListCount = async () => {
        const getAskListCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/customer/getListCount");
        if(getAskListCount.data.success){
            const total = Math.ceil(getAskListCount.data.data / 10);
            setPageList(total);
            setTotalPage(total);
            setAskListCount(getAskListCount.data.data);
            if(getAskListCount.data.data === 0){
                $('.AskListPagiNationWrap').css('display', 'none');
            }else{
                $('.AskListPagiNationWrap').css('display', 'flex');
            }
        }
    }

    // 페이지 이동 
    const goNext = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');
        var total = totalPage;
        let calcPage = Math.floor(total / 10)  * 10 + 1;
        let checkPage = total - Math.floor(total / 10)  * 10;
        if(edge===1){
            if(checkPage === 0) {
                cuPage = Math.floor(total / 10) * 10 - 9; 
            }else{
                if(calcPage === 1) {
                    cuPage = 1
                }else{
                    cuPage = Math.floor(total / 10) * 10 + 1; 
                }
            }
        }else{
            cuPage = cuPage + 10;
            if(cuPage>total) cuPage = total;   
        }
        setPageList(total);
        if(edge===1) {
            goPage(total);
        }else{
            goPage(cuPage);
        }
    }

    // 페이지 이동 
    const goPre = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');   
        var total = totalPage;     
        if(edge===1){
            cuPage = 1; 
        }else{
            cuPage = cuPage - 10;
            if(cuPage<1) cuPage = 1;
        }  
        setPageList(total);
        goPage(cuPage);
    }

    // 페이지 이동 및 현재 페이지 넘버링 색상 변경
    const goPage = (no) => {
        $('html, body').scrollTop(0);
        if(no===undefined){
            no = currentPage;
        }
        setCurrentPage(no);
        var realNo = no - 1;
        getAskList(realNo, 10);
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+no).css('color', 'white');
        $('#PagiNationNum'+no).css('background-color', 'var(--mainColor)');
    }

    // 1:1문의 view 페이지 이동
    const askViewPage = (askInfo) => {
        navigate('/askview', {
            state: {
                askInfo: askInfo
            }
        });
    }

    // 1:1문의 작성 페이지 이동
    const askWritePage = () => {
        navigate('/askwrite', {
            state: {
                askSeq: 0,
                askCate: "normal",
                companyUserId: '',
                productName: '',
                productColor: '',
                productPrice: ''
            }
        });
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
    }

    // 무한 스크롤
    const moremoreList = () => {
        let scrollT = $(window).scrollTop();
        let documentH = $(document).height();
        let windowH = $(window).height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(5*(moreNum) < askListCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                getAskList(0, 10*(moreNum));
            }
        }
    }

    useEffect(() => {
        getAskList(0, 10);
        getAskListCount();
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [])

    // 페이지네이션 CSS
    useEffect(()=> {
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+currentPage).css('color', 'white');
        $('#PagiNationNum'+currentPage).css('background-color', 'var(--mainColor)');
    });

    // 모바일용 무한 스크롤 동작
    useEffect(() => {
        if(width <= 900){
            window.addEventListener('scroll', moremoreList);
            return () => {
                window.removeEventListener('scroll', moremoreList);
            };
        }
    });

    return(
        <div className="AskListWrap">
            <div className='AskListBox'>
                <div className='AskListBtnWrap'>
                    <div className='AskBtnBox'>
                        <div className='AskBtn' onClick={()=>askWritePage()}>
                            문의하기
                        </div>
                    </div>
                </div>
                {width > 900 &&
                    <div className='AskHeaderWrap'>
                        <div className='AskHeaderBox'>
                            <Grid container>
                                <Grid item xs={2} md={2}>
                                    <div className='AskHeaderNum'>
                                        번호
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <div className='AskHeaderTitle'>
                                        제목
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='AskHeaderDate'>
                                        문의일자
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='AskHeaderAnswer'>
                                        답변여부
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
                {isLoading ? (
                    <>
                        <div className='AskContentsWrap'>
                            {askListCount !== 0 ? (
                                <>
                                    {width > 900 ? (
                                        <>
                                            {askList.map(
                                                (ask,index) => (
                                                    <div className='AskContentBox' key={ask.seq}>
                                                        <Grid container>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='AskContentNum'>
                                                                    {(index+1)+currentIndexStart}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6} md={6}>
                                                                <div className='AskContentTitle' onClick={()=>askViewPage(ask)}>
                                                                    {limTxt(ask.title)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='AskContentDate'>
                                                                    {ask.regDate}
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                {ask.answerSeq === 0 ? (
                                                                    <div className='AskContentAnswer'>
                                                                        검토중
                                                                    </div>
                                                                ):(
                                                                    <div className='AskContentAnswer'>
                                                                        답변완료
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {askList.map(
                                                (ask,index) => (
                                                    <div className='AskListContentBoxM' key={ask.seq}>
                                                        <div className='AskListContentNumM'>
                                                            번호 : {(index+1)+currentIndexStart}
                                                        </div>
                                                        <div className='AskListContentDateM'>
                                                            문의일자 : {ask.regDate}
                                                        </div>
                                                        <div className='AskListContentTitleM'>
                                                            제목 : {limTxt(ask.title)}
                                                        </div>
                                                        {ask.answerSeq === 0 ? (
                                                            <div className='AskListContentAnswerM'>
                                                                답변여부 : 검토중
                                                            </div>
                                                        ):(
                                                            <div className='AskListContentAnswerM'>
                                                                답변여부 : 답변완료
                                                            </div>
                                                        )}
                                                        <div className='AskViewBtnM' onClick={()=>askViewPage(ask)}>
                                                            내용보기
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            ):(
                                <div className='NonAskWrap'>
                                    문의 내역이 없습니다.
                                </div>
                            )}
                        </div>
                        {width > 900 &&
                            <>
                                <div className="AskListPagiNationWrap">
                                    <div className="PagiNationBox">
                                        <div className="PagiNationArrow" id="PagiNationFirst" onClick={()=>goPre(1)}>
                                            <KeyboardDoubleArrowLeftIcon />
                                        </div>                                
                                        <div className="PagiNationArrow" id="PagiNationPrev" onClick={()=> goPre(0)}>
                                            <KeyboardArrowLeftIcon />
                                        </div>
                                        <div className="PagiNationNumBox">
                                            {cuPageArray.map(
                                                (pageArray) => (
                                                    <React.Fragment key={pageArray}>
                                                        <div className="PagiNationNum" onClick={()=> goPage(pageArray)}>
                                                            <div className="PagiNationNumArea" id={"PagiNationNum"+pageArray}>
                                                                {pageArray}
                                                            </div>
                                                        </div>                                    
                                                    </React.Fragment>
                                                )
                                            )}                         
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationNext" onClick={()=> goNext(0)}>
                                            <KeyboardArrowRightIcon />
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationEnd" onClick={()=> goNext(1)}>
                                            <KeyboardDoubleArrowRightIcon />
                                        </div>         
                                    </div>
                                </div>
                            </>
                        }
                    </>
                ):( 
                    <div className='NonAskWrap'>
                        <ClipLoader
                            color="var(--mainColor)"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AskList;