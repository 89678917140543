import React, { useEffect, useState } from 'react';
import './Cart.css';
import axios from 'axios';
import $ from 'jquery';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backImg from '../assets/images/back_img.jpg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ClipLoader from "react-spinners/ClipLoader";

var cuPageArray = [];   // 현재 보여지는 페이지 리스트 담겨 있는 배열
var cuPage = 1;        // 현재 페이지 리스트의 첫 페이지
var cuEndPage = 10;    // 현재 페이지 리스트의 마지막 페이지
var moreNum = 1;       // 무한스크롤을 위한 변수

// 장바구니 페이지네이션
const setPageList = (totalPage) => {
    $('.PagiNationArrow').css('display', 'flex');
    cuPageArray = [];
    cuEndPage = cuPage + 9;
    if(totalPage<=cuEndPage) {
        cuEndPage = totalPage;
        displayArrow(2);        
    }
    if(cuPage===1){
        displayArrow(1);   
    }
    for(let i=cuPage; i<=cuEndPage; i++){
        cuPageArray[i-1] = i;
    }
}

// 페이지네이션 arrow 예외처리
const displayArrow = (cbc) => {
    switch (cbc) {
        case 1:
            $('#PagiNationFirst').css('display', 'none');
            $('#PagiNationPrev').css('display', 'none');
            break;
        case 2:
            $('#PagiNationEnd').css('display', 'none');
            $('#PagiNationNext').css('display', 'none');
            break;         
        default:
    }
}

const Cart = () => {

    const [cartList, setCartList] = useState([]);
    const [cartListCount, setCartListCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [DBpage, setDBpage] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [selectSeq, setSelectSeq] = useState('');
    const [selectPrice, setSelectPrice] = useState(0);
    const [selectSeqM, setSelectSeqM] = useState('');
    const [selectPriceM, setSelectPriceM] = useState(0);
    const [selectSeqListM, setSelectSeqListM] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // 장바구니 리스트

    // 천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 장바구니 리스트 불러오기
    const getCartList = async (pageNo, size) => {
        if(pageNo===undefined){
            pageNo = DBpage;
        }else{
            setDBpage(pageNo);
        }
        const getCartList = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getOrderList",
        {
            params:{
                status: "bucket",
                pageNo: pageNo,
                size: size
            }
        })
        if(getCartList.data.success){
            setCartList(getCartList.data.list);
            setIsLoading(true);
            // 수량 데이터를 보여주기 위해서
            const quantityList = getCartList.data.list.map(
                (list) => {
                    return ({seq:list.seq, quantity:list.quantity});
                }
            )
            setQuantity(quantityList);
            // 무한스크롤 시 체크박스 풀리지 않게하기 위해
            let target = "#CartCheckM"
            selectSeqListM.map(
                (list) => {
                    return $(target+list).prop("checked", true);
                }
            )
        }
    }

    // 장바구니 리스트 Count
    const getCartListCount = async () => {
        const getCartListCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getOrderListCount",
        {
            params:{
                status: "bucket"
            }
        })
        if(getCartListCount.data.success){
            const total = Math.ceil(getCartListCount.data.data / 5);
            setPageList(total);
            setTotalPage(total);
            setCartListCount(getCartListCount.data.data);
            if(getCartListCount.data.data === 0){
                $('.CartPagiNationWrap').css('display', 'none');
            }else{
                $('.CartPagiNationWrap').css('display', 'flex');
            }
        }
    }

    // 수량 데이터
    const getQuantity = (seq) => {
        if(quantity && quantity.length!==0){
            const temp = quantity.filter((quantity) => quantity.seq === seq)[0];
            if(temp && temp.length!==0){
                return temp.quantity;
            }
        }
    }

    // 수량 수정 (숫자만 입력 가능하게)
    const quantityChange = (changeQuantity, seq) => {
        const onlyNumber = changeQuantity.replace(/[^0-9]/g, '');  // 숫자만 입력 가능한 정규식
        const nonZero = onlyNumber.replace(/(^0+)/, "");  // 앞자리가 0 일때는 입력 불가 정규식
        if(quantity){
            const quantityList = quantity.map((quantity) => {
                if(quantity.seq===seq) {
                    return (
                        {
                            seq: seq,
                            quantity: nonZero
                        }
                    )
                }else{
                    return (
                        {
                            seq: quantity.seq,
                            quantity: quantity.quantity
                        }
                    )
                }
            })
            setQuantity(quantityList);
        }
    }
    
    // 구매 수량 수정
    const quantityModify = async (seq) => {
        let tempOrder;
        if(cartList && cartList.length!==0){
            const tempList = cartList.filter((cart) => cart.seq === seq)[0];
            if(tempList && tempList.length!==0){
                tempOrder = tempList;
                tempOrder.quantity = getQuantity(seq);
                if(tempOrder.quantity === ""){
                    alert("수량을 입력해 주세요.");
                    getCartList(0, 5);
                    pageInit();
                    $('html, body').scrollTop(0);
                    return;
                }
                var params = new URLSearchParams();
                params.append('seq', seq);
                params.append('quantity', tempOrder.quantity);
                const changeInfo = await axios.post(process.env.REACT_APP_AXIOS_URL+"v1/deal/bucketMod", params);
                if(changeInfo.status === 200){
                    alert("수정 되었습니다.");
                    getCartList(0, 5);
                    pageInit();
                    $('html, body').scrollTop(0);
                }
            }
        }
    }

    // 페이지 이동 
    const goNext = (edge) => {
        $('html, body').scrollTop(0);
        $("#CartHeaderCheck").prop("checked", false);
        $(".CartCheck").prop("checked", false);
        $('.PagiNationArrow').css('display', 'flex');
        setSelectPrice(0);
        setSelectSeq('');
        var total = totalPage;
        let calcPage = Math.floor(total / 10)  * 10 + 1;
        let checkPage = total - Math.floor(total / 10)  * 10;
        if(edge===1){
            if(checkPage === 0) {
                cuPage = Math.floor(total / 10) * 10 - 9; 
            }else{
                if(calcPage === 1) {
                    cuPage = 1
                }else{
                    cuPage = Math.floor(total / 10) * 10 + 1; 
                }
            }
        }else{
            cuPage = cuPage + 10;
            if(cuPage>total) cuPage = total;   
        }
        setPageList(total);
        if(edge===1) {
            goPage(total);
        }else{
            goPage(cuPage);
        }
    }

    // 페이지 이동 
    const goPre = (edge) => {
        $('html, body').scrollTop(0);
        $("#CartHeaderCheck").prop("checked", false);
        $(".CartCheck").prop("checked", false);
        $('.PagiNationArrow').css('display', 'flex');   
        setSelectPrice(0);
        setSelectSeq('');
        var total = totalPage;     
        if(edge===1){
            cuPage = 1; 
        }else{
            cuPage = cuPage - 10;
            if(cuPage<1) cuPage = 1;
        }  
        setPageList(total);
        goPage(cuPage);
    }

    // 페이지 이동 및 현재 페이지 넘버링 색상 변경
    const goPage = (no) => {
        $('html, body').scrollTop(0);
        $("#CartHeaderCheck").prop("checked", false);
        $(".CartCheck").prop("checked", false);
        setSelectPrice(0);
        setSelectSeq('');
        if(no===undefined){
            no = currentPage;
        }
        setCurrentPage(no);
        var realNo = no - 1;
        getCartList(realNo, 5);
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+no).css('color', 'white');
        $('#PagiNationNum'+no).css('background-color', 'var(--mainColor)');
    }

    // 페이지네이션 초기화
    const pageInit = () => {
        setCurrentPage(1);
        cuPage = 1;
        moreNum = 1;
    }

    // 단일체크 PC
    const checkList = (info) => {
        let target = "#CartCheck"+info.seq;
        let seq = info.seq;
        let price = info.totalPrice;
        if($(target).is(":checked")){
            setSelectPrice(selectPrice+price);
            setSelectSeq(selectSeq.concat(seq+','));
        }else{
            setSelectPrice(selectPrice-price);
            setSelectSeq(selectSeq.replace(seq+',',''));
        }
        // 전체 체크박스 연동 
        var total = $("input[name=check]").length;
        var checked = $("input[name=check]:checked").length;
        if(total !== checked) {
            $(".CartHeaderCheck").prop("checked", false);
        }else{
            $(".CartHeaderCheck").prop("checked", true); 
        }
    }

    // 전체체크 PC
    const totalCheck = (list) => {
        let price = 0;
        let seq = '';
        if($("#CartHeaderCheck").is(":checked")) {
            $("input[name=check]").prop("checked", true);
            list.map(
                (list) => {
                    price += list.totalPrice;
                    seq += list.seq+',';
                }
            )
            setSelectSeq(seq);
            setSelectPrice(price);
        }else{
            $("input[name=check]").prop("checked", false);
            setSelectPrice(0);
            setSelectSeq('');
        }
    }

    // 단일체크 Mobile
    const checkListM = (info) => {
        let target = "#CartCheckM"+info.seq;
        let seq = info.seq;
        let price = info.totalPrice;
        if($(target).is(":checked")){
            setSelectPriceM(selectPriceM+price);
            setSelectSeqM(selectSeqM.concat(seq+','));
            setSelectSeqListM(selectSeqListM.concat(seq));  // 무한스크롤 시 체크박스 풀리지 않게하기 위해 (배열에서 seq 추가)
        }else{
            setSelectPriceM(selectPriceM-price);
            setSelectSeqM(selectSeqM.replace(seq+',',''));
            // 무한스크롤 시 체크박스 예외처리를 위해서 (배열에서 seq 제거)
            let seqList = selectSeqListM.filter(seqList => seqList !== seq);
            setSelectSeqListM(seqList);
        }
    }

    // 장바구니 삭제 (선택삭제 or 장바구니 비우기)
    const cartDel = async (status) => {
        var params = new URLSearchParams();
        if(status === "select"){
            if(width > 900){
                params.append('seqList', selectSeq.slice(0,-1));
                if(selectSeq === ''){
                    alert('선택한 상품이 없습니다.');
                    return;
                }
            }else{
                params.append('seqList', selectSeqM.slice(0,-1));
                if(selectSeqM === ''){
                    alert('선택한 상품이 없습니다.');
                    return;
                }
            }
            if(window.confirm('삭제하시겠습니까?')){
                const cartDel = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/bucketDel', params);
                if(cartDel.data.success){
                    alert('삭제되었습니다.');
                    if(width > 900){
                        setSelectPrice(0);
                        setSelectSeq('');
                    }else{
                        setSelectPriceM(0);
                        setSelectSeqM('');
                    }
                    getCartList(0, 5);
                    getCartListCount();
                    pageInit();
                    $('html, body').scrollTop(0);
                    $(".CartHeaderCheck").prop("checked", false);
                }   
            }
        }else{
            params.append('seqList', 'all');
            if(window.confirm('장바구니를 비우시겠습니까?')){
                const cartDel = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/bucketDel', params);
                if(cartDel.data.success){
                    alert('장바구니를 비웠습니다.');
                    if(width > 900){
                        setSelectPrice(0);
                        setSelectSeq('');
                    }else{
                        setSelectPriceM(0);
                        setSelectSeqM('');
                    }
                    getCartList(0, 5);
                    getCartListCount();
                    pageInit();
                    $('html, body').scrollTop(0);
                    $(".CartHeaderCheck").prop("checked", false);
                }   
            }
        }
    }

    // 주문 요청
    const orderRequest = async () => {
        var params = new URLSearchParams();
        if(width > 900){
            params.append('seqList', selectSeq.slice(0,-1));
            if(selectSeq === ''){
                alert('선택한 상품이 없습니다.');
                return;
            }
        }else{
            params.append('seqList', selectSeqM.slice(0,-1));
            if(selectSeqM === ''){
                alert('선택한 상품이 없습니다.');
                return;
            }
        }
        const orderRequest = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/requestMulti', params);
        if(orderRequest.data.success){
            alert('마이페이지 -> 주문 내역 페이지에서 구매 가능 여부 확인 및 결제가 가능합니다.');
            getCartList(0, 5);
            getCartListCount();
            pageInit();
            setSelectPriceM(0);
            setSelectSeqM('');
            setSelectPrice(0);
            setSelectSeq('');
            $('html, body').scrollTop(0);
            $(".CartHeaderCheck").prop("checked", false);
            if(window.confirm('주문 내역 페이지로 이동하시겠습니까?')){
                navigate('/orderlist', {
                    state: {
                        status: "request"
                    }
                });
            }
        }
    }

    // 상품 상세페이지 이동
    const productInfoPage = (product) => {
        navigate('/productinfo', {
            state: {
                productInfo: product
            }
        });
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
        // PC와 Mobile 사이즈에 따른 초기화 처리
        if(window.innerWidth > 900){
            setSelectSeqM('');
            setSelectPriceM(0);
        }else{
            setSelectSeq('');
            setSelectPrice(0);
        }
    }

    // 무한 스크롤
    const moremoreList = () => {
        let scrollT = $(window).scrollTop();
        let documentH = $(document).height();
        let windowH = $(window).height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(5*(moreNum) < cartListCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                getCartList(0, 5*(moreNum));
            }
        }
    }

    useEffect(() => {
        getCartList(0, 5);
        getCartListCount();
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [])

    // 페이지네이션 CSS
    useEffect(()=> {
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+currentPage).css('color', 'white');
        $('#PagiNationNum'+currentPage).css('background-color', 'var(--mainColor)');
    });

    // 모바일용 무한 스크롤 동작
    useEffect(() => {
        if(width <= 900){
            window.addEventListener('scroll', moremoreList);
            return () => {
                window.removeEventListener('scroll', moremoreList);
            };
        }
    });
    
    return(
        <div className='CartWrap'>
            <div className='CartBox'>
                {cartListCount !== 0 &&
                    <div className='CartBtnWrap'>
                        <div className='CartBtnBox'>
                            <div className='CartDelBtn' onClick={()=>cartDel("select")}>
                                선택삭제
                            </div>
                            <div className='CartTotalDelBtn' onClick={()=>cartDel("all")}>
                                장바구니 비우기
                            </div>
                        </div>
                    </div>
                }
                {width > 900 &&
                    <div className='CartHeaderWrap'>
                        <div className='CartHeaderBox'>
                            <Grid container>
                                <Grid item xs={1} md={1}>
                                    <div className='CartHeaderCheckBox'>
                                        <input className='CartHeaderCheck' id="CartHeaderCheck" type='checkbox' onClick={()=>totalCheck(cartList)} />
                                    </div>
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <div className='CartHeaderInfo'>
                                        상품정보
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='CartHeaderQuantity'>
                                        구매 수량
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='CartHeaderPrice'>
                                        상품 금액 
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
                {isLoading ? (
                    <>
                        <div className='CartContentWrap'>
                            {cartListCount !== 0 ? (
                                <>
                                    {width > 900 ? (
                                        <>
                                            {cartList.map(
                                                (cart) => (
                                                    <div className='CartContentBox' key={cart.seq}>
                                                        <Grid container>
                                                            <Grid item xs={1} md={1}>
                                                                <div className='CartCheckBox'>
                                                                    <input className='CartCheck' id={"CartCheck"+cart.seq} name='check' type='checkbox' onClick={()=>checkList(cart)} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={7} md={7}>
                                                                <div className='CartInfo'>
                                                                    <div className='CartImgBox' onClick={()=>productInfoPage(cart.product)}>
                                                                        <img className='BackImg' src={backImg} alt='img' />
                                                                        <img className='CartImg' src={getProductUrl(cart.product.productImg)} alt='img' />
                                                                    </div>
                                                                    <div className='CartNameBox' onClick={()=>productInfoPage(cart.product)}>
                                                                        {cart.product.name}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='CartQuantity'>
                                                                    <div className='CartQuantityBox'>
                                                                        <div className='CartQuantityInputBox'>
                                                                            <input className='CartQuantityInput' value={getQuantity(cart.seq)} onChange={(e) => quantityChange(e.target.value, cart.seq)} type='text' />
                                                                            개
                                                                        </div>
                                                                        <div className='CartModifyBtnBox'>
                                                                            <div className='CartModifyBtn' onClick={()=>quantityModify(cart.seq)}>
                                                                                수량 수정
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='CartPrice'>
                                                                    {addComma(cart.totalPrice)}원
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {cartList.map(
                                                (cart) => (
                                                    <div className='CartContentBoxM' key={cart.seq}>
                                                        <div className='CartCheckBoxM'>
                                                            <input className='CartCheckM' id={"CartCheckM"+cart.seq} name='checkM' type='checkbox' onClick={()=>checkListM(cart)} />
                                                        </div>
                                                        <div className='CartInfoM'>
                                                            <div className='CartImgBoxM' onClick={()=>productInfoPage(cart.product)}>
                                                                <img className='BackImg' src={backImg} alt='img' />
                                                                <img className='CartImgM' src={getProductUrl(cart.product.productImg)} alt='img' />
                                                            </div>
                                                            <div className='CartNameBoxM' onClick={()=>productInfoPage(cart.product)}>
                                                                {cart.product.name}
                                                            </div>
                                                        </div>
                                                        <div className='CartQuantityM'>
                                                            구매 수량 : <input className='CartQuantityInputM' value={getQuantity(cart.seq)} onChange={(e) => quantityChange(e.target.value, cart.seq)} type='text' />개
                                                            <div className='CartModifyBtnBoxM'>
                                                                <div className='CartModifyBtnM' onClick={()=>quantityModify(cart.seq)}>
                                                                    수량 수정
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='CartPriceM'>
                                                            상품 금액 : {addComma(cart.totalPrice)}원
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            ):(
                                <div className='NonCartWrap'>
                                    장바구니에 담긴 상품이 없습니다.
                                </div>
                            )}
                            {width > 900 &&
                                <>
                                    <div className="CartPagiNationWrap">
                                        <div className="PagiNationBox">
                                            <div className="PagiNationArrow" id="PagiNationFirst" onClick={()=>goPre(1)}>
                                                <KeyboardDoubleArrowLeftIcon />
                                            </div>                                
                                            <div className="PagiNationArrow" id="PagiNationPrev" onClick={()=> goPre(0)}>
                                                <KeyboardArrowLeftIcon />
                                            </div>
                                            <div className="PagiNationNumBox">
                                                {cuPageArray.map(
                                                    (pageArray) => (
                                                        <React.Fragment key={pageArray}>
                                                            <div className="PagiNationNum" onClick={()=> goPage(pageArray)}>
                                                                <div className="PagiNationNumArea" id={"PagiNationNum"+pageArray}>
                                                                    {pageArray}
                                                                </div>
                                                            </div>                                    
                                                        </React.Fragment>
                                                    )
                                                )}                         
                                            </div>
                                            <div className="PagiNationArrow" id="PagiNationNext" onClick={()=> goNext(0)}>
                                                <KeyboardArrowRightIcon />
                                            </div>
                                            <div className="PagiNationArrow" id="PagiNationEnd" onClick={()=> goNext(1)}>
                                                <KeyboardDoubleArrowRightIcon />
                                            </div>         
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {cartListCount !== 0 &&
                            <>
                                <div className='CartResultWrap'>
                                    <div className='CartResultBox'>
                                            {width > 900 ? (
                                                <div className='CartResultPrice'>
                                                    선택 상품 금액 : {addComma(selectPrice)}원
                                                </div>
                                            ):(
                                                <div className='CartResultPrice'>
                                                    선택 상품 금액 : {addComma(selectPriceM)}원
                                                </div>
                                            )}
                                        <div className='CartOrderBtn' onClick={()=>orderRequest()}>
                                            주문 요청
                                        </div>
                                    </div>
                                </div>
                                <div className='CartNoticeBox'>
                                    ※ 주문 요청 후 관리자가 재고 확인하여 주문 가능 여부를 알려드립니다.<br/>
                                    <b>마이페이지 → 주문 내역</b> 페이지에서 확인 가능하며,<br/>
                                    주문승인 탭에서 결제가 가능합니다.<br/>
                                    원활한 거래를 위한 과정이오니 양해 부탁드리겠습니다.
                                </div>
                            </>
                        }
                    </>
                ):(
                    <div className='NonCartWrap'>
                        <ClipLoader
                            color="var(--mainColor)"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Cart;