import { useEffect, useState } from 'react';
import './MyInfo.css';
import axios from 'axios';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const MyInfo = () => {

    const [userInfo, setUserInfo] = useState([]);
    const [curPw, setCurPw] = useState('');
    const [newPw, setNewPw] = useState('');
    const [newPwFormCheck, setNewPwFormCheck] = useState(false);
    const [newPwCheck, setNewPwCheck] = useState("");
    const [newPwFormReCheck, setNewPwFormReCheck] = useState(false);
    const [addr, setAddr] = useState([]); 
    const [sEncData, setSEncData] = useState(null);

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    // 회원 정보 불러오기
    const getUsetInfo = async () => {
        const getUsetInfo = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/user/getInfo");
        if(getUsetInfo.data.success){
            setUserInfo(getUsetInfo.data.data);
        }
    }

    // 기본주소 불러오기
    const getDefaultAddr = async () => {
        const getDefaultAddr = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getDefault");
        if(getDefaultAddr.data.success){
            if(getDefaultAddr.data.list.length !== 0){
                setAddr(getDefaultAddr.data.list);
            }
        }
    }

    // 현재 비밀번호 입력
    const curPwChange = (e) => {
        setCurPw(e.target.value);
    }

    // 새 비밀번호 입력
    const pwChange = (e) => {
        setNewPw(e.target.value);
        setNewPwCheck('');
        let thisPassword = e.target.value;
        let spacePasswordCheck = thisPassword.indexOf(" ");
        let totalPasswordLength = thisPassword.length;
        if(thisPassword === null || thisPassword === ''){
            //값이 없을 때 
            setNewPwFormCheck(false);
            return;
        }else if (spacePasswordCheck !== -1 || totalPasswordLength < 8 || totalPasswordLength > 15) {
            //유효성 위반 처리 (공백, 길이 검사)
            setNewPwFormCheck(false);
            return;
        }else{
            setNewPwFormCheck(true);
        }
    }

    // 새 비밀번호 재확인 입력
    const pwCheckChange = (e) => {
        setNewPwCheck(e.target.value);
        if(e.target.value === newPw){
            setNewPwFormReCheck(true);
        }else{
            setNewPwFormReCheck(false);
        }
    }

    // 비밀번호 재설정
    const pwReset = async () => {
        if(curPw === ''){
            alert('현재 비밀번호를 입력해 주세요.');
            return;
        }
        if(!newPwFormCheck){
            alert('비밀번호는 8자 이상, 15자 이하, 공백을 제거해주세요.');
            return;
        }
        if(!newPwFormReCheck){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        var params = new URLSearchParams();
        params.append('password', curPw);
        params.append('newPassword', newPw); 
        const signUp = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/user/modifyPW', params);
        if(signUp.data.success){
            alert('비밀번호가 재설정 되었습니다.');
            setCurPw('');
            setNewPw('');
            setNewPwCheck('');
            setNewPwFormCheck(false);
            setNewPwFormReCheck(false);
        }else{
            alert('현재 비밀번호가 일치하지 않습니다.');
        }
    }

// ================================== 본인인증 관련 ================================== //
    const authPopup = async() => {
        const temp = await axios.get(process.env.REACT_APP_AXIOS_URL+'v1/sign/cert');
        if(temp.data.success){
            setSEncData(temp.data.data);
            fnPopup();       
            window.name ="niceCert";                                
            function fnPopup(){
                window.open(process.env.REACT_APP_AXIOS_URL+'v1/cert/test', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk.target = "popupChk";
                document.form_chk.submit();                 
            } 
        }  
    }

    const getAuth = async() => {
        const temp = await axios.get(process.env.REACT_APP_AXIOS_URL+'v1/sign/cert');
        if(temp.data.success){
            setSEncData(temp.data.data);
        }      
    }

    const getUserInfoFunc = async(e) => {
        if(e.data.message==="OK"){
            var param = new URLSearchParams();
            param.append('HP', e.data.mobileno);
            const checkHP = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/checkHP', param);
            if(checkHP.data.success){
                var params = new URLSearchParams();
                params.append('hp', e.data.mobileno);
                const modifyHP = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/user/modifyHP', params);
                if(modifyHP.data.success){
                    alert('재인증 되었습니다.');
                    getUsetInfo();
                }
            }else{
                alert('이미 가입된 번호입니다.');
            }
        }
    }

    useEffect(() => {
        getUsetInfo();
        getDefaultAddr();
    }, [])

    useEffect(() => {
        getAuth();
        window.addEventListener("message", getUserInfoFunc, false);
        return () => {
            window.removeEventListener("message", getUserInfoFunc, false);
        }
    }, []);

    return(
        <>
            <div className='MyInfoWrap'>
                <div className='MyInfoBox'>
                    <div className='MyInfoSection'>
                        <div className="MyInfoTitle1">
                            아이디
                        </div>
                        <div className="MyInfoContentWrap1">
                            <div className="MyInfoInfoBox">
                                {userInfo.userId}
                            </div>
                        </div>
                    </div>
                    <div className='MyInfoSection'>
                        <div className="MyInfoTitle2">
                            비밀번호<br/>재설정
                        </div>
                        <div className="MyInfoContentWrap2">
                            <div className='MyInfoPwTitle'>
                                현재 비밀번호
                            </div>
                            <div className="MyInfoBox1">
                                <input className="MyInfoInput1" type='password' value={curPw} onChange={curPwChange} placeholder="현재 비밀번호를 입력해 주세요." autoComplete='new-password' />
                            </div>
                            <div className='MyInfoPwTitle'>
                                새 비밀번호
                            </div>
                            <div className="MyInfoBox2">
                                <input className="MyInfoInput2" type='password' value={newPw} onChange={pwChange} placeholder="새 비밀번호를 입력해 주세요." autoComplete='new-password' />
                            </div>
                            {(!newPwFormCheck && newPw !== "") &&
                                <div className='MyInfoNewPwCheckText'>
                                    8자 이상, 15자 이하, 공백을 제거해주세요.
                                </div>
                            }
                            <div className='MyInfoNewPwCheckTitle'> 
                                새 비밀번호 재확인
                            </div>
                            <div className="MyInfoBox3">
                                <input className="MyInfoInput3" type='password' value={newPwCheck} onChange={pwCheckChange} placeholder="새 비밀번호를 재입력해 주세요." autoComplete='new-password' /> 
                            </div>
                            {(newPw !== newPwCheck && newPwCheck !== '') &&
                                <div className='MyInfoNewPwReCheckText'>
                                    비밀번호가 일치하지 않습니다.
                                </div>
                            }
                            <div className='MyInfoPwResetBtn' onClick={()=>pwReset()}>
                                비밀변호 재설정
                            </div>
                        </div>
                    </div>
                    <div className='MyInfoSection'>
                        <div className="MyInfoTitle1">
                            휴대폰 번호
                        </div>
                        <div className="MyInfoContentWrap1">
                            <div className="MyInfoInfoBox">
                                {hyphenPhoneNum(userInfo.hp)}
                            </div>
                            <div className='PhoneNumReAuth' onClick={()=>authPopup()}>
                                재인증
                            </div>
                        </div>
                    </div>
                    <div className='MyInfoDefaultAddrSection'>
                        <div className="MyInfoDefaultAddrTitle">
                            기본 배송지
                        </div>
                        <div className="MyInfoDefaultAddrWrap">
                            <div className='MyInfoDefaultAddrBox'>
                                {addr.length !== 0 ? (
                                    <>
                                        <div className="MyInfoDefaultAddr">
                                            받는 사람 : {addr[0].receiveName}
                                        </div>
                                        <div className="MyInfoDefaultAddr">
                                            휴대폰 번호 : {hyphenPhoneNum(addr[0].tel)}
                                        </div>
                                        <div className="MyInfoDefaultAddr">
                                            주소 : {addr[0].address} {addr[0].addressDetail}
                                        </div>
                                    </>
                                ):(
                                    <div>

                                    </div>
                                )}
                                <div className="MyInfoDefaultAddr">
                                    ※ 배송지 관리에서 등록, 수정, 삭제 하실 수 있습니다. 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='WithdrawBox'>
                        <div className='WithdrawText'>
                            탈퇴를 원하시면 우측 버튼을 눌러주세요.
                        </div>
                        <NavLink to='/withdraw'>
                            <div className='WithdrawBtn'>
                                회원탈퇴
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <form name="form_chk" method="post">
		        <input type="hidden" name="m" value="checkplusService"/>						
		        <input type="hidden" name="EncodeData" value={sEncData===null ? '' : sEncData}/>		
	    		{/* <a href="javascript:fnPopup();"></a> */}
            </form>
        </>
    )
}

export default MyInfo;