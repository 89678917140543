import { useState } from 'react';
import './PwReset.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';

const PwReset = () => {

    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [pwFormCheck, setPwFormCheck] = useState(false);
    const [pwCheck, setPwCheck] = useState("");
    const [pwFormReCheck, setPwFormReCheck] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let hp = location.state.hp;

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    // 비밀번호 입력
    const pwChange = (e) => {
        setPw(e.target.value);
        setPwCheck('');
        let thisPassword = e.target.value;
        let spacePasswordCheck = thisPassword.indexOf(" ");
        let totalPasswordLength = thisPassword.length;
        if(thisPassword === null || thisPassword === ''){
            //값이 없을 때 
            setPwFormCheck(false);
            return;
        }else if (spacePasswordCheck !== -1 || totalPasswordLength < 8 || totalPasswordLength > 15) {
            //유효성 위반 처리 (공백, 길이 검사)
            setPwFormCheck(false);
            return;
        }else{
            setPwFormCheck(true);
        }
    }

    // 비밀번호 재확인 입력
    const pwCheckChange = (e) => {
        setPwCheck(e.target.value);
        if(e.target.value === pw){
            setPwFormReCheck(true);
        }else{
            setPwFormReCheck(false);
        }
    }

    // 비밀번호 재설정
    const pwReset = async () => {
        if(id === ''){
            alert('아이디를 입력해 주세요.');
            return;
        }
        if(!pwFormCheck){
            alert('비밀번호는 8자 이상, 15자 이하, 공백을 제거해주세요.');
            return;
        }
        if(!pwFormReCheck){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        var params = new URLSearchParams();
        params.append('userId', id);
        params.append('newPassword', pw);
        params.append('hp', hp);
        const signUp = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/setPassword', params);
        if(signUp.data.success){
            alert('비밀번호가 재설정 되었습니다.');
            navigate('/login');
        }else{
            alert('회원정보가 일치하지 않습니다.');
        }
    }

    return(
        <div className='PwResetWrap'>
            <div className='PwResetBox'>
                <div className='PwResetTitle'>
                    비밀번호 재설정
                </div>
                <div className='PwResetIdBox'>
                    <div className='PwResetIdTitle'>
                        아이디
                    </div>
                    <div className='PwResetIdInputBox'>
                        <input className='PwResetIdInput' type='text' placeholder='아이디를 입력해 주세요.' value={id} onChange={(e)=>setId(e.target.value)} />
                    </div>
                </div>
                <div className='ResetPwBox'>
                    <div className='ResetPwTitle'>
                        새 비밀번호
                    </div>
                    <div className='ResetPwInputBox'>
                        <input className='ResetPwInput' type='password' value={pw} onChange={pwChange} placeholder='새 비밀번호를 입력해 주세요.' autoComplete='new-password' />
                    </div>
                    {(!pwFormCheck && pw !== "") &&
                        <p className="ResetPwCheckText">8자 이상, 15자 이하, 공백을 제거해주세요.</p>
                    }
                </div>
                <div className='ResetPwBox'>
                    <div className='ResetPwTitle'>
                        새 비밀번호 재확인
                    </div>
                    <div className='ResetPwInputBox'>
                        <input className='ResetPwInput' type='password' value={pwCheck} onChange={pwCheckChange} placeholder='새 비밀번호를 재입력해 주세요.' autoComplete='new-password' />
                    </div>
                    {(pw !== pwCheck && pwCheck !== '') &&
                        <div className='ResetPwReCheckText'>
                            비밀번호가 일치하지 않습니다.
                        </div>
                    }
                </div>
                <div className='ResetPwPhoneNumBox'>
                    <div className='ResetPwPhoneNumTitle'>
                        휴대폰 번호
                    </div>
                    <div className='ResetPwPhoneNumInputBox'>
                        <input className='ResetPwPhoneNumInput' type='text' value={hyphenPhoneNum(hp)} readOnly />
                    </div>
                </div>
                <div className='ResetPw' onClick={()=>pwReset()}>
                    비밀번호 재설정
                </div> 
            </div>
        </div>
    )
}

export default PwReset;