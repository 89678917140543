import { useState } from 'react';
import './SignUp.css';
import axios from 'axios';
import $ from 'jquery';
import Auth from '../js/Auth';
import { useLocation, useNavigate } from 'react-router-dom';

const SignUp = () => {

    const [id, setId] = useState('');
    const [idFormCheck, setIdFormCheck] = useState(false);
    const [message, setMessage] = useState('');
    const [pw, setPw] = useState('');
    const [pwFormCheck, setPwFormCheck] = useState(false);
    const [pwCheck, setPwCheck] = useState("");
    const [pwFormReCheck, setPwFormReCheck] = useState(false);
    const [policyCheck1, setPolicyCheck1] = useState("N");
    const [policyCheck2, setPolicyCheck2] = useState("N");
    const navigate = useNavigate();
    const location = useLocation();
    let hp = location.state.hp;

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    // 아이디 입력
    const idChange = (e) => {
        const limitId = /^[a-zA-Z0-9]*$/; // 영어(대,소문자), 숫자만 입력가능
        if(limitId.test(e.target.value)){
            setId(e.target.value);
        }else{
            alert("영문, 숫자만 입력이 가능합니다.");
        }
        setIdFormCheck(false);
    }

    // 아이디 체크
    const idCheck = async () => {
        $('.IdCheckText').css('display', 'block');
        var params = new URLSearchParams();
        params.append('userId', id);
        const checkId = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/checkId', params);
        if(!checkId.data.success){
            // 중복 아이디일 때
            setIdFormCheck(false);
            setMessage('이미 사용 중인 아이디입니다.');
            $('.IdCheckText').css('color', 'var(--redColor)');
            return;
        }else if(id === null || id === ''){
            // 값이 없을 때 
            setIdFormCheck(false);
            setMessage('아이디를 입력해 주세요.');
            $('.IdCheckText').css('color', 'var(--redColor)');
            return;
        }else if (id.length < 4 || id.length > 10) {
            // 유효성 위반 처리 (길이 검사)
            setIdFormCheck(false);
            setMessage('4자 이상, 10자 이하로 입력해 주세요.');
            $('.IdCheckText').css('color', 'var(--redColor)');
            return;
        }else{
            // 사용할 수 있을 때
            setIdFormCheck(true);
            setMessage('사용할 수 있는 아이디입니다.');
            $('.IdCheckText').css('color', 'var(--blueColor)');
        }
        $('.IdCheckText').css('margin', '5px 0px 0px 5px');
    }

    // 비밀번호 입력
    const pwChange = (e) => {
        setPw(e.target.value);
        setPwCheck('');
        let thisPassword = e.target.value;
        let spacePasswordCheck = thisPassword.indexOf(" ");
        let totalPasswordLength = thisPassword.length;
        if(thisPassword === null || thisPassword === ''){
            //값이 없을 때 
            setPwFormCheck(false);
            return;
        }else if (spacePasswordCheck !== -1 || totalPasswordLength < 8 || totalPasswordLength > 15) {
            //유효성 위반 처리 (공백, 길이 검사)
            setPwFormCheck(false);
            return;
        }else{
            setPwFormCheck(true);
        }
    }

    // 비밀번호 재확인 입력
    const pwCheckChange = (e) => {
        setPwCheck(e.target.value);
        if(e.target.value === pw){
            setPwFormReCheck(true);
        }else{
            setPwFormReCheck(false);
        }
    }

    // 이용약관 체크 (필수)
    const checkPolicy1 = () => {
        if(policyCheck1 === 'Y') {
            $('#PolicyCheck1').prop('checked', false);
            setPolicyCheck1('N');
        }else{
            $('#PolicyCheck1').prop('checked', true);
            setPolicyCheck1('Y');
        }
    }

    // 이용약관 새창 (JSP)
    const viewPolicy1 = async () => {
        window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/policy1', 'popupChk', 'width=600, height=650, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
    }

    // 개인정보 수집 및 이용 체크 (필수)
    const checkPolicy2 = () => {
        if(policyCheck2 === 'Y') {
            $('#PolicyCheck2').prop('checked', false);
            setPolicyCheck2('N');
        }else{
            $('#PolicyCheck2').prop('checked', true);
            setPolicyCheck2('Y');
        }
    }

    // 개인정보 수집 및 이용 새창 (JSP)
    const viewPolicy2 = async () => {
        window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/policy2', 'popupChk', 'width=600, height=650, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
    }

    // 회원가입 후 자동로그인을 위해서
    const autoLogin = () => {
        Auth
        .executeAuth(id, pw)
        .then((response) => {            
            if(response.status===200){              
                Auth.registerSuccessfulLoginForJwt(response.data.user, response.data.token);          
                navigate('/');
                window.location.reload();
            }else{
                alert("error");
            }           
        }).catch(() =>{
            alert("error");
        })                        
    }

    // 회원가입
    const signUp = async () => {
        if(!idFormCheck){
            alert('아이디 중복확인을 해주세요.');
            return;
        }
        if(!pwFormCheck){
            alert('비밀번호는 8자 이상, 15자 이하, 공백을 제거해주세요.');
            return;
        }
        if(!pwFormReCheck){
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }
        if(policyCheck1 === "N"){
            alert('이용약관에 동의하셔야 합니다.');
            return;
        }
        if(policyCheck2 === "N"){
            alert('개인정보 수집 및 이용에 동의하셔야 합니다.');
            return;
        }
        var params = new URLSearchParams();
        params.append('userId', id);
        params.append('password', pw);
        params.append('hp', hp);
        params.append('agree1YN', policyCheck1);
        params.append('agree2YN', policyCheck2);
        const signUp = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/signUp', params);
        if(signUp.data.success){
            alert('회원가입 되었습니다.');
            autoLogin();
        }
    }

    return(
        <div className='SignUpWrap'>
            <div className='SignUpBox'>
                <div className='SignUpTitle'>
                    회원가입
                </div>
                <div className='SignUpIdBox'>
                    <div className='SignUpIdTitle'>
                        아이디
                    </div>
                    <div className='SignUpIdInputBox'>
                        <input className='SignUpIdInput' type='text' placeholder='아이디를 입력해 주세요.' value={id} onChange={idChange} autoComplete='new-password' />
                    </div>
                    <div className='IdCheckBtn' onClick={()=>idCheck()}>
                        중복확인
                    </div>
                    <div className='IdCheckText'>
                        {message}
                    </div>
                </div>
                <div className='SignUpPwBox'>
                    <div className='SignUpPwTitle'>
                        비밀번호
                    </div>
                    <div className='SignUpPwInputBox'>
                        <input className='SignUpPwInput' type='password' placeholder='비밀번호를 입력해 주세요.' value={pw} onChange={pwChange} autoComplete='new-password' />
                    </div>
                    {(!pwFormCheck && pw !== "") &&
                        <p className="PwCheckText">8자 이상, 15자 이하, 공백을 제거해주세요.</p>
                    }
                </div>
                <div className='SignUpPwReCheckBox'>
                    <div className='SignUpPwTitle'>
                        비밀번호 재확인
                    </div>
                    <div className='SignUpPwInputBox'>
                        <input className='SignUpPwInput' type='password' placeholder='비밀번호를 재입력해 주세요.' value={pwCheck} onChange={pwCheckChange} autoComplete='new-password' />
                    </div>
                    {(pw !== pwCheck && pwCheck !== '') &&
                        <div className='PwReCheckText'>
                            비밀번호가 일치하지 않습니다.
                        </div>
                    }
                </div>
                <div className='SignUpPhoneBox'>
                    <div className='SignUpPhoneTitle'>
                        휴대폰 번호
                    </div>
                    <div className='SignUpPhoneInputBox'>
                        <input className='SignUpPhoneInput' type='text' value={hyphenPhoneNum(hp)} readOnly />
                    </div>
                </div>
                <div className='PolicyCheckWrap'>
                    <div className='PolicyCheckBox1'>
                        <div className='CheckBox1' onClick={()=>checkPolicy1()}>
                            <input className='PolicyCheck1' id="PolicyCheck1" type='checkbox' />
                            <div className='PolicyTitle'>
                                (필수) 이용약관
                            </div>
                        </div>
                        <div className='PolicyViewBtn' onClick={()=>viewPolicy1()}>
                            내용보기
                        </div>
                    </div>
                    <div className='PolicyCheckBox2'>
                        <div className='CheckBox2' onClick={()=>checkPolicy2()}>
                            <input className='PolicyCheck2' id="PolicyCheck2" type='checkbox' />
                            <div className='PolicyTitle'>
                                (필수) 개인정보 수집 및 이용
                            </div>
                        </div>
                        <div className='PolicyViewBtn' onClick={()=>viewPolicy2()}>
                            내용보기
                        </div>
                    </div>
                </div>
                <div className='SignUp' onClick={()=>signUp()}>
                    회원가입
                </div>
            </div>
        </div>
    )
}

export default SignUp;