import './PopUpModal.css';

const PopUpModal = props => {
    
    return(
        <div className={ props.open ? 'openModal PopUpModal': 'PopUpModal' }>
            { props.open ? (  
                <section>
                    <main>
                        {props.children}
                    </main>
                </section>
            ):(
                null
            )}
        </div> 
    )
}

export default PopUpModal;