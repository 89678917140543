import axios from 'axios';

class Auth {
    
    // send userId, password to the SERVER
    executeAuth(userId, password) {
        var params = new URLSearchParams();
        params.append('userId', userId);
        params.append('password', password);       
        return axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/login', params);
    }

    registerSuccessfulLoginForJwt(userId, token) {
        localStorage.setItem('authenticatedUser', userId);        
        localStorage.setItem('token', token);
        this.setupAxiosInterceptors();
    }
    
    setupAxiosInterceptors() {
        axios.interceptors.request.use(
            config => {             
                const token = localStorage.getItem('token');                
                if (token) {                      
                    config.headers = {
                        "Accept": "*/*", 
                        "X-AUTH-TOKEN": token
                    };
                }
                return config;
            },
            error => {                
                Promise.reject(error);
            }
        )
    }

    logout() {    
        localStorage.removeItem('authenticatedUser');
        localStorage.removeItem('token');
        window.location.href = "/";
    }

    loggedCheck() {        
        if(localStorage.getItem('token')==="null" || 
        localStorage.getItem('token')===null || localStorage.getItem('token')===undefined ){
            return false;
        }else{
            this.setupAxiosInterceptors();
        }
        return true;
    }
}

export default new Auth();