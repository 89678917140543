import React, { useEffect, useState } from 'react';
import './Search.css';
import axios from 'axios';
import $ from 'jquery';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { Grid } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import backImg from '../assets/images/back_img.jpg';
import ClipLoader from "react-spinners/ClipLoader";

var cuPageArray = [];   // 현재 보여지는 페이지 리스트 담겨 있는 배열
var cuPage = 1;        // 현재 페이지 리스트의 첫 페이지
var cuEndPage = 10;    // 현재 페이지 리스트의 마지막 페이지
var moreNum = 1;       // 무한스크롤을 위한 변수

// 검색결과 리스트 페이지네이션
const setPageList = (totalPage) => {
    $('.PagiNationArrow').css('display', 'flex');
    cuPageArray = [];
    cuEndPage = cuPage + 9;
    if(totalPage<=cuEndPage) {
        cuEndPage = totalPage;
        displayArrow(2);        
    }
    if(cuPage===1){
        displayArrow(1);   
    }
    for(let i=cuPage; i<=cuEndPage; i++){
        cuPageArray[i-1] = i;
    }
}

// 페이지네이션 arrow 예외처리
const displayArrow = (cbc) => {
    switch (cbc) {
        case 1:
            $('#PagiNationFirst').css('display', 'none');
            $('#PagiNationPrev').css('display', 'none');
            break;
        case 2:
            $('#PagiNationEnd').css('display', 'none');
            $('#PagiNationNext').css('display', 'none');
            break;         
        default:
    }
}

const Search = () => {

    const location = useLocation();
    let bigCate = location.state.bigCate;
    let smallCate = location.state.smallCate;
    let keyword = location.state.keyword;
    let orderBy = location.state.orderBy;
    const [search, setSearch] = useState([]);
    const [searchCount, setSearchCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    let searchVal = $('.SearchText').val();
    const [isLoading, setIsLoading] = useState(false); // 검색결과

    // 텍스트 길이 자르기
    const limTxt = (txt) => {
        let text = String(txt);
        if(text.length>=55){
            text = text.substring(0, 55)+ "...";
        }
        return text;
    }

    //천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 대표 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 검색결과
    const searchResult = async (pageNo, size) => {
        const searchResult = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/search",
        {
            params:{
                lCategoryNo: bigCate,
                sCategoryNo: smallCate,
                keyWord: keyword,
                orderBy: orderBy,
                pageNo: pageNo,
                size: size
            }
        })
        if(searchResult.data.success){
            setSearch(searchResult.data.list);
            setIsLoading(true);
            // 새로고침 시 초기화
            if(searchVal === undefined || searchVal === null){
                navigate('/search', {
                    state: {
                        bigCate: 0,
                        smallCate: 0,
                        keyword: '',
                        orderBy: 0
                    }
                });
            }
        }
    }

    // 검색결과 Count
    const searchResultCount = async () => {
        const searchResultCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/searchCount",
        {
            params:{
                lCategoryNo: bigCate,
                sCategoryNo: smallCate,
                keyWord: keyword,
            }
        })
        if(searchResultCount.data.success){
            const total = Math.ceil(searchResultCount.data.data / 16);
            setPageList(total);
            setTotalPage(total);
            setSearchCount(searchResultCount.data.data);
            if(searchResultCount.data.data === 0){
                $('.SearchPagiNationWrap').css('display', 'none');
            }else{
                $('.SearchPagiNationWrap').css('display', 'flex');
            }
        }
    }

    // 페이지 이동 
    const goNext = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');
        var total = totalPage;
        let calcPage = Math.floor(total / 10)  * 10 + 1;
        let checkPage = total - Math.floor(total / 10)  * 10;
        if(edge===1){
            if(checkPage === 0) {
                cuPage = Math.floor(total / 10) * 10 - 9; 
            }else{
                if(calcPage === 1) {
                    cuPage = 1
                }else{
                    cuPage = Math.floor(total / 10) * 10 + 1; 
                }
            }
        }else{
            cuPage = cuPage + 10;
            if(cuPage>total) cuPage = total;   
        }
        setPageList(total);
        if(edge===1) {
            goPage(total);
        }else{
            goPage(cuPage);
        }
    }

    // 페이지 이동 
    const goPre = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');   
        var total = totalPage;     
        if(edge===1){
            cuPage = 1; 
        }else{
            cuPage = cuPage - 10;
            if(cuPage<1) cuPage = 1;
        }  
        setPageList(total);
        goPage(cuPage);
    }

    // 페이지 이동 및 현재 페이지 넘버링 색상 변경
    const goPage = (no) => {
        $('html, body').scrollTop(0);
        if(no===undefined){
            no = currentPage;
        }
        setCurrentPage(no);
        var realNo = no - 1;
        searchResult(realNo, 16);
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+no).css('color', 'white');
        $('#PagiNationNum'+no).css('background-color', 'var(--mainColor)');
    }

    // sort 클릭
    const sort = (num) => {
        navigate('/search', {
            state: {
                bigCate: bigCate,
                smallCate: smallCate,
                keyword: keyword,
                orderBy: num
            }
        });
        let target = "#Sort"+num;
        $('.Sort').css('color', 'var(--baseFontColor)');
        $('.Sort').css('fontWeight', 'normal');
        $(target).css('color', 'var(--mainColor)');
        $(target).css('fontWeight', 'bold');
        window.scrollTo(0, 0);
    }

    // category, keyword, orderby 변경 시 pagination 및 무한스크롤을 위한 변수 초기화
    const pageInit = () => {
        setCurrentPage(1);
        cuPage = 1;
        moreNum = 1;
    }

    // 무한 스크롤
    const moremoreList = () => {
        let scrollT = $(window).scrollTop();
        let documentH = $(document).height();
        let windowH = $(window).height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(16*(moreNum) < searchCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                searchResult(0, 16*(moreNum));
            }
        }
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);     
    }

    // category, keyword, orderby 변경 시 데이터 리렌더링 
    useEffect(() => {
        searchResult(0, 16);
        searchResultCount();
        pageInit();
    }, [bigCate, smallCate, keyword, orderBy]);

    // 페이지네이션 CSS
    useEffect(()=> {
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+currentPage).css('color', 'white');
        $('#PagiNationNum'+currentPage).css('background-color', 'var(--mainColor)');
    });

    // 반응형을 위해 resize 동작
    useEffect(() => {
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    // 모바일용 무한 스크롤 동작
    useEffect(() => {
        if(width <= 900){
            window.addEventListener('scroll', moremoreList);
            return () => {
                window.removeEventListener('scroll', moremoreList);
            };
        }
    });

    return(
        <div className='SearchResultWrap'>
            <div className='SearchResultBox'>
                <div className='SearchTitleBox'>
                    <div className='SearchTitle'>
                        상품 정보
                    </div>
                    {keyword !== '' &&
                        <div className='SearchResultText'>
                            ('{keyword}' 에 대한 검색 결과 입니다.)
                        </div>
                    }
                </div>
                <div className='SortWrap'>
                    <div className='SortBox'>
                        <div className='Sort' id="Sort0" onClick={()=>sort(0)}>
                            최신순
                        </div>
                        <div className='Sort' id="Sort1" onClick={()=>sort(1)}>
                            낮은 가격순
                        </div>
                        <div className='Sort' id="Sort2" onClick={()=>sort(2)}>
                            높은 가격순
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <>
                        <div className='ProductInfoBox'>
                            {searchCount !== 0 ? (
                                <Grid container rowSpacing={{xs:2, md:3}} columnSpacing={2}>
                                    {search.map(
                                        (product) => (
                                            <Grid item xs={6} md={3} key={product.seq}>
                                                <NavLink className="NavLink" to='/productinfo' 
                                                    state={{
                                                        productInfo: product
                                                    }}
                                                >
                                                    <div className='ProductInfo'>
                                                        <div className='ProductImgBox'>
                                                            <img className='BackImg' src={backImg} alt='img' />
                                                            <img className='ProductImg' src={getProductUrl(product.productImg)} alt='img' />
                                                        </div>
                                                        <div className='ProductName'>
                                                            {limTxt(product.name)}
                                                        </div>
                                                        <div className='ProductPrice'>
                                                            {addComma(product.price)}원
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            ):( 
                                <div className='NonSearchWrap'>
                                    등록된 상품이 없습니다.
                                </div>
                            )}
                        </div>
                        {width > 900 &&
                            <>
                                <div className="SearchPagiNationWrap">
                                    <div className="PagiNationBox">
                                        <div className="PagiNationArrow" id="PagiNationFirst" onClick={()=>goPre(1)}>
                                            <KeyboardDoubleArrowLeftIcon />
                                        </div>                                
                                        <div className="PagiNationArrow" id="PagiNationPrev" onClick={()=> goPre(0)}>
                                            <KeyboardArrowLeftIcon />
                                        </div>
                                        <div className="PagiNationNumBox">
                                            {cuPageArray.map(
                                                (pageArray) => (
                                                    <React.Fragment key={pageArray}>
                                                        <div className="PagiNationNum" onClick={()=> goPage(pageArray)}>
                                                            <div className="PagiNationNumArea" id={"PagiNationNum"+pageArray}>
                                                                {pageArray}
                                                            </div>
                                                        </div>                                    
                                                    </React.Fragment>
                                                )
                                            )}                         
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationNext" onClick={()=> goNext(0)}>
                                            <KeyboardArrowRightIcon />
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationEnd" onClick={()=> goNext(1)}>
                                            <KeyboardDoubleArrowRightIcon />
                                        </div>         
                                    </div>
                                </div>
                            </>
                        }
                    </>
                ):(
                    <div className='NonSearchWrap'>
                        <ClipLoader
                            color="var(--mainColor)"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Search; 