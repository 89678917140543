import './Policy3.css';

const Policy3 = () => {

    return(
        <div className='PolicyWrap'>
            <div className='PolicyBox'>
                <div class='LawInfoWrap'>
                    <div class='LawInfoContentWrap'>
                        <div class='LawInfoContentBox'>
                            <div className='LawInfoContent'>
                                1. ㈜공유자재는 통신판매중개업자로서 몰 서비스를 이용하여 체결되는 매매계약의 당사자가 아니며, 구매자 및 판매자 회원이 자율적으로 자재 구매/ 판매 정보를 등록, 활용하여 상대방과의 거래 여부를 결정하는데 도움이 되도록 정보를 중개하는 역할만을 합니다.
                            </div>
                            <div className='LawInfoContent'>
                                2. ㈜공유자재는 몰 서비스를 이용하여 회원 간에 체결되는 매매계약에 대해 아무런 책임이 없을 뿐만 아니라, 회원 간 매매계약 및 거래와 관련하여 어떠한 형태의 묵시적, 명시적 보증도 하지 않습니다.
                            </div>
                            <div className='LawInfoContent'>
                                3. ㈜공유자재가 제공하는 몰 서비스 및 자료 등을 통해 나타나는 모든 정보들을 확인하고 이를 신뢰할지 하는 것과 이를 토대로 어떤 결정을 할지 여부는 어디까지나 구매자 및 판매자 회원 각자의 책임이고, 실제 거래에 이르는 모든 과정 또한 회원이 직접 선택하여 결정해야만 하는 것으로서 이와 관련하여 회사에는 그 어떤 책임도 없습니다.
                            </div>
                            <div className='LawInfoContent'>
                                4. ㈜공유자재는 회사가 제공한 자재 구매/ 판매 정보 외에 매매계약의 이행 과정에서 발생하는 사안에 대하여 일체의 책임이 없습니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Policy3;