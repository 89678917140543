import { useEffect, useState, useCallback } from 'react';
import './Header.css';
import axios from 'axios';
import $ from 'jquery';
import Auth from '../js/Auth';
import { NavLink, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StorefrontIcon from '@mui/icons-material/Storefront';

const Header = () => {
    
    const url = window.location.pathname;   // 소카테고리 노출 예외처리를 위해서 페이지 url 받아옴
    const [login, setLogin] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [menuItem, setMenuItem] = useState(false);
    const [bigCate, setBigCate] = useState([]);
    const [marbleCate, setMarbleCate] = useState([]);
    const [furnitureCate, setFurnitureCate] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [enterKeyword, setEnterKeyword] = useState('');
    const navigate = useNavigate();

    // 대카테고리 불러오기
    const getBigCate = async () => {
        const getBigCate = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getCategoryList",
        {
            params:{
                type: 0
            }
        })
        if(getBigCate.data.success){
            setBigCate(getBigCate.data.list);
        }
    }

    // 대리석 소카테고리 불러오기
    const getMarbleCate = async () => {
        const getMarbleCate = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getCategoryList",
        {
            params:{
                type: 1
            }
        })
        if(getMarbleCate.data.success){
            setMarbleCate(getMarbleCate.data.list);
        }
    }

    // 가구 소카테고리 불러오기
    const getFurnitureCate = async () => {
        const getFurnitureCate = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/main/getCategoryList",
        {
            params:{
                type: 2
            }
        })
        if(getFurnitureCate.data.success){
            setFurnitureCate(getFurnitureCate.data.list);
        }
    }

    // 대카테고리 클릭 PC
    const bigCateClick = (num) => {
        let target = "#BigCate"+num;
        sessionStorage.setItem('cateNum', num);
        $('.BigCate, .SmallCate, .Sort').css('color', 'var(--baseFontColor)');
        $('.BigCate, .SmallCate, .Sort').css('fontWeight', 'normal');
        $(target).css('color', 'var(--mainColor)');
        $(target).css('fontWeight', 'bold');
        $('#Sort0').css('color', 'var(--mainColor)');
        $('#Sort0').css('fontWeight', 'bold');
        if(num === 1){
            $('#MarbleSmall0').css('color', 'var(--mainColor)');
            $('#MarbleSmall0').css('fontWeight', 'bold');
        }else{
            $('#FurnitureSmall0').css('color', 'var(--mainColor)');
            $('#FurnitureSmall0').css('fontWeight', 'bold');
        }
        navigate('/search', {
            state: {
                bigCate: num,
                smallCate: 0,
                keyword: enterKeyword,
                orderBy: 0
            }
        });
        window.scrollTo(0, 0);
    }

    // 대리석 소카테고리 클릭 PC
    const marbleSmallCate = (num) => {
        let target = "#MarbleSmall"+num;
        $('.SmallCate, .Sort').css('color', 'var(--baseFontColor)');
        $('.SmallCate, .Sort').css('fontWeight', 'normal');
        $(target).css('color', 'var(--mainColor)');
        $(target).css('fontWeight', 'bold');
        $('#Sort0').css('color', 'var(--mainColor)');
        $('#Sort0').css('fontWeight', 'bold');
        navigate('/search', {
            state: {
                bigCate: 1,
                smallCate: num,
                keyword: enterKeyword,
                orderBy: 0
            }
        });
        window.scrollTo(0, 0);
    }

    // 가구 소카테고리 클릭 PC
    const FurnitureSmallCate = (num) => {
        let target = "#FurnitureSmall"+num;
        $('.SmallCate, .Sort').css('color', 'var(--baseFontColor)');
        $('.SmallCate, .Sort').css('fontWeight', 'normal');
        $(target).css('color', 'var(--mainColor)');
        $(target).css('fontWeight', 'bold');
        $('#Sort0').css('color', 'var(--mainColor)');
        $('#Sort0').css('fontWeight', 'bold');
        navigate('/search', {
            state: {
                bigCate: 2,
                smallCate: num,
                keyword: enterKeyword,
                orderBy: 0
            }
        });
        window.scrollTo(0, 0);
    }

    // URL에 따른 카테고리 color
    const cateColor = () => {
        // search 페이지가 아니라면 기본색상
        if(url !== "/search"){
            $('.BigCate').css('color', 'var(--baseFontColor)');
            $('.BigCate').css('fontWeight', 'normal');
            setKeyword('');
            setEnterKeyword('');
        }
        // 배송지 등록/수정 페이지에서 color
        if(url === '/addresslist' || url === '/addressedit' || url === '/addressnew'){
            $('#AddressList').css('color', 'var(--mainColor)');
            $('#AddressList').css('fontWeight', 'bold');
        }else{
            $('#AddressList').css('color', 'var(--baseColor)');
            $('#AddressList').css('fontWeight', 'normal');
        }
        // 주문내역 관련 페이지에서 color
        if(url === '/orderlist' || url === '/pay' || url === '/paysuccess' || url === '/payinfo'){
            $('#OrderList').css('color', 'var(--mainColor)');
            $('#OrderList').css('fontWeight', 'bold');
        }else{
            $('#OrderList').css('color', 'var(--baseColor)');
            $('#OrderList').css('fontWeight', 'normal');
        }
        // 1:1문의 관련 페이지에서 color
        if(url === '/asklist' || url === '/askview' || url === '/askwrite'){
            $('#asklist').css('color', 'var(--mainColor)');
            $('#asklist').css('fontWeight', 'bold');
        }else{
            $('#asklist').css('color', 'var(--baseColor)');
            $('#asklist').css('fontWeight', 'normal');
        }
    }

    // 검색어 입력
    const searchOnchange = (e) => {
        setKeyword(e.target.value);
    }

    // 검색어 삭제
    const keywordDel = () => {
        setKeyword('');
    }

    // Enter key 검색
    const formSubmit = (e) => {
        if(e.key==="Enter"){
            if(keyword.replace(/(^\s*)|(\s*$)/gi, "").length !== 0){
                navigate('/search', {
                    state: {
                        bigCate: 0,
                        smallCate: 0,
                        keyword: keyword,
                        orderBy: 0
                    }
                });
                setEnterKeyword(keyword);
                sessionStorage.setItem('cateNum', 0);
                $('.BigCate, .Sort').css('color', 'var(--baseFontColor)');
                $('.BigCate, .Sort').css('fontWeight', 'normal');
                $('#BigCate0, #Sort0').css('color', 'var(--mainColor)');
                $('#BigCate0, #Sort0').css('fontWeight', 'bold');
                setMenuItem(false);
                $('.MenuWrapM').css('display', 'none');
            }else{
                alert('검색어를 입력해주세요.');
            }
        }
    }  

    // 반응형 menu list On/Off
    const menu = () => {
        if(menuItem){
            setMenuItem(false);
            $('.MenuWrapM').css('display', 'none');
        }else{
            setMenuItem(true);
            $('.MenuWrapM').fadeIn(200);
        }
    }

    // 브라우저 resize 시 반응형을 위한 함수
    // resize시 menuItem의 실시간 상태를 가져오기위해 useCallback 사용 (컴포넌트가 다시 렌더링되더라도 함수의 참조값을 동일하게 유지시킬 수 있다.)
    const resize = useCallback(() => {
        setWidth(window.innerWidth);     
        if(width > 900){
            $('.HeaderContentsBox').css('display', 'block');
            $('.MenuContentsWrapM, .MenuWrapM').css('display', 'none');
            setMenuItem(false);
        }else if(width <= 900){
            $('.HeaderContentsBox').css('display', 'none');
            $('.MenuContentsWrapM').css('display', 'flex');
        }
    }, [width])

    // 외부영역 클릭 시 사이드바 display: none
    const outClickOff = () => {
        $('.App').click(function(e) {
            if(!$('.MenuWrapM').has(e.target).length){
                $('.MenuWrapM').css('display', 'none');
                setMenuItem(false);
            }
        });
    };

    // 클릭 시 사이드바 display: none
    const clickOff = (url) => {
        $('.MenuWrapM').css('display', 'none');
        setMenuItem(false);
        if(url === "/orderlist"){
            orderListPath();
        }else{
            navigate(url);
        }
    };

    // 로그아웃
    const logout = () => {
        Auth.logout();
        setLogin(false);         
        $('.MenuWrapM').css('display', 'none');
        setMenuItem(false);
    }

    // 주문내역으로 이동
    const orderListPath = () => {
        navigate('/orderlist', {
            state: {
                status: "submit"
            }
        });
    }

    useEffect(() => {
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, [resize]);

    useEffect(() => {
        getBigCate();
        getMarbleCate();
        getFurnitureCate();
        outClickOff();
        sessionStorage.setItem('cateNum', 0);
    }, []);

    // 모바일용 장바구니 화면에서만 floating 버튼 위치를 변경
    const floatingPosition = () => {
        if(url !== '/cart' && width <= 900){
            $('.FloatingWrap').css('bottom', '20px');
        }else if(url !== '/cart' && width > 900){
            $('.FloatingWrap').css('bottom', '20px');
        }else if(url === '/cart' && width <= 900){
            $('.FloatingWrap').css('bottom', '100px');
        }else if(url === '/cart' && width > 900){
            $('.FloatingWrap').css('bottom', '20px');
        }
    }

    // 구매자 페이지를 통한 판매자 페이지 진입 시 판단여부
    const sellerPageIn = () => {
        localStorage.setItem('sellerPageIn', 'Y');
    }

    useEffect(() => {
        cateColor();
    }, [url]);
    
    useEffect(() => {
        floatingPosition();
    }, [url, width]);

    useEffect(() => {
        if(Auth.loggedCheck()) {
            setLogin(true);
        }else{
            setLogin(false);
        }
    }, [login])

    return(
        <>
            <div className='HeaderWrap'>
                <div className='HeaderBox'>
                    <div className='HeaderTop'>
                        <NavLink className="NavLink" to="/">
                            <div className='LogoBox'>
                                <img className='Logo' alt='Logo' />
                            </div>
                        </NavLink>
                        <div className='HeaderContentsBox'>
                            <div className='HeaderMenuBox'>
                                {login &&
                                    <>
                                        <div className='HeaderMenu' onClick={()=>sellerPageIn()}>
                                            <NavLink className="NavLink" to="https://gongyoomaterial.com/seller" target='_blank'>
                                                판매자 페이지
                                            </NavLink>
                                        </div>
                                        <div className='HeaderMenu'>
                                            <NavLink className="NavLink" to="/cart">
                                                장바구니
                                            </NavLink>
                                        </div>
                                        <div className='HeaderMenu' onClick={()=>orderListPath()}>
                                            마이페이지
                                        </div>
                                        <div className='HeaderMenu'>
                                            <NavLink className="NavLink" to="/asklist">
                                                고객센터
                                            </NavLink>
                                        </div>
                                    </>
                                }
                                {login ? (
                                    <div className='HeaderMenu' onClick={()=>logout()}>
                                        로그아웃
                                    </div>
                                ):(
                                    <div className='HeaderMenu'>
                                        <NavLink className="NavLink" to='/login'>
                                            로그인/회원가입
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                            <div className="SearchWrap" onKeyPress={formSubmit}>
                                <div className="SearchBtn">
                                    <SearchIcon/>
                                </div>
                                <input className="SearchText" type="text" placeholder="검색어를 입력해주세요." value={keyword} onChange={searchOnchange} autoComplete='new-password' />
                                {keyword !== '' &&
                                    <div className="SearchDel" onClick={()=>keywordDel()}>
                                        <CloseIcon/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='MenuContentsWrapM'>
                            <div className='MenuIconBoxM' onClick={()=>menu()}>
                                <SearchIcon/>
                            </div>
                            <div className='MenuIconBoxM' onClick={()=>menu()}>
                                <MenuIcon/>
                            </div>
                        </div>
                    </div>
                    <div className='CateWrap'>
                        {(url !== "/policy1" && url !== "/policy2" && url !== "/policy3" &&
                        url !== "/myinfo" && url !== "/addresslist" && url !== "/addressedit" && url !== "/addressnew" && url !== "/orderlist"
                        && url !== "/pay" && url !== "/paysuccess" && url !== "/payinfo" && url !== "/cart" && url !== "/withdraw" && url !== "/asklist"
                        && url !== "/askview" && url !== "/askwrite") &&
                            <div className='BigCateBox'>
                                <div className='BigCate' id='BigCate0' onClick={()=>bigCateClick(0)}>
                                    전체
                                </div>
                                {bigCate.map(
                                    (cate) => (
                                        <div className='BigCate' id={"BigCate"+cate.seq} onClick={()=>bigCateClick(cate.seq)} key={cate.seq}>
                                            {cate.name}
                                        </div>
                                    )
                                )}
                            </div>
                        }
                        {url === "/search" && 
                            <>
                                {(+sessionStorage.getItem('cateNum') === 1 || +sessionStorage.getItem('cateNum') === 2) &&
                                    <div className='BorderLine'></div>
                                }
                                <div className='SmallCateBox'>
                                    {+sessionStorage.getItem('cateNum') === 1 &&
                                        <>
                                            <div className='SmallCate' id='MarbleSmall0' onClick={()=>marbleSmallCate(0)}>
                                                제조사 전체
                                            </div>
                                            {marbleCate.map(
                                                (cate) => (
                                                    <div className='SmallCate' id={'MarbleSmall'+cate.seq} onClick={()=>marbleSmallCate(cate.seq)} key={cate.seq}>
                                                        {cate.name}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    }
                                    {+sessionStorage.getItem('cateNum') === 2 &&
                                        <>
                                            <div className='SmallCate' id="FurnitureSmall0" onClick={()=>FurnitureSmallCate(0)}>
                                                제조사 전체
                                            </div>
                                            {furnitureCate.map(
                                                (cate) => (
                                                    <div className='SmallCate' id={'FurnitureSmall'+cate.seq} onClick={()=>FurnitureSmallCate(cate.seq)} key={cate.seq}>
                                                        {cate.name}
                                                    </div>
                                                )
                                            )}
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {(url === "/policy1" || url === "/policy2" || url === "/policy3") &&
                        <div className='PolicyMenuWrap'>
                            <div className='PageTitle'>
                                정책안내
                            </div>
                            <div className='BorderLine'></div>
                            <div className='SmallCateBox'>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/policy1" 
                                        style={({ isActive }) => ({ 
                                            color: isActive ? 'var(--mainColor)' : 'var(--baseFontColor)', 
                                            fontWeight: isActive ? 'bold' : 'normal' 
                                        })}
                                    >
                                        이용약관
                                    </NavLink>
                                </div>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/policy2" 
                                        style={({ isActive }) => ({ 
                                            color: isActive ? 'var(--mainColor)' : 'var(--baseFontColor)', 
                                            fontWeight: isActive ? 'bold' : 'normal' 
                                        })}
                                    >
                                        개인정보처리방침
                                    </NavLink>
                                </div>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/policy3" 
                                        style={({ isActive }) => ({ 
                                            color: isActive ? 'var(--mainColor)' : 'var(--baseFontColor)', 
                                            fontWeight: isActive ? 'bold' : 'normal' 
                                        })}
                                    >
                                        법적고지
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    }
                    {(url === "/myinfo" || url === "/addresslist" || url === "/addressedit" || url === "/addressnew" || url === "/orderlist" ||
                    url === "/pay" || url === "/paysuccess" || url === "/payinfo" || url === "/cart") &&
                        <div className='MyPageMenuWrap'>
                            <div className='PageTitle'>
                                마이페이지
                            </div>
                            <div className='BorderLine'></div>
                            <div className='SmallCateBox'>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/myinfo" 
                                        style={({ isActive }) => ({ 
                                            color: isActive ? 'var(--mainColor)' : 'var(--baseFontColor)', 
                                            fontWeight: isActive ? 'bold' : 'normal' 
                                        })}
                                    >
                                        내 정보관리
                                    </NavLink>
                                </div>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" id="AddressList" to="/addresslist" >
                                        배송지 관리
                                    </NavLink>
                                </div>
                                <div className='SmallCate' id="OrderList" onClick={()=>orderListPath()}>
                                    주문내역
                                </div>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/cart" 
                                        style={({ isActive }) => ({ 
                                            color: isActive ? 'var(--mainColor)' : 'var(--baseFontColor)', 
                                            fontWeight: isActive ? 'bold' : 'normal' 
                                        })}
                                    >
                                        장바구니
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    }
                    {(url === "/asklist" || url === "/askview" || url === "/askwrite") &&
                        <div className='MyPageMenuWrap'>
                            <div className='PageTitle'>
                                고객센터
                            </div>
                            <div className='BorderLine'></div>
                            <div className='SmallCateBox'>
                                <div className='SmallCate'>
                                    <NavLink className="NavLink" to="/asklist" id="asklist">
                                        1:1 문의
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    }
                    {url === "/withdraw" &&
                        <div className='WithdrawMenuWrap'>
                            <div className='PageTitle'>
                                회원탈퇴
                            </div>
                            <div className='WithdrawBorderLine'></div>
                        </div>
                    }
                </div>
            </div>
            <div className='MenuWrapM'>
                <div className='MenuBoxM'>
                    <div className='SideMenuBoxM'>
                        <div className="SearchWrapM" onKeyPress={formSubmit}>
                            <div className="SearchBtnM">
                                <SearchIcon/>
                            </div>
                            <input className="SearchTextM" type="text" placeholder="검색어를 입력해주세요." value={keyword} onChange={searchOnchange} autoComplete='new-password' />
                            {keyword !== '' &&
                                <div className="SearchDelM" onClick={()=>keywordDel()}>
                                    <CloseIcon/>
                                </div>
                            }
                        </div>
                        {login &&
                            <>
                                <div className='SideMenuM' onClick={()=>sellerPageIn()}>
                                    <NavLink className="NavLink" to="https://gongyoomaterial.com/seller" target='_blank'>
                                        <div className='SideMenuTextM' onClick={()=>clickOff()}>
                                            <StorefrontIcon/>
                                            판매자 페이지
                                        </div>
                                    </NavLink>
                                </div>
                                <div className='SideMenuM'>
                                    <div className='SideMenuTextM' onClick={()=>clickOff("/cart")}>
                                        <AddShoppingCartIcon/>
                                        장바구니
                                    </div>
                                </div>
                                <div className='SideMenuM'>
                                    <div className='SideMenuTextM' onClick={()=>clickOff("/orderlist")}>
                                        <PersonOutlineIcon/>
                                        마이페이지
                                    </div>
                                </div>
                                <div className='SideMenuM'>
                                    <div className='SideMenuTextM' onClick={()=>clickOff("/asklist")}>
                                        <HelpOutlineIcon/>
                                        고객센터
                                    </div>
                                </div>
                            </>
                        }
                        {login ? (
                            <div className='SideMenuM'>
                                <div className='SideMenuTextM' onClick={()=>logout()}>
                                    <LogoutIcon/>
                                    로그아웃
                                </div>
                            </div>
                        ):(
                            <div className='SideMenuM'>
                                <div className='SideMenuTextM' onClick={()=>clickOff("/login")}>
                                    <LoginIcon/>
                                    로그인/회원가입
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;