import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {

    return(
        <div className='FooterWrap'>
            <div className='FooterBox'>
                <div className='FooterMenuWrap'>
                    <div className='FooterMenuBox'>
                        <div className='FooterMenu'>
                            <NavLink className="NavLink" to='/policy1'>
                                이용약관
                            </NavLink>
                        </div>
                        <div className='FooterMenu'>
                            <NavLink className="NavLink" to='/policy2'>
                                개인정보처리방침
                            </NavLink>
                        </div>
                        <div className='FooterMenu'>
                            <NavLink className="NavLink" to='/policy3'>
                                법적고지
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='FooterContentsWrap'>
                    <div className='FooterContentsBox'>
                        사업자명 : (주) 공유자재<br/>
                        사업자 등록번호 : 561-88-03121<br/>
                        주소 : 인천광역시 동구 방축로83번길 23, 31동 316호<br/>
                        (송림동, 인천산업용품 유통단지)<br/>
                        전화 : 032-217-1990 / 팩스 : 032-218-1990<br/>
                        e-mail : gongyoo2023@naver.com
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;