import React, { useEffect, useState } from 'react';
import './AskWrite.css';
import axios from 'axios';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';

const AskWrite = () => {

    const location = useLocation();
    const askSeq = location.state.askSeq;
    const askCate = location.state.askCate;
    const companyUserId = location.state.companyUserId;
    const productName = location.state.productName;
    const productColor = location.state.productColor;
    const productPrice = location.state.productPrice;
    const [title, setTitle] = useState('');
    const [contents, setContents] = useState('');    
    const navigate = useNavigate();

    // 파일 업로드 관련
    const [imgFiles, setImgFiles] = useState([]); // 이미지 객체를 구성한다.
    const [imgFile, setImgFile] = useState([]);	// 파일을 배열로 담아 둔다.
    
    // 파일 업로드 onChange
    const handleChangeFile = (event) => {    
        // 예외 처리 위해 한번 필터링을 전부 한 후 다시 업로드 한다.
        for(var i=0;i<event.target.files.length;i++){
            // 동일 파일명 예외처리
            let tempFileName = event.target.files[i].name;
            if(imgFiles.filter((item) => item.fileName === tempFileName).length !==0) {
                alert('파일명이 같은 파일이 이미 첨부되었습니다.');
                return false;
            }
            // 파일 용량 예외처리
            let tempFileSize = event.target.files[i].size;
            if(tempFileSize > 10*1024*1024){
                alert("첨부할 수 없는 용량의 파일이 포함되어 있습니다.");
                return false;
            }
            // 확장자 예외처리
            let fileExtensions = tempFileName.substring(tempFileName.indexOf('.')+1);        //확장자를 찾음
            fileExtensions = fileExtensions.toLowerCase();                                   //소문자 변환
            if(fileExtensions !== 'gif' && fileExtensions !== 'jpg' && fileExtensions !== 'jpeg' && fileExtensions !== 'png'){
                alert("첨부할 수 없는 형식의 파일이 포함되어 있습니다.");                
                return false;
            }
        }
        // 파일 첨부만 담당
        for(var i=0;i<event.target.files.length;i++){
            let tempFileName = event.target.files[i].name;
            // 파일 첨부
            if(event.target.files[i]) {                
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[i]); //파일을 읽어 버퍼에 저장
                reader.onloadend = () => {                                  
                    const imgData = reader.result;
                    if (imgData) {
                        setImgFiles(imgFiles => [...imgFiles, {fileName:tempFileName, imgData}])  
                    }
                }
            }
        }       
        const tempList = Object.values(event.target.files);
        setImgFile(imgFile.concat(tempList));   
    }  

    // 천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 제목 입력 (숫자,영문,한글, () . - , 가능)
    const titleOnChange = (e) => {
        const titleChange = e.target.value.replace(/[^0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힝|(|)|.|/\s|,|-]/g, ''); // 숫자,영문,한글, () . - , 가능
        setTitle(titleChange);
    }

    // 문의내용 입력
    const contentsOnChange = (e) => {
        setContents(e.target.value);
    }

    // 일반문의, 재고문의, 불편접수 예외처리
    const cateChange = () => {
        if(askCate === "normal"){
            setTitle('');
            setContents('');
        }else if(askCate === "dispute"){
            setTitle('불편접수');
            setContents('판매자명 : '+companyUserId+'\n\n상품명 : '+productName+'\n\n색상 : '+productColor+'\n\n가격 : '+addComma(productPrice)+'원 (개당)');
        }else if(askCate === "soldout"){
            setTitle('재고문의');
            setContents('판매자명 : '+companyUserId+'\n\n상품명 : '+productName+'\n\n색상 : '+productColor+'\n\n가격 : '+addComma(productPrice)+'원 (개당)');
            $('.AskWriteInfoInput, .AskWriteInfoTextArea').attr('readonly', true);
        }
    }

    // 문의하기
    const askReg = async () => {
        if(title.replace(/(^\s*)|(\s*$)/gi, "").length === 0){
            alert('제목을 입력해 주세요.');
            return;
        }
        if(title.length > 30){
            alert('제목은 최대 30자까지 입력할 수 있습니다.');
            return;
        }
        if(contents.replace(/(^\s*)|(\s*$)/gi, "").length === 0){
            alert('문의내용을 입력해 주세요.');
            return;
        }
        if(contents.length > 500){
            alert('문의내용은 최대 500자까지 입력할 수 있습니다.');
            return;
        }

        const fd = new FormData();
        imgFile.forEach((file) => fd.append("files", file));    
        fd.append('title', title);
        fd.append('contents', contents);
        fd.append('seq', askSeq);
        fd.append('category', askCate);    
        const askReg = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/customer/reg', fd);
        if(askReg.data.success){
            alert('문의되었습니다.');
            navigate("/asklist");
        }
    }   

    // 이미지 삭제 (미리보기&&파일)
    const imgDel = (fileName) => {
        const imgList = imgFile.filter((item) => item.name !== fileName);
        const previewDel = imgFiles.filter((item) => item.fileName !== fileName);
        setImgFile([...imgList]);
        setImgFiles([...previewDel]);
        document.getElementById("imgUpload").value=null;  // 동일 파일 삭제후 업로드시. 이벤트가 저장되어 있으므로 파일 변화가 없어 작동 안하는 문제 해결
    }

    useEffect(() => {
        cateChange();
    }, [])

    return(
        <div className="AskWriteWrap">
            <div className='AskWriteBox'>
                <div className='AskWriteSection'>
                    <div className="AskWriteTitle">
                        제목
                    </div>
                    <div className="AskWriteContentWrap">
                        <div className='AskWriteInfoBox'>
                            <input className='AskWriteInfoInput' value={title} onChange={titleOnChange} placeholder='제목을 입력해 주세요. (최대 30자 입력가능)' />
                        </div>
                    </div>
                </div>
                <div className='AskWriteSection'>
                    <div className="AskWriteTitle">
                        문의내용
                    </div>
                    <div className="AskWriteContentWrap">
                        <div className='AskWriteInfoTextAreaBox'>
                            <textarea className='AskWriteInfoTextArea' value={contents} onChange={contentsOnChange} placeholder='문의내용을 입력해 주세요. (최대 500자 입력가능)'>

                            </textarea>
                        </div>
                    </div>
                </div>
                <div className='AskWriteSection'>
                    <div className="AskWriteTitle">
                        첨부파일
                    </div>
                    <div className="AskWriteFileContentWrap">
                        <div className='AskWriteFileWrap'>
                            <div className='AskWriteFileViewWrap'>
                                <div className='AskWriteFileViewBox'>
                                    <div className='AskWriteFileView'>
                                        {imgFiles.map((item, index) => {
                                            return(   
                                                <div className='imgUploadBox' key={index}>                         
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.imgData}
                                                        alt="First slide"
                                                    />
                                                    <div className='imgDelBtnBox'>
                                                        <div className='imgDelBtn' onClick={()=>imgDel(item.fileName)}>
                                                            삭제
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <label className='AskWriteFileUpload' htmlFor="imgUpload">
                                첨부 
                                <input id="imgUpload" type="file" multiple="multiple" onChange={handleChangeFile} accept="image/jpeg, image/jpg, image/png, image/gif"/>
                            </label>
                        </div>
                        <div className='AskWriteFileNoticeBox'>
                            <div className='AskWriteFileNotice'>
                                - 용량 : 10MB 이하
                            </div>
                            <div className='AskWriteFileNotice'>
                                - 형식 : JPG, PNG, GIF
                            </div>
                            <div className='AskWriteFileNotice'>
                                - 첨부 개수 : 제한 없음
                            </div>
                        </div>
                    </div>
                </div>
                <div className='AskWriteBtnBox'>
                    <div className='AskWriteBtn' onClick={()=>askReg()}>
                        문의하기
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskWrite;