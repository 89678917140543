import React, { useEffect, useState } from 'react';
import './OrderList.css';
import axios from 'axios';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import backImg from '../assets/images/back_img.jpg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ClipLoader from "react-spinners/ClipLoader";

var cuPageArray = [];   // 현재 보여지는 페이지 리스트 담겨 있는 배열
var cuPage = 1;        // 현재 페이지 리스트의 첫 페이지
var cuEndPage = 10;    // 현재 페이지 리스트의 마지막 페이지
var moreNum = 1;       // 무한스크롤을 위한 변수

// 주문 내역 리스트 페이지네이션
const setPageList = (totalPage) => {
    $('.PagiNationArrow').css('display', 'flex');
    cuPageArray = [];
    cuEndPage = cuPage + 9;
    if(totalPage<=cuEndPage) {
        cuEndPage = totalPage;
        displayArrow(2);        
    }
    if(cuPage===1){
        displayArrow(1);   
    }
    for(let i=cuPage; i<=cuEndPage; i++){
        cuPageArray[i-1] = i;
    }
}

// 페이지네이션 arrow 예외처리
const displayArrow = (cbc) => {
    switch (cbc) {
        case 1:
            $('#PagiNationFirst').css('display', 'none');
            $('#PagiNationPrev').css('display', 'none');
            break;
        case 2:
            $('#PagiNationEnd').css('display', 'none');
            $('#PagiNationNext').css('display', 'none');
            break;         
        default:
    }
}

const OrderList = () => {

    const [orderList, setOrderList] = useState([]);
    const [orderListCount, setOrderListCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [DBpage, setDBpage] = useState(0);
    const location = useLocation();
    const status = location.state.status;
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false); // 주문내역 리스트

    // 천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 상태 표시
    const statusText = (status) => {
        switch(status){
            case "request":
                return "요청중";
            case "submit":
                return "결제하기";
            case "paymentEnd":
                return "결제내역";
            case "paymentIng":
                return "입금대기";
            case "soldOut":
                return "재고없음";
            case "paymentCancel":
                return "결제취소";
            case "dispute":
                return "불편접수중";
            case "orderEnd":
                return "구매완료";
            default:
        }
    }

    // 리스트가 없을 시 문구 예외처리
    const noListText = (status) => {
        switch(status){
            case "all":
                return "주문내역이 없습니다.";
            case "request":
                return "주문요청 내역이 없습니다.";
            case "submit":
                return "주문승인 내역이 없습니다.";
            case "paymentEnd":
                return "결제완료 내역이 없습니다.";
            case "soldOut":
                return "재고없음 내역이 없습니다.";
            default:
        }
    }

    // 주문 내역 리스트 불러오기
    const getOrderList = async (pageNo, size) => {
        if(pageNo===undefined){
            pageNo = DBpage;
        }else{
            setDBpage(pageNo);
        }
        const getOrderList = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getOrderList",
        {
            params:{
                status: status,
                pageNo: pageNo,
                size: size
            }
        })
        if(getOrderList.data.success){
            setOrderList(getOrderList.data.list);
            setIsLoading(true);
        }
    }

    // 주문 내역 리스트 Count
    const getOrderListCount = async () => {
        const getOrderListCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getOrderListCount",
        {
            params:{
                status: status
            }
        })
        if(getOrderListCount.data.success){
            const total = Math.ceil(getOrderListCount.data.data / 5);
            setPageList(total);
            setTotalPage(total);
            setOrderListCount(getOrderListCount.data.data);
            if(getOrderListCount.data.data === 0){
                $('.OrderListPagiNationWrap').css('display', 'none');
            }else{
                $('.OrderListPagiNationWrap').css('display', 'flex');
            }
        }
    }

    // 페이지 이동 
    const goNext = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');
        var total = totalPage;
        let calcPage = Math.floor(total / 10)  * 10 + 1;
        let checkPage = total - Math.floor(total / 10)  * 10;
        if(edge===1){
            if(checkPage === 0) {
                cuPage = Math.floor(total / 10) * 10 - 9; 
            }else{
                if(calcPage === 1) {
                    cuPage = 1
                }else{
                    cuPage = Math.floor(total / 10) * 10 + 1; 
                }
            }
        }else{
            cuPage = cuPage + 10;
            if(cuPage>total) cuPage = total;   
        }
        setPageList(total);
        if(edge===1) {
            goPage(total);
        }else{
            goPage(cuPage);
        }
    }

    // 페이지 이동 
    const goPre = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');   
        var total = totalPage;     
        if(edge===1){
            cuPage = 1; 
        }else{
            cuPage = cuPage - 10;
            if(cuPage<1) cuPage = 1;
        }  
        setPageList(total);
        goPage(cuPage);
    }

    // 페이지 이동 및 현재 페이지 넘버링 색상 변경
    const goPage = (no) => {
        $('html, body').scrollTop(0);
        if(no===undefined){
            no = currentPage;
        }
        setCurrentPage(no);
        var realNo = no - 1;
        getOrderList(realNo, 5);
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+no).css('color', 'white');
        $('#PagiNationNum'+no).css('background-color', 'var(--mainColor)');
    }

    // 페이지네이션 초기화
    const pageInit = () => {
        setCurrentPage(1);
        cuPage = 1;
        moreNum = 1;
    }

    // tab 메뉴 클릭
    const tabClick = (num, status) => {
        $('html, body').scrollTop(0);
        pageInit();
        navigate('/orderlist', {
            state: {
                status: status
            }
        });
        let target = "#OrderListTab"+num;
        $('.OrderListTab').css('backgroundColor','var(--whiteColor)');
        $('.OrderListTab').css('color','var(--baseFontColor)');
        $(target).css('backgroundColor','var(--mainColor)');
        $(target).css('color','var(--whiteColor)');
    }

    // select 메뉴
    const selectOnchange = (e) => {
        $('html, body').scrollTop(0);
        pageInit();
        navigate('/orderlist', {
            state: {
                status: e.target.value
            }
        });
    }

    // Mobile의 메뉴 변경에 따른 PC tab 색상 변화를 위해 (창크기 900 이상일때만 동작)
    const menuColor = () => {
        $('.OrderListTab').css('backgroundColor','var(--whiteColor)');
        $('.OrderListTab').css('color','var(--baseFontColor)');
        if(status === "all"){
            $('#OrderListTab0').css('backgroundColor','var(--mainColor)');
            $('#OrderListTab0').css('color','var(--whiteColor)');
        }else if(status === "request"){
            $('#OrderListTab1').css('backgroundColor','var(--mainColor)');
            $('#OrderListTab1').css('color','var(--whiteColor)');
        }else if(status === "submit"){
            $('#OrderListTab2').css('backgroundColor','var(--mainColor)');
            $('#OrderListTab2').css('color','var(--whiteColor)');
        }else if(status === "paymentEnd"){
            $('#OrderListTab3').css('backgroundColor','var(--mainColor)');
            $('#OrderListTab3').css('color','var(--whiteColor)');
        }else if(status === "soldOut"){
            $('#OrderListTab4').css('backgroundColor','var(--mainColor)');
            $('#OrderListTab4').css('color','var(--whiteColor)');
        }
    }

    // 구매확정
    const payEnd = async (paymentSeq,orderSeq) => {
        // noti가 들어오기전 구매확정 처리 막기위해 추가
        const getPayInfo = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getPayment",
        {
            params:{
                paymentSeq: paymentSeq
            }
        })
        if(getPayInfo.data.success){
            if(getPayInfo.data.data.resNoti === null){
                alert("잠시 후 다시 시도해주세요.");
                return;
            }
        }
        if(window.confirm('구매확정 처리를 하시겠습니까?\n\n※ 결제일 기준 7일 후에는 자동으로 구매확정이 됩니다.\n※ 구매확정 후에는 환불이 불가합니다.')){
            const payEnd = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/setOrderEnd",
            {
                params:{
                    orderSeq: orderSeq
                }
            })
            if(payEnd.data.success){
                alert('구매확정 처리되었습니다.');
                getOrderList(0, 5);
                getOrderListCount();
                $('html, body').scrollTop(0);
                pageInit();
            }
        }
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
    }

    // 무한 스크롤
    const moremoreList = () => {
        let scrollT = $(window).scrollTop();
        let documentH = $(document).height();
        let windowH = $(window).height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(5*(moreNum) < orderListCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                getOrderList(0, 5*(moreNum));
            }
        }
    }

    // 상품 상세페이지 이동
    const productInfoPage = (product) => {
        navigate('/productinfo', {
            state: {
                productInfo: product
            }
        });
    }

    // 결제 페이지 이동
    const payPage = (order) => {
        navigate('/pay', {
            state: {
                orderInfo: order
            }
        });
    }

    // 결제 상세페이지 이동
    const payInfoPage = (paymentSeq) => {
        navigate('/payinfo', {
            state: {
                paymentSeq: paymentSeq
            }
        });
    }

    // 불편접수 클릭 후 1:1문의 작성 페이지 이동
    const disputeAskWritePage = async (paymentSeq, seq, cate , companyUserId, productName, productColor, productPrice) => {
        // noti가 들어오기전 구매확정 처리 막기위해 추가
        const getPayInfo = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/my/getPayment",
        {
            params:{
                paymentSeq: paymentSeq
            }
        })
        if(getPayInfo.data.success){
            if(getPayInfo.data.data.resNoti === null){
                alert("잠시 후 다시 시도해주세요.");
                return;
            }
        }
        if(window.confirm('불편접수 처리를 하시겠습니까?\n\n※ 불편접수 후에는 자동으로 구매확정이 되지 않습니다.\n※ 불편사항 해결 후 구매확정 부탁드리겠습니다.')){
            navigate('/askwrite', {
                state: {
                    askSeq: seq,
                    askCate: cate,
                    companyUserId: companyUserId,
                    productName: productName,
                    productColor: productColor,
                    productPrice: productPrice
                }
            });
        }
    }

    // 재고문의 클릭 후 1:1문의 작성 페이지 이동
    const soldOutAskWritePage = async (seq, cate, companyUserId, productName, productColor, productPrice) => {
        navigate('/askwrite', {
            state: {
                askSeq: seq,
                askCate: cate,
                companyUserId: companyUserId,
                productName: productName,
                productColor: productColor,
                productPrice: productPrice
            }
        });
    }

    useEffect(() => {
        getOrderList(0, 5);
        getOrderListCount();
    }, [status])

    useEffect(() => {
        if(window.innerWidth > 900){
            menuColor();
        }
    })

    // 페이지네이션 CSS
    useEffect(()=> {
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+currentPage).css('color', 'white');
        $('#PagiNationNum'+currentPage).css('background-color', 'var(--mainColor)');
    });

    useEffect(() => {
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    // 모바일용 무한 스크롤 동작
    useEffect(() => {
        if(width <= 900){
            window.addEventListener('scroll', moremoreList);
            return () => {
                window.removeEventListener('scroll', moremoreList);
            };
        }
    });

    return(
        <div className='OrderListWrap'>
            <div className='OrderListBox'>
                <div className='OrderListTabWrap'>
                    {width > 900 ? (
                        <div className='OrderListTabBox'>
                            <div className='OrderListTab' id="OrderListTab0" onClick={()=>tabClick(0, "all")}>
                                전체
                            </div>
                            <div className='OrderListTab' id="OrderListTab1" onClick={()=>tabClick(1, "request")}>
                                주문요청
                            </div>
                            <div className='OrderListTab' id="OrderListTab2" onClick={()=>tabClick(2, "submit")}>
                                주문승인
                            </div>
                            <div className='OrderListTab' id="OrderListTab3" onClick={()=>tabClick(3, "paymentEnd")}>
                                결제완료
                            </div>
                            <div className='OrderListTab' id="OrderListTab4" onClick={()=>tabClick(4, "soldOut")}>
                                재고없음
                            </div>
                        </div>
                    ):(
                        <div className='OrderListSelectBox'>
                            <FormControl sx={{ minWidth: 100 }} size="small">
                                <InputLabel id="demo-select-small-label">상태</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={status}
                                    label="상태"
                                    onChange={selectOnchange}
                                >
                                    <MenuItem className='MenuItem' value="all">전체</MenuItem>
                                    <MenuItem className='MenuItem' value="request">주문 요청</MenuItem>
                                    <MenuItem className='MenuItem' value="submit">주문 승인</MenuItem>
                                    <MenuItem className='MenuItem' value="paymentEnd">결제 완료</MenuItem>
                                    <MenuItem className='MenuItem' value="soldOut">재고 없음</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div className='OrderListNoticeBox'>
                        {width > 900 ? (
                            <div className='OrderListNotice'>
                                ※ 주문 승인 상태일 때 결제가 가능합니다.
                            </div>
                        ):(
                            <div className='OrderListNoticeM'>
                                ※ 주문 승인 상태일 때<br/>결제가 가능합니다.
                            </div>  
                        )}
                    </div>
                </div>
                {width > 900 &&
                    <div className='OrderListHeaderWrap'>
                        <div className='OrderListHeaderBox'>
                            <Grid container>
                                <Grid item xs={6} md={6}>
                                    <div className='OrderListHeaderInfo'>
                                        상품정보
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='OrderListHeaderQuantity'>
                                        구매 수량
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='OrderListHeaderPrice'>
                                        상품 금액 
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div className='OrderListHeaderStatus'>
                                        상태
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                }
                {isLoading ? (
                    <>
                        <div className='OrderListContentWrap'>
                            {orderListCount !== 0 ? (
                                <>
                                    {width > 900 ? (
                                        <>
                                            {orderList.map(
                                                (order) => (
                                                    <div className='OrderListContentBox' key={order.seq}>
                                                        <Grid container>
                                                            <Grid item xs={6} md={6}>
                                                                <div className='OrderListInfo'>
                                                                    <div className='OrderListImgBox' onClick={()=>productInfoPage(order.product)}>
                                                                        <img className='BackImg' src={backImg} alt='img' />
                                                                        <img className='OrderListImg' src={getProductUrl(order.product.productImg)} alt='img' />
                                                                    </div>
                                                                    <div className='OrderListNameBox' onClick={()=>productInfoPage(order.product)}>
                                                                        {order.productName}
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='OrderListQuantity'>
                                                                    {addComma(order.quantity)}개
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='OrderListPrice'>
                                                                    {addComma(order.totalPrice)}원
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} md={2}>
                                                                <div className='OrderListStatus'>
                                                                    {order.status === "submit" ? (
                                                                        <div className='OrderListStatusBtn1' onClick={()=>payPage(order)}>
                                                                            {statusText(order.status)}
                                                                        </div>
                                                                    ):(
                                                                        <div className='OrderListStatusBtnBox'>
                                                                            {order.status === "paymentEnd" ? (
                                                                                <>
                                                                                    <div className='OrderListStatusBtn3' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                        {statusText(order.status)}
                                                                                    </div>
                                                                                    <div className='OrderListStatusBtn1' onClick={()=>payEnd(order.paymentSeq,order.seq)}>
                                                                                        구매확정
                                                                                    </div>
                                                                                    <div className='OrderListStatusBtn4' onClick={()=>disputeAskWritePage(order.paymentSeq, order.seq, "dispute", order.product.company.userId, order.product.name, order.product.color, order.product.price)}>
                                                                                        불편접수
                                                                                    </div>
                                                                                </>
                                                                            ):(
                                                                                <>
                                                                                    <div className='OrderListStatusBtn2'>
                                                                                        {statusText(order.status)}
                                                                                    </div>
                                                                                    {order.status === "orderEnd" &&
                                                                                        <>
                                                                                            <div className='OrderListStatusBtn4' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                                구매내역
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {order.status === "paymentIng" &&
                                                                                        <>
                                                                                            <div className='OrderListStatusBtn4' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                                계좌확인
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {order.status === "soldOut" &&
                                                                                        <>
                                                                                            <div className='OrderListStatusBtn4' onClick={()=>soldOutAskWritePage(order.productSeq, "soldout", order.product.company.userId, order.product.name, order.product.color, order.product.price)}>
                                                                                                재고문의
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {order.status === "dispute" &&
                                                                                        <>
                                                                                            <div className='OrderListStatusBtn4' onClick={()=>payEnd(order.paymentSeq,order.seq)}>
                                                                                                구매확정
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    ):(
                                        <>
                                            {orderList.map(
                                                (order) => (
                                                    <div className='OrderListContentBoxM' key={order.seq}>
                                                        <div className='OrderListInfoM'>
                                                            <div className='OrderListImgBoxM' onClick={()=>productInfoPage(order.product)}>
                                                                <img className='BackImg' src={backImg} alt='img' />
                                                                <img className='OrderListImgM' src={getProductUrl(order.product.productImg)} alt='img' />
                                                            </div>
                                                            <div className='OrderListNameBoxM' onClick={()=>productInfoPage(order.product)}>
                                                                {order.productName}
                                                            </div>
                                                        </div>
                                                        <div className='OrderListQuantityM'>
                                                            구매 수량 : {addComma(order.quantity)}개
                                                        </div>
                                                        <div className='OrderListPriceM'>
                                                            상품 금액 : {addComma(order.totalPrice)}원
                                                        </div>
                                                        <div className='OrderListStatusM'>
                                                            {order.status === "submit" ? (
                                                                <div className='OrderListStatusBtn1M' onClick={()=>payPage(order)}>
                                                                    {statusText(order.status)}
                                                                </div>
                                                            ):(
                                                                <div className='OrderListStatusBtnBoxM'>
                                                                    {order.status === "paymentEnd" ? (
                                                                        <>
                                                                            <div className='OrderListStatusBtn3M' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                {statusText(order.status)}
                                                                            </div>
                                                                            <div className='OrderListStatusBtn1M' onClick={()=>payEnd(order.paymentSeq,order.seq)}>
                                                                                구매확정
                                                                            </div>
                                                                            <div className='OrderListStatusBtn4M' onClick={()=>disputeAskWritePage(order.paymentSeq, order.paymentSeq, order.seq, "dispute", order.product.company.userId, order.product.name, order.product.color, order.product.price)}>
                                                                                불편접수
                                                                            </div>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <div className='OrderListStatusBtn2M'>
                                                                                {statusText(order.status)}
                                                                            </div>
                                                                            {order.status === "orderEnd" &&
                                                                                <>
                                                                                    <div className='OrderListStatusBtn4M' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                        구매내역
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {order.status === "paymentIng" &&
                                                                                <>
                                                                                    <div className='OrderListStatusBtn4M' onClick={()=>payInfoPage(order.paymentSeq)}>
                                                                                        계좌확인
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {order.status === "soldOut" &&
                                                                                <>
                                                                                    <div className='OrderListStatusBtn4M' onClick={()=>soldOutAskWritePage(order.productSeq, "soldout", order.product.company.userId, order.product.name, order.product.color, order.product.price)}>
                                                                                        재고문의
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {order.status === "dispute" &&
                                                                                <>
                                                                                    <div className='OrderListStatusBtn4M' onClick={()=>payEnd(order.paymentSeq,order.seq)}>
                                                                                        구매확정
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </>
                                    )}
                                </>
                            ):(
                                <div className='NonOrderListWrap'>
                                    {noListText(status)}
                                </div>
                            )}
                            {width > 900 &&
                                <>
                                    <div className="OrderListPagiNationWrap">
                                        <div className="PagiNationBox">
                                            <div className="PagiNationArrow" id="PagiNationFirst" onClick={()=>goPre(1)}>
                                                <KeyboardDoubleArrowLeftIcon />
                                            </div>                                
                                            <div className="PagiNationArrow" id="PagiNationPrev" onClick={()=> goPre(0)}>
                                                <KeyboardArrowLeftIcon />
                                            </div>
                                            <div className="PagiNationNumBox">
                                                {cuPageArray.map(
                                                    (pageArray) => (
                                                        <React.Fragment key={pageArray}>
                                                            <div className="PagiNationNum" onClick={()=> goPage(pageArray)}>
                                                                <div className="PagiNationNumArea" id={"PagiNationNum"+pageArray}>
                                                                    {pageArray}
                                                                </div>
                                                            </div>                                    
                                                        </React.Fragment>
                                                    )
                                                )}                         
                                            </div>
                                            <div className="PagiNationArrow" id="PagiNationNext" onClick={()=> goNext(0)}>
                                                <KeyboardArrowRightIcon />
                                            </div>
                                            <div className="PagiNationArrow" id="PagiNationEnd" onClick={()=> goNext(1)}>
                                                <KeyboardDoubleArrowRightIcon />
                                            </div>         
                                        </div>
                                    </div>
                                </>
                            }
                        </div>                    
                    </>
                ):(
                    <div className='NonOrderListWrap'>
                        <ClipLoader
                            color="var(--mainColor)"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderList;