import React, { useEffect, useState } from 'react';
import './Login.css';
import axios from 'axios';
import Auth from '../js/Auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [sEncData, setSEncData] = useState(null);
    const navigate = useNavigate();

    // 로그인
    const loginClicked = () => {
        Auth
        .executeAuth(id, pw)
        .then((response) => {
            if(response.data.code===0){
                Auth.registerSuccessfulLoginForJwt(id, response.data.data);
                navigate('/');                
                window.location.reload();
            }else if(response.data.code === -1000){
                alert("등록되지 않은 아이디입니다.");
            }else if(response.data.code === -1001){
                alert("비밀번호를 잘못 입력하셨습니다.");
            }else if(response.data.code === -1006){
                alert('탈퇴하거나 이용 정지 중인 회원입니다.');
            }
        }).catch(() =>{
            alert("error");
        })                        
    }

    // Enter key 로그인
    const formSubmit = (e) => {
        if(e.key==="Enter"){
            loginClicked();
        }
    }  

// ================================== 본인인증 관련 ================================== //
    const authPopup = async(page) => {
        sessionStorage.setItem('page', page);
        const temp = await axios.get(process.env.REACT_APP_AXIOS_URL+'v1/sign/cert');
        if(temp.data.success){
            setSEncData(temp.data.data);
            fnPopup();       
            window.name ="niceCert";                                
            function fnPopup(){
                window.open(process.env.REACT_APP_AXIOS_URL+'v1/cert/test', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk.target = "popupChk";
                document.form_chk.submit();                 
            } 
        }  
    }

    const getAuth = async() => {
        const temp = await axios.get(process.env.REACT_APP_AXIOS_URL+'v1/sign/cert');
        if(temp.data.success){
            setSEncData(temp.data.data);
        }      
    }

    const getUserInfoFunc = async(e) => {
        if(e.data.message==="OK"){
            if(sessionStorage.getItem('page') === "signUp"){
                // 회원가입 페이지
                let formData = new FormData();
                formData.append('HP', e.data.mobileno);
                const temp = await axios.post(process.env.REACT_APP_AXIOS_URL+"v1/sign/checkHP", formData);
                if(temp.data.success){
                    navigate('/signup', {
                        state: {
                            hp: e.data.mobileno
                        }
                    })   
                }else{
                    alert('이미 가입된 번호입니다.');
                }
            }else if(sessionStorage.getItem('page') === "idSearch"){
                // 아이디 찾기 페이지
                let formData = new FormData();
                formData.append('hp', e.data.mobileno);
                const getId = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/getId', formData);
                if(getId.data.success){
                    navigate('/idsearch', {
                        state: {
                            searchId: getId.data.data.userId
                        }
                    })
                }else{
                    alert('일치하는 정보가 없습니다.');
                }
            }else if(sessionStorage.getItem('page') === "pwReset"){
                // 비밀번호 재설정 페이지
                let formData = new FormData();
                formData.append('hp', e.data.mobileno);
                const getId = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/sign/getId', formData);
                if(getId.data.success){
                    navigate('/pwreset', {
                        state: {
                            hp: e.data.mobileno
                        }
                    })
                }else{
                    alert('일치하는 정보가 없습니다.');
                }
            }       
            sessionStorage.removeItem('page');
        }
    }


    useEffect(() => {
        getAuth();
        window.addEventListener("message", getUserInfoFunc, false);
        return () => {
            window.removeEventListener("message", getUserInfoFunc, false);
        }
    }, []);

    return(
        <>
            <div className='LoginWrap' onKeyPress={formSubmit}>
                <div className='LoginBox'>
                    <div className='LoginTitle'>
                        로그인
                    </div>
                    <div className='LoginIdBox'>
                        <div className='LoginIdTitle'>
                            아이디
                        </div>
                        <div className='LoginIdInputBox'>
                            <input className='LoginIdInput' type='text' placeholder='아이디를 입력해 주세요.' value={id} onChange={(e)=>setId(e.target.value)} />
                        </div>
                    </div>
                    <div className='LoginPwBox'>
                        <div className='LoginPwTitle'>
                            비밀번호
                        </div>
                        <div className='LoginPwInputBox'>
                            <input className='LoginPwInput' type='password' placeholder='비밀번호를 입력해 주세요.' value={pw} onChange={(e)=>setPw(e.target.value)} />
                        </div>
                    </div>
                    <div className='LoginMenuBox'>
                        <div className='LoginMenuLeft'>
                            <div className='IdSearchBtn' onClick={()=>authPopup('idSearch')}>
                                아이디 찾기
                            </div>
                            <div className='Slash'>
                                /
                            </div>
                            <div className='PwEditBtn' onClick={()=>authPopup('pwReset')}>
                                비밀번호 재설정
                            </div>
                        </div>
                        <div className='LoginMenuRight'>
                            <div className='SignUpBtn' onClick={()=>authPopup('signUp')}>
                                회원가입
                            </div>
                        </div>
                    </div>
                    <div className='LoginBtn' onClick={()=>loginClicked()}>
                        로그인
                    </div>
                </div>
            </div>
            <form name="form_chk" method="post">
		        <input type="hidden" name="m" value="checkplusService"/>						
		        <input type="hidden" name="EncodeData" value={sEncData===null ? '' : sEncData}/>		
	    		{/* <a href="javascript:fnPopup();"></a> */}
            </form>
        </>
    )
}

export default Login;