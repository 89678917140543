import './Floating.css';
import $ from 'jquery';
import { Fab, Tooltip } from "@mui/material";
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

const Floating = () => {

    // 스크롤 최상단으로 이동 (for all browsers)
    const scrollTop = () => {
        $('html, body').animate({ scrollTop: 0 })
    }

    return(
        <div className="FloatingWrap">
            <Tooltip placement="left" title="맨 위로" arrow>
                <div className="FloatingBox">
                    <Fab color="primary" aria-label="edit" onClick={()=>scrollTop()}>
                        <UpIcon color="action"/>
                    </Fab>
                </div>
            </Tooltip>
        </div>
    );
}

export default Floating;