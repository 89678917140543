import './Modal.css';

const Modal = props => {
    
    return(
        <div className={ props.open ? 'openModal Modal': 'Modal' }>
            { props.open ? (  
                <section>
                    <main>
                        {props.children}
                    </main>
                </section>
            ):(
                null
            )}
        </div> 
    )
}

export default Modal;