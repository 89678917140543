import React, { useEffect, useState } from 'react';
import './AddressList.css';
import axios from 'axios';
import $ from 'jquery';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NavLink } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

var cuPageArray = [];   // 현재 보여지는 페이지 리스트 담겨 있는 배열
var cuPage = 1;        // 현재 페이지 리스트의 첫 페이지
var cuEndPage = 10;    // 현재 페이지 리스트의 마지막 페이지
var moreNum = 1;       // 무한스크롤을 위한 변수

// 배송지 리스트 페이지네이션
const setPageList = (totalPage) => {
    $('.PagiNationArrow').css('display', 'flex');
    cuPageArray = [];
    cuEndPage = cuPage + 9;
    if(totalPage<=cuEndPage) {
        cuEndPage = totalPage;
        displayArrow(2);        
    }
    if(cuPage===1){
        displayArrow(1);   
    }
    for(let i=cuPage; i<=cuEndPage; i++){
        cuPageArray[i-1] = i;
    }
}

// 페이지네이션 arrow 예외처리
const displayArrow = (cbc) => {
    switch (cbc) {
        case 1:
            $('#PagiNationFirst').css('display', 'none');
            $('#PagiNationPrev').css('display', 'none');
            break;
        case 2:
            $('#PagiNationEnd').css('display', 'none');
            $('#PagiNationNext').css('display', 'none');
            break;         
        default:
    }
}

const AddressList = () => {

    const [addressList, setAddressList] = useState([]);
    const [addressListCount, setAddressListCount] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [DBpage, setDBpage] = useState(0);
    const [width, setWidth] = useState(window.innerWidth);
    const [isLoading, setIsLoading] = useState(false); // 배송지 리스트

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    // 배송지 리스트 불러오기
    const getAddressList = async (pageNo, size) => {
        if(pageNo===undefined){
            pageNo = DBpage;
        }else{
            setDBpage(pageNo);
        }
        const getAddressList = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getList",
        {
            params:{
                pageNo: pageNo,
                size: size
            }
        })
        if(getAddressList.data.success){
            setAddressList(getAddressList.data.list);
            setIsLoading(true);
        }
    }

    // 배송지 리스트 Count
    const getAddressListCount = async () => {
        const getAddressListCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getListCount")
        if(getAddressListCount.data.success){
            const total = Math.ceil(getAddressListCount.data.data / 5);
            setPageList(total);
            setTotalPage(total);
            setAddressListCount(getAddressListCount.data.data);
            if(getAddressListCount.data.data === 0){
                $('.AddressPagiNationWrap').css('display', 'none');
            }else{
                $('.AddressPagiNationWrap').css('display', 'flex');
            }
        }
    }

    // 페이지 초기화 (배송지 삭제 후 호출)
    const pageInit = () => {
        getAddressList(0, 5);
        getAddressListCount();
        setCurrentPage(1);
        cuPage = 1;
        moreNum = 1;
    }

    // 배송지 삭제
    const addressDel = async (seq) => {
        var params = new URLSearchParams();
        params.append('seq', seq);
        if(window.confirm("삭제하시겠습니까?")){
            const addressDel = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/address/del', params);
            if(addressDel.data.success){
                alert('배송지가 삭제되었습니다.');
                pageInit();
                $('html, body').scrollTop(0);
            }else{
                alert('error');
            }
        }
    }

    // 페이지 이동 
    const goNext = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');
        var total = totalPage;
        let calcPage = Math.floor(total / 10)  * 10 + 1;
        let checkPage = total - Math.floor(total / 10)  * 10;
        if(edge===1){
            if(checkPage === 0) {
                cuPage = Math.floor(total / 10) * 10 - 9; 
            }else{
                if(calcPage === 1) {
                    cuPage = 1
                }else{
                    cuPage = Math.floor(total / 10) * 10 + 1; 
                }
            }
        }else{
            cuPage = cuPage + 10;
            if(cuPage>total) cuPage = total;   
        }
        setPageList(total);
        if(edge===1) {
            goPage(total);
        }else{
            goPage(cuPage);
        }
    }

    // 페이지 이동 
    const goPre = (edge) => {
        $('html, body').scrollTop(0);
        $('.PagiNationArrow').css('display', 'flex');   
        var total = totalPage;     
        if(edge===1){
            cuPage = 1; 
        }else{
            cuPage = cuPage - 10;
            if(cuPage<1) cuPage = 1;
        }  
        setPageList(total);
        goPage(cuPage);
    }

    // 페이지 이동 및 현재 페이지 넘버링 색상 변경
    const goPage = (no) => {
        $('html, body').scrollTop(0);
        if(no===undefined){
            no = currentPage;
        }
        setCurrentPage(no);
        var realNo = no - 1;
        getAddressList(realNo, 5);
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+no).css('color', 'white');
        $('#PagiNationNum'+no).css('background-color', 'var(--mainColor)');
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);     
    }

    // 무한 스크롤
    const moremoreList = () => {
        let scrollT = $(window).scrollTop();
        let documentH = $(document).height();
        let windowH = $(window).height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(5*(moreNum) < addressListCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                getAddressList(0, 5*(moreNum));
            }
        }
    }

    useEffect(() => {
        getAddressList(0, 5);
        getAddressListCount();
    }, [])

    // 페이지네이션 CSS
    useEffect(()=> {
        $('.PagiNationNumArea').css('color', 'var(--baseFontColor)');
        $('.PagiNationNumArea').css('background-color', 'var(--whiteColor)');
        $('#PagiNationNum'+currentPage).css('color', 'white');
        $('#PagiNationNum'+currentPage).css('background-color', 'var(--mainColor)');
    });

    // 반응형을 위해 resize 동작
    useEffect(() => {
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    // 모바일용 무한 스크롤 동작
    useEffect(() => {
        if(width <= 900){
            window.addEventListener('scroll', moremoreList);
            return () => {
                window.removeEventListener('scroll', moremoreList);
            };
        }
    });

    return(
        <div className='AddressListWrap'>
            <div className='AddressListBox'>
                <div className='AddressAddBox'>
                    <NavLink to='/addressnew'>
                        <div className='AddressAddBtn'>
                            배송지 추가
                        </div>
                    </NavLink>
                </div>
                {isLoading ? (
                    <>
                        {addressListCount !== 0 ? (
                            <>
                                {addressList.map(
                                    (address) => (
                                        <div className='AddressBox' key={address.seq}>
                                            <div className='AddressInfoBox'>
                                                {address.defaultYN === "Y" &&
                                                    <div className='DefaultAddress'>
                                                        기본 배송지
                                                    </div>
                                                }
                                                <div className='AddressInfo'>
                                                    받는 사람 : {address.receiveName}
                                                </div>
                                                <div className='AddressInfo'>
                                                    휴대폰 번호 : {hyphenPhoneNum(address.tel)}
                                                </div>
                                                <div className='AddressInfo'>
                                                    주소 : ({address.postNo}) {address.address} {address.addressDetail}
                                                </div>
                                            </div>
                                            <div className='AddressBtnBox'>
                                                <NavLink to='/addressedit' 
                                                    state={{
                                                        address: address
                                                    }}
                                                >
                                                    <div className='AddressModifyBtn'>
                                                        수정
                                                    </div>
                                                </NavLink>
                                                <div className='AddressDelBtn' onClick={()=>addressDel(address.seq)}>
                                                    삭제
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </>
                        ):(
                            <div className='NonAddressWrap'>
                                등록된 배송지가 없습니다.
                            </div>
                        )}
                        {width > 900 &&
                            <>
                                <div className="AddressPagiNationWrap">
                                    <div className="PagiNationBox">
                                        <div className="PagiNationArrow" id="PagiNationFirst" onClick={()=>goPre(1)}>
                                            <KeyboardDoubleArrowLeftIcon />
                                        </div>                                
                                        <div className="PagiNationArrow" id="PagiNationPrev" onClick={()=> goPre(0)}>
                                            <KeyboardArrowLeftIcon />
                                        </div>
                                        <div className="PagiNationNumBox">
                                            {cuPageArray.map(
                                                (pageArray) => (
                                                    <React.Fragment key={pageArray}>
                                                        <div className="PagiNationNum" onClick={()=> goPage(pageArray)}>
                                                            <div className="PagiNationNumArea" id={"PagiNationNum"+pageArray}>
                                                                {pageArray}
                                                            </div>
                                                        </div>                                    
                                                    </React.Fragment>
                                                )
                                            )}                         
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationNext" onClick={()=> goNext(0)}>
                                            <KeyboardArrowRightIcon />
                                        </div>
                                        <div className="PagiNationArrow" id="PagiNationEnd" onClick={()=> goNext(1)}>
                                            <KeyboardDoubleArrowRightIcon />
                                        </div>         
                                    </div>
                                </div>
                            </>
                        }
                    </>
                ):(
                    <div className='NonAddressWrap'>
                        <ClipLoader
                            color="var(--mainColor)"
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AddressList;