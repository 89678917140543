import React, { useEffect, useState } from "react";
import "./PayInfo.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import backImg from "../assets/images/back_img.jpg";

const PayInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentSeq = location.state.paymentSeq;
  const [payInfo, setPayInfo] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  // 결제수단 표시
  const payMethodText = (method) => {
    switch (method) {
      case "CARD":
        return "신용카드";
      case "VBANK":
        return "가상계좌";
      default:
    }
  };

  // 전화번호 하이픈 자동 입력
  const hyphenPhoneNum = (value) => {
    if (!value) {
      return "";
    }

    value = value.replace(/[^0-9]/g, "");

    let result = [];
    let restNumber = "";

    // 지역번호와 나머지 번호로 나누기
    if (value.startsWith("02")) {
      // 서울 02 지역번호
      result.push(value.substr(0, 2));
      restNumber = value.substring(2);
    } else if (value.startsWith("1")) {
      // 지역 번호가 없는 경우
      // 1xxx-yyyy
      restNumber = value;
    } else {
      // 나머지 3자리 지역번호
      // 0xx-yyyy-zzzz
      result.push(value.substr(0, 3));
      restNumber = value.substring(3);
    }

    if (restNumber.length === 7) {
      // 7자리만 남았을 때는 xxx-yyyy
      result.push(restNumber.substring(0, 3));
      result.push(restNumber.substring(3));
    } else {
      result.push(restNumber.substring(0, 4));
      result.push(restNumber.substring(4));
    }

    return result.filter((val) => val).join("-");
  };

  // 천단위 콤마
  const addComma = (value) => {
    var tempStr = String(value);
    return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 상품 이미지 URL
  const getProductUrl = (fileName) => {
    let productImg = process.env.REACT_APP_AXIOS_URL + process.env.REACT_APP_IMAGE_PRODUCT;
    if (
      fileName === null ||
      fileName === "null" ||
      fileName === undefined ||
      fileName === "undefined" ||
      fileName === ""
    ) {
    } else {
      productImg = productImg + fileName;
    }

    return productImg;
  };

  // 상품 상세페이지 이동
  const productInfoPage = (product) => {
    navigate("/productinfo", {
      state: {
        productInfo: product,
      },
    });
  };

  // 결제 내역 상세
  const getPayInfo = async () => {
    const getPayInfo = await axios.get(process.env.REACT_APP_AXIOS_URL + "v1/my/getPayment", {
      params: {
        paymentSeq: paymentSeq,
      },
    });
    if (getPayInfo.data.success) {
      setPayInfo(getPayInfo.data.data);
      if (getPayInfo.data.data.product !== null) {
        setProductInfo(getPayInfo.data.data.product);
      }
    }
  };

  // 뒤로가기
  const backPage = () => {
    if (payInfo.payDate === null || payInfo.payDate === undefined) {
      navigate("/orderlist", {
        state: {
          status: "submit",
        },
      });
    } else {
      navigate("/orderlist", {
        state: {
          status: "paymentEnd",
        },
      });
    }
  };

  // 브라우저 사이즈 가져오기
  const resize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    getPayInfo();
  }, []);

  useEffect(() => {
    resize(); // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
    window.addEventListener("resize", resize); // 브라우저 resize 시 작동
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div className="PayDetailInfoWrap">
      <div className="PayDetailInfoBox">
        <div className="PayDetailInfoProductWrap">
          <div className="PayDetailInfoInfoTitle">구매 상품 정보</div>
          <div className="PayDetailInfoContentWrap">
            {width > 900 ? (
              <>
                <div className="PayDetailInfoHeaderWrap">
                  <div className="PayDetailInfoHeaderBox">
                    <Grid container>
                      <Grid item xs={6} md={6}>
                        <div className="PayDetailInfoHeaderInfo">상품정보</div>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <div className="PayDetailInfoHeaderQuantity">구매 수량</div>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <div className="PayDetailInfoHeaderPrice">상품 금액</div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="PayDetailInfoContentBox">
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <div className="PayDetailInfoInfo">
                        <div
                          className="PayDetailInfoImgBox"
                          onClick={() => productInfoPage(productInfo)}
                        >
                          <img className="BackImg" src={backImg} alt="img" />
                          <img
                            className="PayDetailInfoImg"
                            src={getProductUrl(productInfo.productImg)}
                            alt="img"
                          />
                        </div>
                        <div
                          className="PayDetailInfoNameBox"
                          onClick={() => productInfoPage(productInfo)}
                        >
                          {payInfo.productName}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <div className="PayDetailInfoQuantity">{addComma(payInfo.quantity)}개</div>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <div className="PayDetailInfoPrice">{addComma(payInfo.totalPrice)}원</div>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <div className="PayDetailInfoContentBoxM">
                <div className="PayDetailInfoInfoM">
                  <div
                    className="PayDetailInfoImgBoxM"
                    onClick={() => productInfoPage(productInfo)}
                  >
                    <img className="BackImg" src={backImg} alt="img" />
                    <img
                      className="PayDetailInfoImgM"
                      src={getProductUrl(productInfo.productImg)}
                      alt="img"
                    />
                  </div>
                  <div
                    className="PayDetailInfoNameBoxM"
                    onClick={() => productInfoPage(productInfo)}
                  >
                    {payInfo.productName}
                  </div>
                </div>
                <div className="PayDetailInfoQuantityM">
                  구매 수량 : {addComma(payInfo.quantity)}개
                </div>
                <div className="PayDetailInfoPriceM">
                  상품 금액 : {addComma(payInfo.totalPrice)}원
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="PayDetailInfoAddrWrap">
          <div className="PayDetailInfoTitle">배송지 정보</div>
          <div className="PayDetailInfoAddrBox">
            <div className="PayDetailInfoInfoWrap">
              <div className="PayDetailInfoInfoBox">
                <div className="PayDetailInfoInfo">받는 사람 : {payInfo.name}</div>
                <div className="PayDetailInfoInfo">휴대폰 번호 : {hyphenPhoneNum(payInfo.tel)}</div>
                <div className="PayDetailInfoInfo">
                  주소 : ({payInfo.postNo}) {payInfo.address} {payInfo.addressDetail}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="PayDetailInfoPayInfoWrap">
          <div className="PayDetailInfoTitle">결제 정보</div>
          <div className="PayDetailInfoPayInfoBox">
            <div className="PayDetailInfoInfoWrap">
              <div className="PayDetailInfoInfoBox">
                <div className="PayDetailInfoInfo">
                  결제수단 : {payMethodText(payInfo.payMethod)}
                </div>
                {payInfo.payDate === null || payInfo.payDate === undefined ? (
                  <div className="PayDetailInfoInfo">결제일 : 입금대기</div>
                ) : (
                  <div className="PayDetailInfoInfo">결제일 : {payInfo.payDate}</div>
                )}
                <div className="PayDetailInfoInfo">결제금액 : {addComma(payInfo.totalPrice)}원</div>
                {payInfo.payMethod === "VBANK" && (
                  <>
                    <div className="PayDetailInfoInfo">예금주 : (주)공유자재</div>
                    <div className="PayDetailInfoInfo">입금자명 : {payInfo.vname}</div>
                    <div className="PayDetailInfoInfo">
                      입금 계좌번호 : {payInfo.vbank} {payInfo.vaccount}
                    </div>
                    <div className="PayDetailInfoInfo">
                      입금 만료일 : {payInfo.vexpiredDate.replace("T", " ")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="BackBtnBox">
          <div className="BackBtn" onClick={() => backPage()}>
            뒤로가기
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayInfo;
