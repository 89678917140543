import { useEffect, useState } from 'react';
import './ProductInfo.css';
import axios from 'axios';
import Auth from '../js/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import backImg from '../assets/images/back_img.jpg';

const ProductInfo = () => {

    const location = useLocation();
    let productInfo = location.state.productInfo;
    const [imgNum, setImgNum] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [login, setLogin] = useState(false);
    const navigate = useNavigate();

    //천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 이미지 크게 보기
    const imgClick = (src) => {
        let img1 = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT+src;
        var img2= new Image(); 
        img2.src= process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT+src;      
        var W = img2.width; 
        var H = img2.height; 
        // 팝업창 중앙정렬
        let TopPosition = (window.screen.height/2)-(H/2);
        let LeftPosition = (window.screen.width/2)-(W/2);
        LeftPosition += window.screenLeft; // 듀얼모니터까지 고려

        var O= "width="+W+",height="+H+",top="+TopPosition+",left="+LeftPosition+",scrollbars=yes";
        var imgWin= window.open("","",O);  
        
        imgWin.document.write("<html><head><title>이미지상세보기</title></head>");
        imgWin.document.write("<body topmargin=0 leftmargin=0>");
        imgWin.document.write("<img src="+img1+" width='100%' onclick='self.close()' style='cursor:pointer;' title ='클릭하시면 창이 닫힙니다.'>");
        imgWin.document.close();
    }

    // 상품이미지 swap
    const imgChange = (num) => {
        setImgNum(num);
    }

    // 재고 수량 수정 (숫자만 입력 가능하게)
    const quantityChange = (e) => {
        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');  // 숫자만 입력 가능한 정규식
        const nonZero = onlyNumber.replace(/(^0+)/, "");  // 앞자리가 0 일때는 입력 불가 정규식
        setQuantity(nonZero);
    }

    // 장바구니 담기
    const cartIn = async () => {
        if(login){
            var params = new URLSearchParams();
            params.append('productSeq', productInfo.seq);
            params.append('quantity', quantity);
            const cartIn = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/bucketIn', params);
            if(cartIn.data.success){
                if(cartIn.data.data === "already"){
                    alert('동일 상품이 장바구니에 있어 수량이 추가되었습니다.');
                }else{
                    alert('상품이 장바구니에 담겼습니다.');
                }
                if(window.confirm('장바구니로 이동하시겠습니까?')){
                    navigate('/cart');
                }
            }else{
                alert('구매 수량을 입력해 주세요.');
            }
        }else{
            alert('로그인 후 사용 가능합니다.');
            navigate('/login');
        }
    }

    // 주문 요청
    const orderRequest = async () => {
        if(login){
            var params = new URLSearchParams();
            params.append('productSeq', productInfo.seq);
            params.append('quantity', quantity);
            const orderRequest = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/request', params);
            if(orderRequest.data.success){
                alert('마이페이지 -> 주문 내역 페이지에서 구매 가능 여부 확인 및 결제가 가능합니다.');
                if(window.confirm('주문 내역 페이지로 이동하시겠습니까?')){
                    navigate('/orderlist', {
                        state: {
                            status: "request"
                        }
                    });
                }
            }else{
                alert('구매 수량을 입력해 주세요.');
            }
        }else{
            alert('로그인 후 사용 가능합니다.');
            navigate('/login');
        }
    }

    // 재고문의 클릭 후 1:1문의 작성 페이지 이동
    const askWritePage = (productSeq, companyUserId, productName, productColor, productPrice) => {
        navigate('/askwrite', {
            state: {
                askSeq: productSeq,
                askCate: "soldout",
                companyUserId: companyUserId,
                productName: productName,
                productColor: productColor,
                productPrice: productPrice
            }
        });
    }

    useEffect(() => {
        if(Auth.loggedCheck()) {
            setLogin(true);
        }else{
            setLogin(false);
        }
    }, [login])

    return(
        <div className='ProductInfoWrap'>
            <div className='ProductDetailBox'>
                <div className='ProductInfoTop'>
                    <div className='ProductInfoLeft'>
                        <div className='ProductInfoMainImgBox' onClick={()=>imgClick(productInfo.imageList[imgNum].filename)}>
                            <img className='BackImg' src={backImg} alt='img' />
                            <img className='ProductInfoMainImg' src={getProductUrl(productInfo.imageList[imgNum].filename)} alt="img" />
                        </div>
                        <div className='ProductInfoSubImgWrap'>
                            {productInfo.imageList.map(
                                (img, index) => (
                                    <div className='ProductInfoSubImgBox' onClick={()=>imgChange(index)} key={img.seq}>
                                        <img className='BackImg' src={backImg} alt='img' />
                                        <img className='ProductInfoSubImg' src={getProductUrl(img.filename)} alt="img" />
                                    </div>
                                )
                            )}
                        </div> 
                    </div>
                    <div className='ProductInfoRight'>
                        <div className='ProductInfoRightBox'>
                            <div className='ProductInfoDetailBox'>
                                <div className='ProductInfoTitle'>
                                    판매자명
                                </div>
                                <div className='ProductInfoContent'>
                                    {productInfo.company.userId}
                                </div>
                            </div>
                            <div className='ProductInfoDetailBox'>
                                <div className='ProductInfoTitle'>
                                    상품명
                                </div>
                                <div className='ProductInfoTitleContent'>
                                    {productInfo.name}
                                </div>
                            </div>
                            <div className='ProductInfoDetailBox'>
                                <div className='ProductInfoTitle'>
                                    재고 수량
                                </div>
                                <div className='ProductInfoContent'>
                                    {addComma(productInfo.quantity)}개
                                </div>
                            </div>
                            <div className='ProductInfoDetailBox'>
                                <div className='ProductInfoTitle'>
                                    색상
                                </div>
                                <div className='ProductInfoContent'>
                                    {productInfo.color}
                                </div>
                            </div>
                            <div className='ProductInfoDetailBox'>
                                <div className='ProductInfoTitle'>
                                    가격
                                </div>
                                <div className='ProductInfoContent'>
                                    {addComma(productInfo.price)}원 (개당)
                                </div>
                            </div>
                            {productInfo.showYN === "Y" ? (
                                <>
                                    <div className='ProductInfoDetailBox'>
                                        <div className='ProductInfoTitle'>
                                            구매 수량
                                        </div>
                                        <div className='ProductInfoContent'>
                                            <input className='ProductInfoInput' value={quantity} onChange={quantityChange} />
                                            개
                                        </div>
                                    </div>
                                    <div className='ProductInfoDetailBox'>
                                        <div className='ProductInfoTitle'>
                                            총 상품 금액
                                        </div>
                                        <div className='ProductInfoContent'>
                                            {addComma(quantity*productInfo.price)}원
                                        </div>
                                    </div>
                                    <div className='ProductInfoDetailBox'>
                                        <div className='ProductInfoNotice'>
                                            ※ 주문 요청 후 관리자가 재고 확인하여 주문 가능 여부를 알려드립니다.<br/>
                                            <b>마이페이지 → 주문 내역</b> 페이지에서 확인 가능하며,<br/> 
                                            주문승인 탭에서 결제가 가능합니다.<br/>
                                            원활한 거래를 위한 과정이오니 양해 부탁드리겠습니다.
                                        </div>
                                    </div>
                                    <div className='ProductBtnWrap'>
                                        <div className='ProductBtnBox'>
                                            {productInfo.quantity !== 0 ? (
                                                <>
                                                    <div className='ProductCartBtn' onClick={()=>cartIn()}>
                                                        장바구니
                                                    </div>
                                                    <div className='ProductOrderBtn' onClick={()=>orderRequest()}>
                                                        주문 요청
                                                    </div>
                                                </>
                                            ):(
                                                // 추후 재고 관련 시스템이 변경됬을때 사용하면 효율적이기에 남겨둠
                                                <div className='ProductAskBtn' onClick={()=>askWritePage(productInfo.seq, productInfo.company.userId, productInfo.name, productInfo.color, productInfo.price)}>
                                                    재고문의
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ):(
                                <div className='ProductInfoDelNoticeBox'>
                                    <div className='ProductInfoDelNotice'>
                                        삭제된 상품입니다.
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='ProductInfoBot'>
                    <div className='ProductInfoBotTitle'>
                        상세 정보
                    </div>
                    <div className='ProductInfoBotContents'>
                        {productInfo.productInfo}
                    </div>
                    <div className='ProductInfoBotContentsImg'>
                        {productInfo.detailImageList.map(
                            (img) => (
                                <div className='ProductInfoBotImgBox' key={img.seq}>
                                    <img className='ProductInfoBotImg' src={getProductUrl(img.filename)} alt="img" />
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductInfo;