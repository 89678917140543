import React, { useEffect, useState } from 'react';
import './Pay.css';
import axios from 'axios';
import $ from 'jquery';
import Modal from '../Modal/Modal'
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import backImg from '../assets/images/back_img.jpg';
import useScript from '../js/useScript'

var moreNum = 1;       // 무한스크롤을 위한 변수

const Pay = () => {

    const location = useLocation();
    const orderInfo = location.state.orderInfo;
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [defaultAddr, setDefaultAddr] = useState([]); 
    const [checkAddr, setCheckAddr] = useState("");
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [zoneCode, setZoneCode] = useState('');
    const [addr, setAddr] = useState('');
    const [detailAddr, setDetailAddr] = useState('');
    const [area, setArea] = useState('');   // 지역 데이터 받아옴
    const [modal, setMdoal] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [addressListCount, setAddressListCount] = useState(0);
    const [payMethod, setPayMethod] = useState('CARD');
    const [policy1, setPolicy1] = useState('N');
    const [policy2, setPolicy2] = useState('N');

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    //---------------------- 결제 START
    const [loading1, error1] = useScript("https://pg.innopay.co.kr/ipay/js/jquery-2.1.4.min.js");
    const [loading2, error2] = useScript("https://pg.innopay.co.kr/ipay/js/innopay-2.0.js");
    const { innopay } = window;
    const payment = async() => {
        innopay.goPay({
            PayMethod: payMethod,
            MID: process.env.REACT_APP_MID,							// 가맹점 MID
            MerchantKey: process.env.REACT_APP_MERCHANTKEY,	// 가맹점 라이센스키
            GoodsName: limTxt(orderInfo.product.name),		// 상품명
            Amt: String(orderInfo.totalPrice),							// 결제금액(과세)
            BuyerName: orderInfo.user.userId,		// 고객명
            BuyerTel: orderInfo.user.hp,				// 고객전화번호
            BuyerEmail:"gongyoo2023@naver.com",			// 고객이메일
            ResultYN:"Y",				// 결제결과창 출력유뮤
            Moid: process.env.REACT_APP_MID+orderInfo.seq,			// MID 삭제 하고 사용
            ReturnURL: process.env.REACT_APP_FRONT_URL+"paysuccess",   // 프롭스랑 동시에 보내면 오류
            FORWARD:'X',
        })  
    }
    // 결제 내용 payment 테이블에 저장.
    const setPayment = async () => {
        if(name.replace(/(^\s*)|(\s*$)/gi, "").length === 0){
            alert('받는 사람을 입력해 주세요.');
            return;
        }
        if(tel === ''){
            alert('휴대폰 번호를 입력해 주세요.');
            return;
        }
        if(zoneCode === '' && addr === ''){
            alert('주소를 입력해 주세요.');
            return;
        }
        if(policy1 === "N"){
            alert('이용약관에 동의하셔야 합니다.');
            return;
        }
        if(policy2 === "N"){
            alert('개인정보 수집 및 이용에 동의하셔야 합니다.');
            return;
        }
        var params = new URLSearchParams();
        params.append('seq', orderInfo.seq);
        params.append('name', name);
        params.append('tel', tel); 
        params.append('postNo', zoneCode); 
        params.append('address', addr); 
        params.append('addressDetail', detailAddr); 
        params.append('payMethod', payMethod); 
        const result = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/deal/payment', params);
        if(result.data.success){
            payment();
        }else{
            alert('결제 과정에 오류가 있습니다. 다시 시도해 주세요.');
        }
    }
    //------------------------ 결제 END
    // 텍스트 길이 자르기
    const limTxt = (txt) => {
        let text = String(txt);
        if(text.length>=20){
            text = text.substring(0, 17)+ "...";
        }
        return text;
    }
    // 결제테스트 끝

    // 천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 상품 상세페이지 이동
    const productInfoPage = (product) => {
        navigate('/productinfo', {
            state: {
                productInfo: product
            }
        });
    }

    // 기본주소 불러오기
    const getDefaultAddr = async () => {
        const getDefaultAddr = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getDefault");
        if(getDefaultAddr.data.success){
            if(getDefaultAddr.data.list.length !== 0){
                setDefaultAddr(getDefaultAddr.data.list);
            }
            addressCheckInit(getDefaultAddr.data.list);
        }
    }

    // 배송지 관련 초기 렌더링시 체크박스
    const addressCheckInit = (data) => {
        if(data.length !== 0){
            $('#PayAddressCheck1').prop('checked', true);
            $('#PayAddressCheck2').prop('checked', false);
            setCheckAddr('D');
            setName(data[0].receiveName);
            setTel(data[0].tel);
            setZoneCode(data[0].postNo);
            setAddr(data[0].address);
            setDetailAddr(data[0].addressDetail);
            setArea(data[0].region);
        }else{
            $('#PayAddressCheck2').prop('checked', true);
            $('#PayAddressCheck1').prop('checked', false);
            setCheckAddr('N');
            setName('');
            setTel('');
            setZoneCode('');
            setAddr('');
            setDetailAddr('');
            setArea('');
        }
    }

    // 배송지 관련 체크박스
    const addressCheck = (status) => {
        if(status === 'D'){
            if(defaultAddr.length === 0){
                alert('등록된 기본 배송지가 없습니다.');
                $('#PayAddressCheck1').prop('checked', false);
                return;
            }else{
                $('#PayAddressCheck1').prop('checked', true);
                $('#PayAddressCheck2').prop('checked', false);
                setName(defaultAddr[0].receiveName);
                setTel(defaultAddr[0].tel);
                setZoneCode(defaultAddr[0].postNo);
                setAddr(defaultAddr[0].address);
                setDetailAddr(defaultAddr[0].addressDetail);
                setArea(defaultAddr[0].region);
            }
        }else{
            $('#PayAddressCheck2').prop('checked', true);
            $('#PayAddressCheck1').prop('checked', false);
            setName('');
            setTel('');
            setZoneCode('');
            setAddr('');
            setDetailAddr('');
            setArea('');
        }
        setCheckAddr(status);
    }

    // 받는 사람 입력
    const nameChange = (e) => {
        setName(e.target.value);
    }

    // 휴대폰 번호 입력 (숫자만 입력 가능하게)
    const telChange = (e) => {
        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');  // 숫자만 입력 가능한 정규식
        setTel(onlyNumber);
    }

    // 주소 API 호출
    const addressPopup = async() => {      
        fnPopup();       
        window.name ="주소찾기";                                
        function fnPopup(){
            window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/address', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
        }        
    }

    // 주소 받아오기
    const getAddress = async(e) => {
        if(e.data.message==="OK"){          
            setZoneCode(e.data.zonecode);
            setAddr(e.data.addr);
            setArea(e.data.area);
            setDetailAddr('');
        }
    }

    // 배송지 리스트 불러오기
    const getAddressList = async (pageNo, size) => {
        const getAddressList = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getList",
        {
            params:{
                pageNo: pageNo,
                size: size
            }
        })
        if(getAddressList.data.success){
            setAddressList(getAddressList.data.list);
        }
    }

    // 배송지 리스트 Count
    const getAddressListCount = async () => {
        const getAddressListCount = await axios.get(process.env.REACT_APP_AXIOS_URL+"v1/address/getListCount")
        if(getAddressListCount.data.success){
            setAddressListCount(getAddressListCount.data.data);
        }
    }

    // 무한 스크롤 (모달에 적용)
    const moremoreList = () => {
        let scrollT = $('.AddrModalListWrap').scrollTop();
        let documentH = $('.AddrModalListBox').height();
        let windowH = $('.AddrModalListWrap').height();
        if(scrollT > Math.floor((documentH-windowH)*0.8)){
            if(5*(moreNum) < addressListCount){
                //스크롤이 80% 도달했을때 이벤트
                moreNum = moreNum+1;   
                getAddressList(0, 5*(moreNum));
            }
        }
    }

    // 모달 open
    const openModal = () => {
        if(addressListCount === 0){
            alert("등록된 배송지가 없습니다.")
        }else{
            setMdoal(true);
        }
    }

    // 배송지 선택 및 모달 close
    const closeModal = (address) => {
        setMdoal(false);
        setCheckAddr('N');
        $('#PayAddressCheck2').prop('checked', true);
        $('#PayAddressCheck1').prop('checked', false);
        setName(address.receiveName);
        setTel(address.tel);
        setZoneCode(address.postNo);
        setAddr(address.address);
        setDetailAddr(address.addressDetail);
        setArea(address.region);
    }

    // 외부영역 클릭 시 모달 close
    const outsideClickOff = () => {
        $('.App').click(function(e) {
            if(!$('.AddrModalWrap').has(e.target).length) {
                setMdoal(false);
            }
        })
    }

    // 결제수단 초기 렌더링시 체크박스
    const payMethodCheckInit = () => {
        $('#PayMethodCheck1').prop('checked', true);
        $('#PayMethodCheck2').prop('checked', false);
    }

    // 결제수단 체크
    const payMethodCheck = (method) => {
        if(method === "CARD"){
            $('#PayMethodCheck1').prop('checked', true);
            $('#PayMethodCheck2').prop('checked', false);
        }else{
            $('#PayMethodCheck2').prop('checked', true);
            $('#PayMethodCheck1').prop('checked', false);
        }
        setPayMethod(method);
    }

    // 이용약관 체크
    const payPolicyCheck1 = () => {
        if(policy1 === "Y"){
            $('#PayPolicyCheck1').prop('checked', false);
            setPolicy1("N");
        }else{
            $('#PayPolicyCheck1').prop('checked', true);
            setPolicy1("Y");
        }
    }

    // 이용약관 새창 (JSP)
    const viewPayPolicy1 = async () => {
        window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/policy1', 'popupChk', 'width=600, height=650, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
    }

    // 개인정보 수집 및 이용 체크
    const payPolicyCheck2 = () => {
        if(policy2 === "Y"){
            $('#PayPolicyCheck2').prop('checked', false);
            setPolicy2("N");
        }else{
            $('#PayPolicyCheck2').prop('checked', true);
            setPolicy2("Y");
        }
    }

    // 개인정보 수집 및 이용 새창 (JSP)
    const viewPayPolicy2 = async () => {
        window.open(process.env.REACT_APP_AXIOS_URL+'v1/sign/policy2', 'popupChk', 'width=600, height=650, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');           
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
    }

    useEffect(() => {
        getAddressList(0, 5);
        getAddressListCount();
        getDefaultAddr();
        outsideClickOff();
        payMethodCheckInit();
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        window.addEventListener("message", getAddress, false);
        return () => {
            window.removeEventListener("resize", resize);
            window.removeEventListener("message", getAddress, false);
        }
    }, []);

    // 모달 내부 스크롤
    useEffect(() => {
        $(".AddrModalListWrap").scroll(function() {
            moremoreList();
        });
    })

    return(
        <>
            <Modal open={modal}>
                <div className='AddrModalWrap'>
                    <div className='AddrModalBox'>
                        <div className='AddrModalTitle'>
                                배송지 목록
                        </div>
                        <div className='AddrModalListWrap'>
                            <div className='AddrModalListBox'>
                                {addressList.map(
                                    (address) => (
                                        <div className='AddrModalInfoWrap' key={address.seq}>
                                            <div className='AddrModalInfoBox'>
                                                {address.defaultYN === "Y" &&
                                                    <div className='AddrModalDefaultAddr'>
                                                        기본 배송지
                                                    </div>
                                                }
                                                <div className='AddrModalInfo'>
                                                    받는 사람 : {address.receiveName}
                                                </div>
                                                <div className='AddrModalInfo'>
                                                    휴대폰 번호 : {hyphenPhoneNum(address.tel)}
                                                </div>
                                                <div className='AddrModalInfo'>
                                                    주소 : ({address.postNo}) {address.address} {address.addressDetail}
                                                </div>
                                            </div>
                                            <div className='AddrModalBtnBox'>
                                                <div className='AddrModalSelectBtn' onClick={()=>closeModal(address)}>
                                                    선택
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='PayWrap'>
                <div className='PayBox'>
                    <div className='PayProductWrap'>
                        <div className='PayProductInfoTitle'>
                            구매 상품 정보
                        </div>
                        <div className='PayProductContentWrap'>
                            {width > 900 ? (
                                <>
                                    <div className='PayProductHeaderWrap'>
                                        <div className='PayProductHeaderBox'>
                                            <Grid container>
                                                <Grid item xs={6} md={6}>
                                                    <div className='PayProductHeaderInfo'>
                                                        상품정보
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3}>
                                                    <div className='PayProductHeaderQuantity'>
                                                        구매 수량
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3}>
                                                    <div className='PayProductHeaderPrice'>
                                                        상품 금액 
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className='PayProductContentBox'>
                                        <Grid container>
                                            <Grid item xs={6} md={6}>
                                                <div className='PayProductInfo'>
                                                    <div className='PayProductImgBox' onClick={()=>productInfoPage(orderInfo.product)}>
                                                        <img className='BackImg' src={backImg} alt='img' />
                                                        <img className='PayProductImg' src={getProductUrl(orderInfo.product.productImg)} alt='img' />
                                                    </div>
                                                    <div className='PayProductNameBox' onClick={()=>productInfoPage(orderInfo.product)}>
                                                        {orderInfo.product.name}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <div className='PayProductQuantity'>
                                                    {addComma(orderInfo.quantity)}개
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <div className='PayProductPrice'>
                                                    {addComma(orderInfo.totalPrice)}원
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            ):(
                                <div className='PayProductContentBoxM'>
                                    <div className='PayProductInfoM'>
                                        <div className='PayProductImgBoxM' onClick={()=>productInfoPage(orderInfo.product)}>
                                            <img className='BackImg' src={backImg} alt='img' />
                                            <img className='PayProductImgM' src={getProductUrl(orderInfo.product.productImg)} alt='img' />
                                        </div>
                                        <div className='PayProductNameBoxM' onClick={()=>productInfoPage(orderInfo.product)}>
                                            {orderInfo.product.name}
                                        </div>
                                    </div>
                                    <div className='PayProductQuantityM'>
                                        구매 수량 : {addComma(orderInfo.quantity)}개
                                    </div>
                                    <div className='PayProductPriceM'>
                                        상품 금액 : {addComma(orderInfo.totalPrice)}원
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='PayAddressWrap'>
                        <div className='PayAddressTitle'>
                            배송지 정보 <b>(결제 후 배송지 변경이 불가합니다.)</b>
                        </div>
                        <div className='PayAddressBox'>
                            <div className='PayAddressHeaderBox'>
                                <div className='PayAddressCheckBox' onClick={()=>addressCheck('D')}>
                                    <input className='PayAddressCheck' id="PayAddressCheck1" type='checkbox' />
                                    <div className='PayAddressCheckText'>
                                        기본 배송지
                                    </div>
                                </div>
                                <div className='PayAddressCheckBox' onClick={()=>addressCheck('N')}>
                                    <input className='PayAddressCheck' id="PayAddressCheck2" type='checkbox' />
                                    <div className='PayAddressCheckText'>
                                        신규 배송지
                                    </div>
                                </div>
                                <div className='PayAddressListBtn' onClick={()=>openModal()}>
                                    배송지 목록
                                </div>
                            </div>
                            <div className='PayAddressInfoWrap'>
                                <div className='PayAddressInfoBox'>
                                    {(checkAddr === "D" && defaultAddr.length !== 0) &&
                                        <>
                                            <div className='PayAddressInfo'>
                                                받는 사람 : {defaultAddr[0].receiveName}
                                            </div>
                                            <div className='PayAddressInfo'>
                                                휴대폰 번호 : {hyphenPhoneNum(defaultAddr[0].tel)}
                                            </div>
                                            <div className='PayAddressInfo'>
                                                주소 : ({defaultAddr[0].postNo}) {defaultAddr[0].address} {defaultAddr[0].addressDetail}
                                            </div>
                                        </>
                                    }
                                    {checkAddr === "N" &&
                                        <div className='PayAddressNewBox'>
                                            <div className='PayAddressNewSection'>
                                                <div className="PayAddressNewTitle1">
                                                    받는 사람
                                                </div>
                                                <div className="PayAddressNewContentWrap1">
                                                    <div className="PayAddressNewInfoBox">
                                                        <input className="PayAddressNewInfoInput" value={name} onChange={nameChange} placeholder="받는 사람을 입력해 주세요." /> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='PayAddressNewSection'>
                                                <div className="PayAddressNewTitle1">
                                                    휴대폰 번호
                                                </div>
                                                <div className="PayAddressNewContentWrap1">
                                                    <div className="PayAddressNewInfoBox">
                                                        <input className="PayAddressNewInfoInput" value={tel} onChange={telChange} placeholder="휴대폰 번호를 입력해 주세요." /> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='PayAddressNewSection'>
                                                <div className="PayAddressNewTitle2">
                                                    주소
                                                </div>
                                                <div className="PayAddressNewContentWrap2">
                                                    <div className="PayAddressNewBox1">
                                                        <input className="PayAddressNewInput1" value={zoneCode} readOnly={true} placeholder="우편번호" />
                                                    </div>
                                                    <div className="PayAddressNewBtn" onClick={()=>addressPopup()}>
                                                        주소찾기
                                                    </div>
                                                    <div className="PayAddressNewBox2">
                                                        <input className="PayAddressNewInput2" value={addr} readOnly={true} placeholder="주소" />
                                                    </div>
                                                    <div className="PayAddressNewBox3">
                                                        <input className="PayAddressNewInput3" value={detailAddr} onChange={(e)=>setDetailAddr(e.target.value)} placeholder="상세주소를 입력해 주세요." /> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PayInfoWrap'>
                        <div className='PayInfoTitle'>
                            결제 정보
                        </div>
                        <div className='PayInfoBox'>
                            <div className='PayInfoArea'>
                                <div className='PayInfoPrice'>
                                    결제금액 : &nbsp;{addComma(orderInfo.totalPrice)}원
                                </div>
                                <div className='PayInfoMethod'>
                                    <div className='PayMethodTitle'>
                                        결제수단 : 
                                    </div>
                                    <div className='PayMethodBox' onClick={()=>payMethodCheck("CARD")}>
                                        <input className='PayMethodCheck' id="PayMethodCheck1" type='checkbox' />
                                        <div className='PayMethodCheckText'>
                                            신용카드
                                        </div>
                                    </div>
                                    <div className='PayMethodBox' onClick={()=>payMethodCheck("VBANK")}>
                                        <input className='PayMethodCheck' id="PayMethodCheck2" type='checkbox' />
                                        <div className='PayMethodCheckText'>
                                            가상계좌
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PayPolicyWrap'> 
                        <div className='PayPolicyTitle'>
                            약관 동의
                        </div>
                        <div className='PayPolicyBox'>
                            <div className='PayPolicyCheckBox'>
                                <div className='PayPolicyArea'>
                                    <div className='PayPolicyCheckArea' onClick={()=>payPolicyCheck1()}>
                                        <input className='PayPolicyCheck' id="PayPolicyCheck1" type='checkbox' />
                                        <div className='PayPolicyCheckText'>
                                            (필수) 이용약관
                                        </div>
                                    </div>
                                    <div className='PayPolicyView' onClick={()=>viewPayPolicy1()}>
                                        내용 보기
                                    </div>
                                </div>
                                <div className='PayPolicyArea'>
                                    <div className='PayPolicyCheckArea' onClick={()=>payPolicyCheck2()}>
                                        <input className='PayPolicyCheck' id="PayPolicyCheck2" type='checkbox' />
                                        <div className='PayPolicyCheckText'>
                                            (필수) 개인정보 수집 및 이용
                                        </div>
                                    </div>
                                    <div className='PayPolicyView' onClick={()=>viewPayPolicy2()}>
                                        내용 보기
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PayBtnWrap'>
                        <div className='PayBtn' onClick={()=>setPayment()}>
                            결제하기
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pay;