import React, { useEffect, useState } from 'react';
import './PaySuccess.css';
import axios from 'axios';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import backImg from '../assets/images/back_img.jpg';

const PaySuccess = () => {

    const [paymentInfo, setPaymentInfo] = useState([]);
    const [productInfo, setProductInfo] = useState([]);
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);

    const resParams = new URLSearchParams(window.location.search);
    const moid = resParams.get("MOID");
    const vExpired = resParams.get("VbankExpDate");
    const vBank = resParams.get("VbankName");
    const vAccount = resParams.get("VbankNum");
    const vName = resParams.get("VBankAccountName");
    const AuthDate = resParams.get("AuthDate");

    // 결제 완료 통보 하고 payment 객체 가져오기
    const paymentEnd = async () => {
        var params = new URLSearchParams();
        params.append('moid', moid);
        params.append('vbank', vBank);
        params.append('vaccount', vAccount);
        params.append('vname', vName);
        params.append('vexpired', vExpired);
        params.append('authdate', AuthDate);
        params.append('res', resParams.toString());
        const getPayment = await axios.post(process.env.REACT_APP_AXIOS_URL+"v1/deal/paymentEnd", params)
        if(getPayment.data.success){
            setPaymentInfo(getPayment.data.data);
            setProductInfo(getPayment.data.data.product);
        }
    }

    // 결제수단 표시
    const payMethodText = (method) => {
        switch(method){
            case "CARD":
                return "신용카드";
            case "VBANK":
                return "가상계좌";
            default:
        }
    }

    // 전화번호 하이픈 자동 입력
    const hyphenPhoneNum = value => {
        if (!value) {
        return "";
        }
    
        value = value.replace(/[^0-9]/g, "");
    
        let result = [];
        let restNumber = "";
    
        // 지역번호와 나머지 번호로 나누기
        if (value.startsWith("02")) {
        // 서울 02 지역번호
        result.push(value.substr(0, 2));
        restNumber = value.substring(2);
        } else if (value.startsWith("1")) {
        // 지역 번호가 없는 경우
        // 1xxx-yyyy
        restNumber = value;
        } else {
        // 나머지 3자리 지역번호
        // 0xx-yyyy-zzzz
        result.push(value.substr(0, 3));
        restNumber = value.substring(3);
        }
    
        if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
        } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
        }
    
        return result.filter((val) => val).join("-");
    }

    // 천단위 콤마
    const addComma = (value) => {
        var tempStr = String(value);
        return tempStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // 상품 이미지 URL
    const getProductUrl = fileName => {
        let productImg = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_PRODUCT; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            productImg = productImg + fileName;
        }
      
        return productImg;
    } 

    // 상품 상세페이지 이동
    const productInfoPage = (product) => {
        navigate('/productinfo', {
            state: {
                productInfo: product
            }
        });
    }

    // 주문내역으로 이동
    const orderListPage = () => {
        navigate('/orderlist', {
            state: {
                status: "submit"
            }
        });
    }

    // 브라우저 사이즈 가져오기
    const resize = () => {
        setWidth(window.innerWidth);  
    }

    useEffect(() => {
        paymentEnd();
        resize();       // 처음 렌더링 시 브라우저 넓이에 맞는 예외처리를 위해 선언
        window.addEventListener("resize", resize);      // 브라우저 resize 시 작동
        return () => {
            window.removeEventListener("resize", resize);
        }
    }, []);

    return(

        <div className='PaySuccessWrap'>
            <div className='PaySuccessBox'>
                <div className='PaySuccessTextBox'>
                    {paymentInfo.status === "End" ? (
                        <div className='PaySuccessText'>
                            결제가 완료되었습니다.
                        </div>
                    ):(
                        <div className='PaySuccessText'>
                            결제금액 입금 후 결제가 완료됩니다.
                        </div>
                    )}
                </div>
                <div className='PaySuccessProductWrap'>
                    <div className='PaySuccessInfoTitle'>
                        구매 상품 정보
                    </div>
                    <div className='PaySuccessContentWrap'>
                        {width > 900 ? (
                            <>
                                <div className='PaySuccessHeaderWrap'>
                                    <div className='PaySuccessHeaderBox'>
                                        <Grid container>
                                            <Grid item xs={6} md={6}>
                                                <div className='PaySuccessHeaderInfo'>
                                                    상품정보
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <div className='PaySuccessHeaderQuantity'>
                                                    구매 수량
                                                </div>
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <div className='PaySuccessHeaderPrice'>
                                                    상품 금액 
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className='PaySuccessContentBox'>
                                    <Grid container>
                                        <Grid item xs={6} md={6}>
                                            <div className='PaySuccessInfo'>
                                                <div className='PaySuccessImgBox' onClick={()=>productInfoPage(paymentInfo.product)}>
                                                    <img className='BackImg' src={backImg} alt='img' />
                                                    <img className='PaySuccessImg' src={getProductUrl(productInfo.productImg)} alt='img' />
                                                </div>
                                                <div className='PaySuccessNameBox' onClick={()=>productInfoPage(paymentInfo.product)}>
                                                    {productInfo.name}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <div className='PaySuccessQuantity'>
                                                {addComma(paymentInfo.quantity)}개
                                            </div>
                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <div className='PaySuccessPrice'>
                                                {addComma(paymentInfo.totalPrice)}원
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        ):(
                            <div className='PaySuccessContentBoxM'>
                                <div className='PaySuccessInfoM'>
                                    <div className='PaySuccessImgBoxM' onClick={()=>productInfoPage(paymentInfo.product)}>
                                        <img className='BackImg' src={backImg} alt='img' />
                                        <img className='PaySuccessImgM' src={getProductUrl(productInfo.productImg)} alt='img' />
                                    </div>
                                    <div className='PaySuccessNameBoxM' onClick={()=>productInfoPage(paymentInfo.product)}>
                                        {productInfo.name}
                                    </div>
                                </div>
                                <div className='PaySuccessQuantityM'>
                                    구매 수량 : {addComma(paymentInfo.quantity)}개
                                </div>
                                <div className='PaySuccessPriceM'>
                                    상품 금액 : {addComma(paymentInfo.totalPrice)}원
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='PaySuccessAddrWrap'>
                    <div className='PaySuccessTitle'>
                        배송지 정보
                    </div>
                    <div className='PaySuccessAddrBox'>
                        <div className='PaySuccessInfoWrap'>
                            <div className='PaySuccessInfoBox'>
                                <div className='PaySuccessInfo'>
                                    받는 사람 : {paymentInfo.name}
                                </div>
                                <div className='PaySuccessInfo'>
                                    휴대폰 번호 : {hyphenPhoneNum(paymentInfo.tel)}
                                </div>
                                <div className='PaySuccessInfo'>
                                    주소 : ({paymentInfo.postNo}) {paymentInfo.address} {paymentInfo.addressDetail}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='PaySuccessPayInfoWrap'>
                    <div className='PaySuccessTitle'>
                        결제 정보
                    </div>
                    <div className='PaySuccessPayInfoBox'>
                        <div className='PaySuccessInfoWrap'>
                            <div className='PaySuccessInfoBox'>
                                <div className='PaySuccessInfo'>
                                    결제수단 : {payMethodText(paymentInfo.payMethod)}
                                </div>
                                {paymentInfo.payMethod === "CARD" &&
                                    <div className='PaySuccessInfo'>
                                        결제일 : {paymentInfo.payDate.replace('T', ' ')}
                                    </div>
                                }
                                {paymentInfo.payMethod === "VBANK" &&
                                    <>
                                        <div className='PaySuccessInfo'>
                                            예금주 : (주)공유자재
                                        </div>
                                        <div className='PaySuccessInfo'>
                                            입금자명 : {paymentInfo.vname}
                                        </div>
                                        <div className='PaySuccessInfo'>
                                            입금 계좌번호 : {paymentInfo.vbank} {paymentInfo.vaccount}
                                        </div>
                                        <div className='PaySuccessInfo'>
                                            입금 만료일 : {paymentInfo.vexpiredDate.replace('T', ' ')}
                                        </div>
                                    </>
                                }
                                <div className='PaySuccessInfo'>
                                    결제금액 : {addComma(paymentInfo.totalPrice)}원
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='OrderListBtnBox'>
                    <div className='OrderListBtn' onClick={()=>orderListPage()}>
                        주문내역
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaySuccess;