import React from 'react';
import './AskView.css';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';

const AskView = () => {

    const location = useLocation();
    const askInfo = location.state.askInfo;
    const navigate = useNavigate();

    // 첨부파일 이미지 URL
    const getImgUrl = fileName => {
        let Img = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_QNA; 
        if(fileName===null || fileName==="null" || fileName===undefined || fileName==="undefined" || fileName===''){

        }else{
            Img = Img + fileName;
        }
      
        return Img;
    } 

    // 이미지 크게 보기
    const imgClick = (src) => {
        let img1 = process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_QNA+src;
        var img2= new Image(); 
        img2.src= process.env.REACT_APP_AXIOS_URL+process.env.REACT_APP_IMAGE_QNA+src;      
        var W = img2.width; 
        var H = img2.height; 
        // 팝업창 중앙정렬
        let TopPosition = (window.screen.height/2)-(H/2);
        let LeftPosition = (window.screen.width/2)-(W/2);
        LeftPosition += window.screenLeft; // 듀얼모니터까지 고려

        var O= "width="+W+",height="+H+",top="+TopPosition+",left="+LeftPosition+",scrollbars=yes";
        var imgWin= window.open("","",O);  
        
        imgWin.document.write("<html><head><title>이미지상세보기</title></head>");
        imgWin.document.write("<body topmargin=0 leftmargin=0>");
        imgWin.document.write("<img src="+img1+" width='100%' onclick='self.close()' style='cursor:pointer;' title ='클릭하시면 창이 닫힙니다.'>");
        imgWin.document.close();
    }

    // 문의유형 case
    const cate = (cate) => {
        switch(cate){
            case "normal":
                return "일반문의";
            case "dispute":
                return "불편접수";
            case "soldout":
                return "재고문의";
            default:
        }
    }

    // 뒤로가기
    const backPage = () => {
        navigate('/asklist');
    }

    return(
        <div className="AskViewWrap">
            <div className='AskViewBox'>
                <div className='AskViewSection'>
                    <div className="AskViewTitle">
                        문의일자
                    </div>
                    <div className="AskViewContentWrap">
                        {askInfo.regDate}
                    </div>
                </div>
                <div className='AskViewSection'>
                    <div className="AskViewTitle">
                        문의유형
                    </div>
                    <div className="AskViewContentWrap">
                        {cate(askInfo.category)}
                    </div>
                </div>
                <div className='AskViewSection'>
                    <div className="AskViewTitle">
                        제목
                    </div>
                    <div className="AskViewContentWrap">
                        {askInfo.title}
                    </div>
                </div>
                <div className='AskViewSection'>
                    <div className="AskViewTitle">
                        문의내용
                    </div>
                    <div className="AskViewContentWrap">
                        {askInfo.contents}
                    </div>
                </div>
                {askInfo.imageList.length !== 0 &&
                    <div className='AskViewSection'>
                        <div className="AskViewTitle">
                            첨부파일
                        </div>
                        <div className="AskViewImgContentWrap">
                            {askInfo.imageList.map(
                                (img) => (
                                    <div className='AskViewImgBox' onClick={()=>imgClick(img.filename)} key={img.seq}>
                                        <img className='AskViewImg' src={getImgUrl(img.filename)} alt="img" />
                                    </div>
                                )
                            )}
                        </div>
                    </div>       
                }
                {askInfo.answerSeq !== 0 &&
                    <>
                        <div className='AskViewSection'>
                            <div className="AskViewTitle">
                                답변일자
                            </div>
                            <div className="AskViewContentWrap">
                                {askInfo.answer.regDate}
                            </div>
                        </div>
                        <div className='AskViewSection'>
                            <div className="AskViewTitle">
                                답변내용
                            </div>
                            <div className="AskViewContentWrap">
                                {askInfo.answer.contents}
                            </div>
                        </div>
                        {askInfo.answer.imageList.length !== 0 &&
                            <div className='AskViewSection'>
                                <div className="AskViewTitle">
                                    첨부파일<br/>(답변)
                                </div>
                                <div className="AskViewImgContentWrap">
                                    {askInfo.answer.imageList.map(
                                        (img) => (
                                            <div className='AskViewImgBox' onClick={()=>imgClick(img.filename)} key={img.seq}>
                                                <img className='AskViewImg' src={getImgUrl(img.filename)} alt="img" />
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>       
                        }
                    </>
                }
                <div className='AskBackBtnBox'>
                    <div className='AskBackBtn' onClick={()=>backPage()}>
                        뒤로가기
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AskView;