import { useState } from 'react';
import './Withdraw.css';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Auth from '../js/Auth';

const Withdraw = () => {

    const [curPw, setCurPw] = useState('');
    const [checkYN, setCheckYN] = useState('N');
    const navigate = useNavigate();

    // 탈퇴약관 동의
    const checkPolicy = () => {
        if(checkYN === "Y"){
            setCheckYN("N");
            $('#WithdrawPolicyCheck').prop('checked', false);
        }else{
            setCheckYN("Y");
            $('#WithdrawPolicyCheck').prop('checked', true);
        }
    }

    // 현재 비밀번호 입력
    const curPwChange = (e) => {
        setCurPw(e.target.value);
    }

    // 회원탈퇴
    const withdraw = async () => {
        if(checkYN === "N"){
            alert('탈퇴 약관에 동의하셔야 합니다.');
            return;
        }
        if(curPw === ''){
            alert('현재 비밀번호를 입력해 주세요.');
            return;
        }
        var params = new URLSearchParams();
        params.append('password', curPw);
        const result = await axios.post(process.env.REACT_APP_AXIOS_URL+'v1/my/withdraw', params);
        if(result.data.success){
            if(window.confirm('정말 탈퇴 하시겠습니까?')){
                alert('탈퇴 처리되었습니다.');
                Auth.logout();  
                navigate('/');
            }
        }else{
            if(result.data.code === -1001){
                alert('현재 비밀번호가 일치하지 않습니다.');
                return;
            }else if(result.data.code === -1008){
                alert('아직 진행중인 거래가 있습니다.');
                return;
            }else if(result.data.code === -1009){
                alert('아직 진행중인 거래가 있습니다.');
                return;
            }else if(result.data.code === -1015){
                alert('정산되지 않은 금액이 있습니다.');
                return;
            }
        }
    }

    return(
        <div className='WithdrawWrap'>
            <div className='WithdrawArea'>
                <div className='WithdrawPolicyWrap'>
                    <div className='WithdrawPolicyBox'>
                        <div class='WithdrawPolicyInfoWrap'>
                            <div class='WithdrawPolicyInfoContentWrap'>
                                <div class='WithdrawPolicyInfoContentBox'>
                                    <div className='WithdrawPolicyInfoContent'>
                                        [회원탈퇴 약관]
                                    </div>
                                    <div className='WithdrawPolicyInfoContent'>
                                        1. 서비스 이용계약을 해지 하고자 할 때는 본 페이지에서 회원탈퇴를 신청하시면 됩니다.<br/> 
                                        2. 탈퇴를 신청하시면 즉시 탈퇴처리가 완료되며, 탈퇴후에도 동일 개인정보로 재가입 가능합니다.<br/>
                                        3. 회원님이 현재 거래 또는 구매 중인 상품이 있거나, 미정산된 금액이 있으면 회원 탈퇴가 불가능 합니다.<br/>
                                        4. 회원님이 판매자인 경우 플랫폼에 등록된 상품들은 모두 삭제 처리 됩니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='WithdrawPolicyCheckBox' onClick={()=>checkPolicy()}>
                    <input className='WithdrawPolicyCheck' type='checkbox' id="WithdrawPolicyCheck" />
                    <div className='WithdrawPolicyCheckText'>
                        (필수) 탈퇴 약관에 동의합니다.
                    </div>
                </div>
                <div className='WithdrawPwNotice'>
                    ※ 회원탈퇴를 위해 현재 비밀번호를 입력해 주세요.
                </div>
                <div className='WithdrawPwWrap'>
                    <div className='WithdrawPwTitle'>
                        현재 비밀번호
                    </div>
                    <div className="WithdrawPwBox">
                        <input className="WithdrawPwInput" type='password' value={curPw} onChange={curPwChange} placeholder="현재 비밀번호를 입력해 주세요." autoComplete='new-password' />
                    </div>
                </div>
                <div className='WithdrawBtnWrap'>
                    <div className='Withdraw' onClick={()=>withdraw()}>
                        탈퇴하기
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdraw;